import {
  configureStore,
  ThunkAction,
  Action,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';

import loginReducer from '../pages/auth/authSlice';
import activateAccountReducer from '../pages/ActivateAccount/activateAccountSlice';
import registerReducer from '../pages/Register/registerSlice';
import clientsReducer from '../pages/Clients/clientsSlice';
import baseMaturationsReducer from '../pages/Genetics/Global/baseMaturationsSlice';
import companyMaturationsReducer from '../pages/Genetics/Setup/Maturations/companyMaturationsSlice';
import usersReducer from '../pages/Users/usersSlice';
import balanceReducer from '../pages/Users/balanceSlice';
import profileReducer from '../pages/Settings/settingsSlice';
import scheduleRuleReducer from '../pages/Settings/scheduleRuleSlice';
import companySettingsReducer from '../pages/Company/Settings/companySettingsSlice';
import passwordReducer from '../pages/Password/passwordSlice';
import campusReducer from '../pages/Units/campusSlice';
import modulesReducer from '../pages/Modules/modulesSlice';
import tanksReducer from '../pages/Tanks/tanksSlice';
import analysisReducer from '../pages/Analysis/sowingSlice';
import detailAnalysisReducer from '../pages/Analysis/Detail/detailAnalysisSlice';
import sampleReducer from '../pages/Analysis/Detail/Sample/sampleSlice';
import stockingsReducer from '../pages/Sowings/sowingsSlice';
import otherStockingsReducer from '../pages/Sowings/Analysis/otherStockingsSlice';
import finishStockingReducer from '../pages/Sowings/finishStockingSlice';
import transferStockingReducer from '../pages/Sowings/transferStockingSlice';
import growthDeltaPdfReducer from '../pages/Pdfs/GrowthDelta/growthDeltaSlice';
import metricsReducer from '../pages/home/homeSlice';
import headerReducer from '../pages/AppHeader/headerSlice';
import activityDashboardReducer from '../pages/ActivityDashboard/activityDashboardSlice';
import balanceConsumptionReducer from '../pages/BalanceConsumption/BalanceConsumptionSlice';
import adminDashboardReducer from '../pages/AdminDashboard/adminDashboardSlice';
import userImpersonateReducer from '../pages/Sidebar/impersonateSlice';
import survivalRateReducer from '../pages/Reports/SurvivalRate/survivalRateSlice';
import paymentReducer from '../pages/Payments/paymentSlice';
import onboardingReducer from '../common/components/Onboarding/OnboardingSlice';
import activeTanksReducer from '../pages/Reports/ActiveTanksDashboard/ActiveTanksSlice';
import detailPaymentReducer from '../pages/Payments/Detail/detailPaymentSlice';
import archivedAnalysisReducer from '../pages/Archive/Analysis/archivedAnalysisSlice';
import archivedStockingReducer from '../pages/Archive/Stocking/archivedStockingSlice';
import analysisWithoutStockingReducer from '../pages/AnalysisWithoutStocking/analysisWithoutStockingSlice';
import samplesPdfReducer from '../pages/SamplesByUser/samplesByUserSlice';
import StockingChartsReducer from '../pages/Pdfs/StockingCharts/chartsSlice';
import StockingToBeFinishedReducer from '../pages/Sowings/stockingToBeFinishedSlice';
import userActivityDashboardReducer from '../pages/ActivityDashboard/UserActivityDashboard/userActivityDashboardSlice';
import stockingMultiphaseReducer from '../pages/Sowings/Multiphase/multiphaseSlice';
import growthDeltaReducer from '../pages/Reports/GrowthDelta/growthDeltaSlice';
import geneticsAnalysisReducer from '../pages/Genetics/Analysis/geneticsAnalysisSlice';
import stockingAnalysisReducer from '../pages/Sowings/Analysis/stockingAnalysisSlice';
import laboratoryChartReducer from '../pages/Reports/LaboratoryChart/laboratoryChartSlice';
import companyReferenceCurvesReducer from '../pages/ReferenceCurves/CompanyReferenceCurves/CompanyReferenceCurvesSlice';
import unitsReferenceCurvesReducer from '../pages/ReferenceCurves/UnitsReferenceCurves/UnitsReferenceCurvesSlice';
import detailCustomizableReducer from '../pages/Analysis/Detail/detailCustomizableSlice';
import analysisFromConsolidatedReducer from '../pages/Analysis/analysisFromConsolidatedSlice';
import globalReferenceCurvesReducer from '../pages/ReferenceCurves/GlobalReferenceCurves/GlobalReferenceCurvesSlice';
import editStockingPopulationReducer from '../pages/Sowings/Populations/editStockingPopulationSlice';
import prevStatsReducer from '../pages/Reports/PrevStats/prevStatsSlice';
import successQuadrantReducer from '../pages/Reports/SuccessQuadrant/successQuadrantSlice';
import companyStockingParameterReducer from '../pages/Company/StockingParameters/companyStockingParameterSlice';
import stockingParameterCustomizableReducer from '../pages/Company/StockingParameters/stockingParameterCustomizableSlice';
import newStockingParameterReducer from '../pages/StockingParameter/newStockingParameterSlice';
import stockingParameterReducer from '../pages/Analysis/stockingParameterSlice';
import editStockingParameterReducer from '../pages/StockingParameter/Detail/detailStockingParameterSlice';
import harvestsStockingReducer from '../pages/Sowings/harvestsStockingSlice';
import parameterChartReducer from '../pages/Sowings/Parameters/parameterChartSlice';
import containerMapReducer from '../pages/Units/ContainerMap/containerMapSlice';
import stockingParameterGraphReducer from '../pages/Reports/ParameterState/parameterStateSlice';
import harvestsReducer from '../pages/Harvests/harvestsSlice';
import historyManagementReducer from '../pages/Company/HistoryManagement/historyManagementSlice';
import analysisCardCustomizableReducer from '../pages/Company/AnalysisCard/analysisCardCustomizableSlice';
import transportReducer from '../pages/Company/Transports/transportSlice';
import transferGraphReducer from '../pages/Reports/TransferGraph/transferGraphSlice';
import optimalHarvestPointReducer from '../pages/Reports/OptimalHarvestPoint/optimalHarvestPointSlice';
import companyAnalysisObservationReducer from '../pages/Company/AnalysisObservation/companyAnalysisObservationSlice';
import packerReducer from '../pages/Company/Packers/packerSlice';
import commercialSizeReducer from '../pages/Company/Packers/CommercialSizes/commercialSizeSlice';
import tablePriceReducer from '../pages/Company/Packers/TablePrices/tablePriceSlice';

import authMiddleware from './middleware/auth.middleware';

export const store = configureStore({
  middleware: [authMiddleware, ...getDefaultMiddleware()],
  reducer: {
    auth: loginReducer,
    activateAccount: activateAccountReducer,
    registerClient: registerReducer,
    clients: clientsReducer,
    users: usersReducer,
    balance: balanceReducer,
    profile: profileReducer,
    scheduleRule: scheduleRuleReducer,
    companySettings: companySettingsReducer,
    password: passwordReducer,
    campus: campusReducer,
    modules: modulesReducer,
    tanks: tanksReducer,
    analysis: analysisReducer,
    stockings: stockingsReducer,
    otherStockings: otherStockingsReducer,
    finishStocking: finishStockingReducer,
    transferStocking: transferStockingReducer,
    metrics: metricsReducer,
    header: headerReducer,
    detailAnalysis: detailAnalysisReducer,
    activityDashboard: activityDashboardReducer,
    adminDashboard: adminDashboardReducer,
    impersonate: userImpersonateReducer,
    companyMaturations: companyMaturationsReducer,
    baseMaturations: baseMaturationsReducer,
    balanceConsumption: balanceConsumptionReducer,
    survivalRate: survivalRateReducer,
    payments: paymentReducer,
    growthDeltaPdf: growthDeltaPdfReducer,
    onboarding: onboardingReducer,
    activeTanks: activeTanksReducer,
    detailPayment: detailPaymentReducer,
    archivedAnalysis: archivedAnalysisReducer,
    archivedStocking: archivedStockingReducer,
    analysisWithoutStocking: analysisWithoutStockingReducer,
    samplesByUser: samplesPdfReducer,
    stockingCharts: StockingChartsReducer,
    stockingToBeFinished: StockingToBeFinishedReducer,
    userActivityDashboard: userActivityDashboardReducer,
    stockingMultiphase: stockingMultiphaseReducer,
    growthDelta: growthDeltaReducer,
    geneticsAnalysis: geneticsAnalysisReducer,
    stockingAnalysis: stockingAnalysisReducer,
    laboratoryChart: laboratoryChartReducer,
    companyReferenceCurves: companyReferenceCurvesReducer,
    unitsReferenceCurves: unitsReferenceCurvesReducer,
    globalReferenceCurves: globalReferenceCurvesReducer,
    sample: sampleReducer,
    detailCustomizable: detailCustomizableReducer,
    analysisFromConsolidated: analysisFromConsolidatedReducer,
    editStockingPopulation: editStockingPopulationReducer,
    prevState: prevStatsReducer,
    successQuadrant: successQuadrantReducer,
    companyStockingParameter: companyStockingParameterReducer,
    stockingParameterCustomizable: stockingParameterCustomizableReducer,
    newStockingParameter: newStockingParameterReducer,
    stockingParameter: stockingParameterReducer,
    editStockingParameter: editStockingParameterReducer,
    partialHarvests: harvestsStockingReducer,
    parameterChart: parameterChartReducer,
    containerMap: containerMapReducer,
    analysisCardCustomizable: analysisCardCustomizableReducer,
    parameterState: stockingParameterGraphReducer,
    harvests: harvestsReducer,
    historyManagement: historyManagementReducer,
    transports: transportReducer,
    transferGraph: transferGraphReducer,
    optimalHarvestPoint: optimalHarvestPointReducer,
    companyAnalysisObservation: companyAnalysisObservationReducer,
    packers: packerReducer,
    commercialSizes: commercialSizeReducer,
    tablePrices: tablePriceReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
