import React, { useEffect } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { RootState } from '../../../state/store';
import { Stocking } from '../../Sowings/interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { formatLongDateWithZone } from '../../../utils/date';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import { getStartDateStocking } from '../../../helpers/stocking.helpers';

import styles from './StockingsInfoModal.module.scss';
import * as geneticsAnalysisSlice from './geneticsAnalysisSlice';

export const StockingsInfoModal = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { company, phaseType } = useSelector((state: RootState) => state.header);
  const { showStockingsModal, stockingsInfo, filters, maturationIds, maturationCode, isLoadingStockingsInfo, stage } = useSelector((state: RootState) => state.geneticsAnalysis);

  useEffect(() => {
    if (!showStockingsModal) {
      return;
    }
    
    const { maximumDate, minimumDate, unit } = filters;
    const params = { stage, campusId: unit, companyId: company._id, maturationIds, maturationCode, maximumDate, minimumDate, phaseType };
    dispatch(geneticsAnalysisSlice.fetchStockingsInfo(params));
  }, [dispatch, showStockingsModal]);

  const onCancel = () => {
    dispatch(geneticsAnalysisSlice.setShowStockingsModal(false));
    dispatch(geneticsAnalysisSlice.setMaturationIds([]));
    dispatch(geneticsAnalysisSlice.setStage(0));
  };

  const columns: ColumnsType<Stocking> = [
    {
      key: 1,
      width: '30%',
      dataIndex: 'name',
      title: t('stockings.name'),
      ellipsis: { showTitle: false },
    },
    {
      key: 2,
      width: '15%',
      dataIndex: 'campusName',
      title: t('campus.name'),
      responsive: ['lg'] as Breakpoint[],
    },
    {
      key: 3,
      width: '15%',
      dataIndex: 'moduleName',
      title: t('stockings.module'),
      responsive: ['lg'] as Breakpoint[],
    },
    {
      key: 4,
      width: '15%',
      dataIndex: 'tankName',
      title: t('stockings.container'),
      responsive: ['lg'] as Breakpoint[],
    },
    {
      key: 5,
      width: '18%',
      title: t('stockings.startDate'),
      responsive: ['md'] as Breakpoint[],
      render: (record: Stocking) => {
        const startDateStocking = getStartDateStocking({ stocking: record, phaseType: record.phaseType });
        return formatLongDateWithZone({ date: startDateStocking });
      }
    }
  ];

  return (
    <LrvModal
      theme='light'
      title={t('stockings.title')}
      open={showStockingsModal}
      destroyOnClose={true}
      onCancel={onCancel}
      className={styles.stockingsInfoModal}
      closeIcon={<Icon id='close_stockings_info_modal' name='close' />}
      footer={<></>}
    >
      <LrvTable
        id='stockings_info_table'
        className={styles.table}
        rowClassName={styles.tableRow}
        loading={isLoadingStockingsInfo}
        columns={columns}
        dataSource={stockingsInfo}
        pagination={{
          pageSize: 10,
        }}
        scroll={{ y: '' }}
        theme='light'
        onRow={(record) => {
          return {
            onClick: () => {
              const url = `/production/stockings/${record._id}`;
              window.open(url, '_blank');
            },
          };
        }}
      />
    </LrvModal>
  );
};
