import 'react-phone-number-input/style.css';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Select, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';

import { RootState } from '../../state/store';
import { dollarUS } from '../../utils/strings';
import { getMonthName } from '../../utils/date';
import { getLanguage } from '../../utils/language';
import Icon from '../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../utils/select';
import { Language } from '../../common/interfaces/commons';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { useCurrency } from '../../hooks/currency-codes/useCurrency';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { LrvSwitch } from '../../common/components/LrvSwitch/LrvSwitch';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import PasswordPolicy from '../../common/components/LrvPopover/PasswordPolicy';
import { LrvPassword } from '../../common/components/LrvPassword/LrvPassword';
import { LrvInputNumber } from '../../common/components/LrvInputNumber/LrvInputNumber';
import { goToOnboardingNextStep } from '../../common/components/Onboarding/OnboardingSlice';
import { validatePasswordPolicy, validateNumber, validateString, validatePhoneInputGroup } from '../../utils/validations';
import { companyBalanceLimits, isSuperAdmin, DEFAULT_DAYS_TO_INITIAL_STAGE, isSalesRole, plansTypes, isSalesManagerRole, isDistributorCompany, MIN_DAYS_TO_INITIAL_STAGE, MAX_DAYS_TO_INITIAL_STAGE, DEFAULT_CURRENCY_CODE } from '../../config/commons';

import styles from './NewClient.module.scss';
import { NewClientBody } from './interfaces';
import { createClient, setSelectedClient, setSellers, setPromoters, fetchPromotersBySeller, setShowCreateClientModal } from './clientsSlice';
import { calculatePlanPrice, discountPorcentage, getValueActiveUsers, months, MAX_LENGTH_COMPANY_CODE, PREPAID_PLAN, PERSONALIZED_PLAN, PHONE_PREFIX, COUNTRY_CODE, clientDefault, hasPermissionToManageCompanies } from './clientsHelper';

const { Option, OptGroup } = Select;

export const NewClient = () => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const language = getLanguage().toLowerCase() as Language;
  
  const [newCompany, setNewCompany] = useState('');
  const [newBusinessName, setNewBusinessName] = useState<string>('');
  const [ruc, setRuc] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [newCode, setNewCode] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>(COUNTRY_CODE);
  const [phonePrefix, setPhonePrefix] = useState<string>(PHONE_PREFIX);
  const [phone, setPhone] = useState<string>('');
  const [newUserFirstName, setNewUserFirstName] = useState('');
  const [newUserLastName, setUserLastName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [newResponsible, setNewResponsible] = useState<string>('');
  const [newPromoter, setNewPromoter] = useState<string>();
  const [newDaysToInitialStage, setNewDaysToInitialStage] = useState<number | string>(DEFAULT_DAYS_TO_INITIAL_STAGE);
  const [isInternational, setIsInternational] = useState<boolean>(false);
  const [newIsDistributionCompany, setNewIsDistributionCompany] = useState<boolean>(false);
  const [hasTrialPhase, setHasTrialPhase] = useState<boolean>(false);
  const [allowXlsxAnalysisReport, setAllowXlsxAnalysisReport] = useState<boolean>(false);
  const [renuevalMonths, setRenuevalMonths] = useState<number[]>([]);

  const [newPlan, setNewPlan] = useState<string>('');
  const [newPlanType, setNewPlanType] = useState<string>('');
  const [newPlanPrice, setNewPlanPrice] = useState<number>(0);
  const [currencyCode, setCurrencyCode] = useState('');
  const [newDiscountPercentage, setNewDiscountPercentage] = useState<number | undefined>(0);
  const [newQuota, setNewQuota] = useState<number>(0);
  const [newCompanyBalance, setNewCompanyBalance] = useState<string>('');

  const { currencyList } = useCurrency();

  const {
    showCreateClientModal,
    sellers,
    plans,
    promoters,
    isFormCreateLoading,
    hasEmailError,
    filters,
  } = useSelector((state: RootState) => state.clients);
  const isRunningOnboarding = useSelector((state: RootState) => state.onboarding.run);
  const { activeBaseMaturations } = useSelector((state: RootState) => state.baseMaturations);

  const { clientStatus } = filters;

  useEffect(() => {
    if (!showCreateClientModal) {
      return;
    }

    setNewCompany('');
    setNewBusinessName('');
    setRuc('');
    setAddress('');
    setNewCode('');
    setCountryCode(COUNTRY_CODE);
    setPhonePrefix(PHONE_PREFIX);
    setPhone('');
    setNewUserFirstName('');
    setUserLastName('');
    setNewUserEmail('');
    setNewUserPassword('');
    setNewResponsible('');
    setNewPromoter(undefined);
    setCurrencyCode(DEFAULT_CURRENCY_CODE);
    setNewDaysToInitialStage(DEFAULT_DAYS_TO_INITIAL_STAGE);
    setIsInternational(false);
    setNewIsDistributionCompany(false);
    setHasTrialPhase(false);
    setAllowXlsxAnalysisReport(false);
    setRenuevalMonths(months);

    setNewPlan('');
    setNewPlanType('');
    setNewPlanPrice(0);
    setNewDiscountPercentage(0);
    setNewQuota(0);
    setNewCompanyBalance('');

    dispatch(setSelectedClient(clientDefault));
    dispatch(setSellers([]));
    dispatch(setPromoters([]));

    form.setFieldsValue({
      daysToInitialStage: DEFAULT_DAYS_TO_INITIAL_STAGE,
      renuevalMonths: months,
    });
  }, [dispatch, form, showCreateClientModal]);

  useEffect(() => {
    if (!hasEmailError && !isFormCreateLoading) {
      dispatch(setShowCreateClientModal(false));
      form.resetFields();
    }
  }, [dispatch, form, hasEmailError, isFormCreateLoading]);

  const baseMaturationsData = activeBaseMaturations ? activeBaseMaturations.map((baseMaturation: { _id: string; name: string }, index: number) => {
    return {
      index: index,
      _id: baseMaturation._id,
      name: baseMaturation.name,
    };
  }) : [];

  const childrenBaseMaturation = [];
  for (let i = 0; i < baseMaturationsData.length; i++) {
    const baseMaturation = baseMaturationsData[i];
    childrenBaseMaturation.push(<Option key={baseMaturation._id} value={baseMaturation}>{baseMaturation.name}</Option>);
  }

  const plansData = plans ? plans.map((plan, index: number) => {
    return {
      index: index,
      _id: plan._id,
      value: plan.value,
      amountAnalysis: plan.amountAnalysis,
    };
  }) : [];

  const childrenPlan = [];
  for (let i = 0; i < plansData.length; i++) {
    const plan = plansData[i];
    childrenPlan.push(<Option key={plan._id} value={plan._id}>{t('payments.descriptionPlan', { amountAnalysis: plan.amountAnalysis, value: dollarUS.format(plan.value) })}</Option>);
  }

  function handleChangePlans (value: string) {
    setNewPlan(value);
    updatePlanPrice({ value, newQuota: parseInt(newCompanyBalance) });
  }

  function updatePlanPrice (props: { value: string; newQuota: number; isDistributionCompany?: boolean }) {
    const { value, newQuota, isDistributionCompany = newIsDistributionCompany } = props;
    let quota = 0;
    let planPrice = 0;

    if (value === PREPAID_PLAN) {
      setNewPlanType(plansTypes.PREPAID);
      setNewQuota(quota);
      setNewPlanPrice(planPrice);
      return;
    }

    setNewPlanType(plansTypes.POSTPAID);

    if (value === PERSONALIZED_PLAN) {
      quota = newQuota;
      planPrice = calculatePlanPrice({ plans, newQuota, isDistributor: isDistributionCompany });

      setNewQuota(quota);
      setNewPlanPrice(planPrice);
      return;
    }

    const plan = plans.find((plan) => plan._id === value);
    if (plan) {
      quota = plan.amountAnalysis;

      if (isDistributionCompany) {
        planPrice = calculatePlanPrice({ plans, newQuota: quota, isDistributor: isDistributionCompany });
      } else {
        planPrice = plan.value;
      }
    }
    setNewQuota(quota);
    setNewPlanPrice(planPrice);
  }

  function renderPromoterSelect () {
    return <Form.Item
      name='editPromoter'
      label={`${t('clients.promoter')} (${t('common.optional')})`}
    >
      <LrvSelect
        id='select_promoter_client'
        theme='light'
        showSearch
        placeholder={t('clients.select')}
        value={newPromoter}
        onChange={(value) => {
          setNewPromoter(value);
        }}
        disabled={!newResponsible || promoters.length === 0}
        suffixIcon={<Icon name='arrow-down-s' />}
        filterOption={filterOptionSelect}
      >
        {promoters.map((promoter, index) => {
          return (
            <Option
              key={index}
              value={promoter._id}
            >
              {`${promoter.firstName} ${promoter.lastName}`}
            </Option>
          );
        })}
      </LrvSelect>
    </Form.Item>;
  }

  const renderDiscountPercentage = () => {
    if (!newIsDistributionCompany) {
      return null;
    }

    return (
      <Form.Item
        required={true}
        label={t('clients.discountPercentage')}
        name='editDiscountPercentage'
        rules={[() => ({ validator (rule, value) { return validateNumber(value, true, discountPorcentage.min, discountPorcentage.max, t('users.balance.discountError', { min: discountPorcentage.min, max: discountPorcentage.max })); } })]}
      >
        <LrvInputNumber
          theme='light'
          id='txt_edit_discount_percentage'
          value={newDiscountPercentage}
          onChange={(value) => {
            if (value) {
              const discount = parseInt(value.toString());
              setNewDiscountPercentage(discount);
            }
          }}
        />
      </Form.Item>
    );
  };

  const renderDaysToInitialStage = () => {
    return (
      <Col span={12}>
        <Form.Item
          name='daysToInitialStage'
          label={t('clients.daysToInitialStage')}
          required
          rules={[() => ({ validator (rule, value) { return validateNumber(value, true, MIN_DAYS_TO_INITIAL_STAGE); } })]}
        >
          <LrvInputNumber
            theme='light'
            min={MIN_DAYS_TO_INITIAL_STAGE}
            max={MAX_DAYS_TO_INITIAL_STAGE}
            value={newDaysToInitialStage}
            onChange={(value) => {
              if (value) {
                setNewDaysToInitialStage(value);
              }
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderIsInternacional = () => {
    return (
      <Col span={12}>
        <Form.Item
          name='isInternational'
          label={t('clients.international')}
        >
          <LrvSwitch
            theme='light'
            id='international_switch'
            checked={isInternational}
            onChange={(value) => setIsInternational(value)}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderResponsibleSelectCreateForm = () => {
    return (
      <Form.Item
        required={isSuperAdmin()}
        label={t('clients.responsible')}
        name='responsible'
        rules={isSuperAdmin() ? [() => ({ validator (rule, value) { return validateString(value); } })] : undefined}
      >
        <LrvSelect
          theme='light'
          id='select_new_responsable_client'
          showSearch
          placeholder={t('clients.select')}
          value={newResponsible}
          onChange={(value) => {
            setNewResponsible(value);
            dispatch(fetchPromotersBySeller(value));

            form.setFieldsValue({
              promoter: undefined,
            });
            setNewPromoter(undefined);
          }}
          suffixIcon={<Icon name='arrow-down-s' />}
          filterOption={filterOptionSelect}
        >
          {sellers.map((seller, index) => {
            return (
              <Option
                key={index}
                value={seller._id}
              >
                {`${seller.firstName} ${seller.lastName}`}
              </Option>
            );
          })}
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderPromoterSelectCreateForm = () => {
    return (
      <Form.Item
        name='promoter'
        label={`${t('clients.promoter')} (${t('common.optional')})`}
      >
        <LrvSelect
          theme='light'
          id='select_new_promoter_client'
          showSearch
          placeholder={t('clients.select')}
          value={newPromoter}
          onChange={(value) => {
            setNewPromoter(value);
          }}
          disabled={(!newResponsible && !isSalesRole() && !isSalesManagerRole()) || promoters.length === 0}
          suffixIcon={<Icon name='arrow-down-s' />}
          filterOption={filterOptionSelect}
        >
          {promoters.map((promoter, index) => {
            return (
              <Option
                key={index}
                value={promoter._id}
              >
                {`${promoter.firstName} ${promoter.lastName}`}
              </Option>
            );
          })}
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderResponsibleAndPromoterSelect = () => {
    const showPromoterSelect = isSuperAdmin() || isSalesRole() || isSalesManagerRole();
    const showResponsibleSelect = isSuperAdmin();

    if (showPromoterSelect && showResponsibleSelect) {
      return (
        <>
          <Col span={12}>
            {renderResponsibleSelectCreateForm()}
          </Col>
          <Col span={12}>
            {renderPromoterSelectCreateForm()}
          </Col>
        </>
      );
    }

    if (showPromoterSelect) {
      return (
        <Col span={12}>
          {renderPromoterSelect()}
        </Col>
      );
    }

    return null;
  };

  const renderDistributionCompany = () => {
    return (
      <Form.Item
        id='test'
        name='distributionCompany'
        label={t('clients.distributionCompany')}
      >
        <LrvSwitch
          theme='light'
          id='distribution_company_switch'
          checked={newIsDistributionCompany}
          onChange={(value) => {
            setNewIsDistributionCompany(value);
            updatePlanPrice({ value: newPlan, newQuota, isDistributionCompany: value });
          }}
        />
      </Form.Item>
    );
  };

  const renderCurrencyCodes = () => {
    return (
      <Col span={12}>
        <Form.Item
          required
          name='currencyCode'
          label={t('clients.currencyCode')}
        >
          <LrvSelect
            id='select_currency_codes'
            theme='light'
            showSearch
            value={currencyCode}
            placeholder={t('clients.currencyCode')}
            onChange={(value) => setCurrencyCode(value)}
            suffixIcon={<Icon name='arrow-down-s' />}
          >
            {currencyList.map((currency) => {
              return (
                <Option key={currency.code} value={currency.code}>
                  {`${currency.code} - ${currency.name[language]} (${currency.symbol})`}
                </Option>
              );
            })}
          </LrvSelect>
        </Form.Item>
      </Col>
    );
  };

  const onCloseModal = () => {
    form.resetFields();
    dispatch(setShowCreateClientModal(false));

    if (isRunningOnboarding) {
      dispatch(goToOnboardingNextStep(1200));
    }
  };

  const addNewClient = () => {
    const data: NewClientBody = {
      companyName: newCompany,
      businessName: newBusinessName,
      quota: newQuota,
      planPrice: newPlanPrice,
      firstName: newUserFirstName,
      lastName: newUserLastName,
      email: newUserEmail,
      password: newUserPassword,
      isDistributor: newIsDistributionCompany,
      hasTrialPhase: hasTrialPhase,
      isInternational: isInternational,
      daysToInitialStage: newDaysToInitialStage,
      ruc: ruc,
      currencyCode,
      code: newCode,
      phone: phone,
      countryCode: countryCode,
      phonePrefix: phonePrefix,
      address: address,
      sellerId: newResponsible,
      promoterId: newPromoter,
      planType: newIsDistributionCompany ? plansTypes.POSTPAID : newPlanType,
      renuevalMonths,
      discountPercentage: newDiscountPercentage,
      allowXlsxAnalysisReport,
    };

    const active = getValueActiveUsers(clientStatus);
    dispatch(createClient({ data, active }));
  };

  return (
    <LrvModal
      theme='light'
      className={styles.newClientModal}
      title={t('clients.newClient')}
      open={showCreateClientModal}
      destroyOnClose={true}
      okButtonProps={{ form: 'formNewClient', loading: isFormCreateLoading, id: 'btn_save_new_client' }}
      onOk={() => {
        form.submit();
      }}
      okText={t('clients.create').toUpperCase()}
      cancelText={t('clients.cancel').toUpperCase()}
      style={{ top: 20 }}
      onCancel={onCloseModal}
    >
      <LrvForm
        theme='light'
        form={form}
        initialValues={{
          countryCode,
          phonePrefix,
          currencyCode,
        }}
        id='formNewClient'
        name='formNewClient'
        layout='vertical'
        onFinish={addNewClient}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='companyName'
              required
              label={t('clients.companyName')}
              rules={[{ required: true, message: t('clients.companyNameRequired') }]}
            >
              <LrvInput
                theme='light'
                autoFocus
                value={newCompany}
                onChange={(e) => setNewCompany(e.target.value)}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              required={!hasTrialPhase}
              name='businessName'
              label={hasTrialPhase ? `${t('clients.businessName')} (${t('common.optional')})` : t('clients.businessName')}
              rules={[{ required: !hasTrialPhase, message: t('clients.businessNameRequired') }]}
            >
              <LrvInput
                theme='light'
                value={newBusinessName}
                onChange={(e) => setNewBusinessName(e.target.value)}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              required={!hasTrialPhase}
              name='ruc'
              label={hasTrialPhase ? `${t('clients.ruc')} (${t('common.optional')})` : t('clients.ruc')}
              rules={[{ required: !hasTrialPhase, message: t('clients.rucRequired') }]}
            >
              <LrvInput
                theme='light'
                id='txt_ruc'
                value={ruc}
                onChange={(e) => setRuc(e.target.value)}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              required={!hasTrialPhase}
              name='address'
              label={hasTrialPhase ? `${t('clients.address')} (${t('common.optional')})` : t('clients.address')}
              rules={[{ required: !hasTrialPhase, message: t('clients.addressRequired') }]}
            >
              <LrvInput
                theme='light'
                id='txt_address'
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              required={!hasTrialPhase}
              name='code'
              label={hasTrialPhase ? `${t('clients.code')} (${t('common.optional')})` : t('clients.code')}
              rules={[{ required: !hasTrialPhase, message: t('clients.codeRequired') }]}
            >
              <LrvInput
                theme='light'
                maxLength={MAX_LENGTH_COMPANY_CODE}
                id='txt_code'
                value={newCode}
                onChange={(e) => setNewCode(e.target.value)}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('clients.phone')}
              name='phone'
              required
              rules={[
                { validator: (_, value) => validatePhoneInputGroup(value, form.getFieldValue('phone'), form.getFieldValue('countryCode')) },
              ]}
              validateTrigger={['onBlur', 'onChange']}
            >
              <Input.Group className={styles.inputGroup} compact>
                <Form.Item
                  name='countryCode'
                  noStyle
                >
                  <LrvSelect
                    theme='light'
                    showSearch
                    className={styles.countryCodeSelect}
                    containerClassName={styles.countryCodeContainer}
                    style={{ width: '100%' }}
                    suffixIcon={<Icon name='arrow-down-s' />}
                    removeIcon={<Icon name='close' />}
                    placeholder={t('clients.select')}
                    optionFilterProp='children'
                    filterOption={filterOptionSelect}
                    onChange={value => {
                      setPhonePrefix(getCountryCallingCode(value));
                      setCountryCode(value);
                    }}
                    value={countryCode}
                  >
                    {
                      getCountries().map((country) => {
                        return (
                          <Option
                            key={country}
                            value={country}
                          >
                            {`${country} +${getCountryCallingCode(country)}`}
                          </Option>
                        );
                      })
                    }
                  </LrvSelect>
                </Form.Item>

                <Form.Item
                  name='phone'
                  noStyle
                >
                  <LrvInput
                    theme='light'
                    id='txt_phone'
                    placeholder={t('clients.phone')}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name='ownerFirstName'
              label={t('clients.ownerFirstName')}
              rules={[{ required: true, message: t('clients.ownerFirstNameRequired') }]}
            >
              <LrvInput
                theme='light'
                value={newUserFirstName}
                onChange={(e) => setNewUserFirstName(e.target.value)}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name='ownerLastName'
              label={t('clients.ownerLastName')}
              rules={[{ required: true, message: t('clients.ownerLastNameRequired') }]}
            >
              <LrvInput
                theme='light'
                value={newUserLastName}
                onChange={(e) => setUserLastName(e.target.value)}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name='ownerEmail'
              label={t('clients.ownerEmail')}
              validateStatus={hasEmailError ? 'error' : undefined}
              help={hasEmailError ? t('clients.emailRepeat') : undefined}
              rules={[
                { required: true, type: 'email', message: t('clients.ownerEmailRequired') },
              ]}
            >
              <LrvInput
                theme='light'
                value={newUserEmail}
                autoComplete='off'
                onChange={(e) => setNewUserEmail(e.target.value)}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={
                <span className={styles.password}>
                  {t('clients.ownerPassword')}&nbsp;
                  <PasswordPolicy />
                </span>
              }
              required
              name='ownerPassword'
              rules={[() => ({ validator (rule, value) { return validatePasswordPolicy(value); } })]}
            >
              <LrvPassword
                theme='light'
                autoComplete='new-password'
                value={newUserPassword}
                onChange={(e) => setNewUserPassword(e.target.value)}
              />
            </Form.Item>
          </Col>

          {renderResponsibleAndPromoterSelect()}

          {renderDaysToInitialStage()}
          {renderCurrencyCodes()}

          {renderIsInternacional()}

          {
            !isDistributorCompany() &&
            <Col span={12}>
              {renderDistributionCompany()}
            </Col>
          }

          <Col span={12}>
            <Form.Item
              name='hasTrialPhase'
              label={t('clients.trialPhase')}
            >
              <LrvSwitch
                theme='light'
                id='trial_phase_switch'
                checked={hasTrialPhase}
                onChange={(value) => {
                  setHasTrialPhase(value);
                }}
              />
            </Form.Item>
          </Col>

          {hasPermissionToManageCompanies &&
            <Col span={12}>
              <Form.Item
                name='allowXlsxAnalysisReport'
                label={t('clients.allowXlsxAnalysisReport')}
              >
                <LrvSwitch
                  theme='light'
                  id='allow_xlsx_switch'
                  checked={allowXlsxAnalysisReport}
                  onChange={(value) => setAllowXlsxAnalysisReport(value)}
                />
              </Form.Item>
            </Col>}
        </Row>


        <Form.Item
          name='renuevalMonths'
          label={t('clients.renuevalMonths')}
        >
          <LrvSelect
            theme='light'
            showSearch
            mode='multiple'
            suffixIcon={<Icon name='arrow-down-s' />}
            removeIcon={<Icon name='close' />}
            placeholder={t('clients.select')}
            optionFilterProp='children'
            filterOption={filterOptionSelect}
            onChange={(e: number[]) => {
              setRenuevalMonths(e);
            }}
            value={renuevalMonths}
          >
            {
              months.map((month) => {
                return (
                  <Option
                    key={month}
                    value={month}
                  >
                    {getMonthName(month.toString())}
                  </Option>
                );
              })
            }
          </LrvSelect>
        </Form.Item>

        <Form.Item
          required
          name='plan'
          label={t('clients.plans')}
          rules={[() => ({ validator (rule, value) { return validateString(value); } })]}
        >
          <LrvSelect
            theme='light'
            placeholder={t('clients.selectPlan')}
            onChange={handleChangePlans}
            value={newPlan}
            suffixIcon={<Icon name='arrow-down-s' />}
          >
            <Option key={PREPAID_PLAN} value={PREPAID_PLAN}> {t('clients.prepaidPlan')} </Option>
            <OptGroup label={t('clients.postpaidPlans')}>
              {childrenPlan}
              <Option key={PERSONALIZED_PLAN} value={PERSONALIZED_PLAN}> {t('clients.otherPlan')} </Option>
            </OptGroup>
          </LrvSelect>
        </Form.Item>

        {
          newPlan === PERSONALIZED_PLAN &&
          <Form.Item
            name='companyBalance'
            required
            label={t('clients.companyBalance')}
            rules={[() => ({ validator (rule, value) { return validateNumber(value, true, companyBalanceLimits.MIN, companyBalanceLimits.MAX, t('validations.maxValue') + companyBalanceLimits.MAX); } })]}
          >
            <LrvInput
              theme='light'
              autoFocus
              value={newCompanyBalance}
              onChange={(e) => {
                const value = e.target.value;
                setNewCompanyBalance(value);
                updatePlanPrice({ value: PERSONALIZED_PLAN, newQuota: parseInt(value) });
              }}
            />
          </Form.Item>
        }

        {
          newPlan &&
          <Form.Item>
            <LrvText theme='light' text={t('payments.planPrice', { value: dollarUS.format(newPlanPrice) })} />
          </Form.Item>
        }

        {renderDiscountPercentage()}
      </LrvForm>
    </LrvModal>
  );
};

