import i18next from 'i18next';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Client } from '../Clients/interfaces';
import { Company } from '../AppHeader/interfaces';
import { getUserSession } from '../../utils/userSession';
import { emailError, roles } from '../../config/commons';
import { axiosClient as axios } from '../../utils/axios_instance';
import { openSuccessNotification } from '../../common/notification/Notification';
import { USERS_URL, CAMPUS_URL, CAMPUS_MAPPING_URL, SEARCH_USERS_URL, PASSWORD_RESET_URL, CLIENTS_URL, SEARCH_SELLER_URL, SEARCH_PROMOTERS_URL, SEARCH_UNASSIGNED_PROMOTERS_URL } from '../../config/config.api';

import { USERS } from './helpers';
import { User, UsersState, Campus, CampusMapping, CreateUser, EditUser } from './interfaces';

const initialState: UsersState = {
  users: [],
  sellers: [],
  promoters: [],
  campus: [],
  selectedUser: {
    _id: '',
    companyId: '',
    firstName: '',
    lastName: '',
    email: '',
    active: true,
    roles: [],
    campus: [],
    allowCompanyBalanceConsumption: false,
  },
  isUserLoading: false,
  isUsersLoading: false,
  isFormCreateLoading: false,
  isFormUpdateLoading: false,
  isResettingPassword: false,
  hasCurrentPasswordError: false,
  canCloseResettingPasswordModal: true,
  hasNewPasswordError: false,
  passwordResetErrorKey: '',
  hasEmailErrorCreate: false,
  hasEmailErrorUpdate: false,
  isUpdatingOwner: false,
  isClientLoading: false,
  owner: {
    _id: '',
    name: '',
    businessName: '',
    code: '',
    phone: '',
    phonePrefix: '',
    countryCode: '',
    active: true,
    isDistributor: true,
    isInternational: false,
    daysToInitialStage: 0,
    showStockingParameterSection: false,
    maxStage: 0,
    maxDayJuvenile: 0,
    maxDayGrowOut: 0,
    sellerId: '',
    promoterId: '',
    planType: '',
    createdAt: '',
    address: '',
    ruc: '',
    currencyCode: '',
    owner: {
      _id: '',
      firstName: '',
      lastName: '',
      email: '',
    },
  },
  isAssigningOwner: false,
  activeUsers: [],
  filters: {
    userStatus: USERS.ACTIVE,
  },
};

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state: UsersState, action: PayloadAction<User[]>) => {
      state.users = action.payload;
    },
    setCampus: (state: UsersState, action: PayloadAction<Campus[]>) => {
      state.campus = action.payload;
    },
    setSelectedUser: (state: UsersState, action: PayloadAction<User>) => {
      state.selectedUser = action.payload;
    },
    setIsUserLoading: (state: UsersState, action: PayloadAction<boolean>) => {
      state.isUserLoading = action.payload;
    },
    setIsUsersLoading: (state: UsersState, action: PayloadAction<boolean>) => {
      state.isUsersLoading = action.payload;
    },
    setIsFormCreateLoading: (state: UsersState, action: PayloadAction<boolean>) => {
      state.isFormCreateLoading = action.payload;
    },
    setIsFormUpdateLoading: (state: UsersState, action: PayloadAction<boolean>) => {
      state.isFormUpdateLoading = action.payload;
    },
    setHasEmailErrorCreate: (state: UsersState, action: PayloadAction<boolean>) => {
      state.hasEmailErrorCreate = action.payload;
    },
    setHasEmailErrorUpdate: (state: UsersState, action: PayloadAction<boolean>) => {
      state.hasEmailErrorUpdate = action.payload;
    },
    setIsResettingPassword: (state: UsersState, action: PayloadAction<boolean>) => {
      state.isResettingPassword = action.payload;
    },
    setHasNewPasswordError: (state: UsersState, action: PayloadAction<boolean>) => {
      state.hasNewPasswordError = action.payload;
    },
    setHasCurrentPasswordError: (state: UsersState, action: PayloadAction<boolean>) => {
      state.hasCurrentPasswordError = action.payload;
    },
    setPasswordResetErrorKey: (state: UsersState, action: PayloadAction<string>) => {
      state.passwordResetErrorKey = action.payload;
    },
    setCanCloseResettingPasswordModal: (state: UsersState, action: PayloadAction<boolean>) => {
      state.canCloseResettingPasswordModal = action.payload;
    },
    setIsUpdatingOwner: (state: UsersState, action: PayloadAction<boolean>) => {
      state.isUpdatingOwner = action.payload;
    },
    setIsClientLoading: (state: UsersState, action: PayloadAction<boolean>) => {
      state.isClientLoading = action.payload;
    },
    setOwner: (state: UsersState, action: PayloadAction<Client>) => {
      state.owner._id = action.payload._id;
      state.owner.name = action.payload.name;
      state.owner.active = action.payload.active;
      state.owner.owner = action.payload.owner;
      state.owner.isDistributor = action.payload.isDistributor;
    },
    resetOwner: (state: UsersState) => {
      state.owner._id = '';
      state.owner.name = '';
      state.owner.owner = {
        _id: '',
        email: '',
        firstName: '',
        lastName: ''
      };
      state.owner.isDistributor = false;
    },
    setIsAssigningOwner: (state: UsersState, action: PayloadAction<boolean>) => {
      state.isAssigningOwner = action.payload;
    },
    setActiveUsers: (state: UsersState, action: PayloadAction<[]>) => {
      state.activeUsers = action.payload;
    },
    setSellers: (state: UsersState, action: PayloadAction<User[]>) => {
      state.sellers = action.payload;
    },
    setPromoters: (state: UsersState, action: PayloadAction<User[]>) => {
      state.promoters = action.payload;
    },
    setUserStatus: (state: UsersState, action: PayloadAction<string>) => {
      state.filters.userStatus = action.payload;
    },
  },
});

export const {
  setUsers,
  setCampus,
  setSelectedUser,
  setIsUserLoading,
  setIsUsersLoading,
  setHasEmailErrorCreate,
  setIsFormCreateLoading,
  setIsFormUpdateLoading,
  setHasEmailErrorUpdate,
  setIsResettingPassword,
  setHasCurrentPasswordError,
  setHasNewPasswordError,
  setPasswordResetErrorKey,
  setCanCloseResettingPasswordModal,
  setIsUpdatingOwner,
  setIsClientLoading,
  setOwner,
  resetOwner,
  setIsAssigningOwner,
  setActiveUsers,
  setSellers,
  setPromoters,
  setUserStatus,
} = userSlice.actions;

export const fetchUsers = (props: { company?: Company; active?: boolean }) => async (dispatch: Function) => {
  const { active, company } = props;

  dispatch(setIsUsersLoading(true));
  const userSession = getUserSession();

  const params = {
    $limit: -1,
    '$select': ['firstName', 'lastName', 'companyId', 'email', 'active', 'rolesId'],
    companyId: company?._id || userSession.companyId,
    '$sort[firstName]': 1,
    active: active === undefined ? undefined : active,
  };

  try {
    const response = await axios.get<User[]>(USERS_URL, { params });
    dispatch(setUsers(response.data));

    if (company?._id) {
      dispatch(fetchCampus(company._id));
    }

    dispatch(setIsUsersLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchSearchUsers = (props: { companyId?: string; active?: boolean; name?: string | number; email?: string | number }) => async (dispatch: Function) => {
  const { companyId, active, name, email } = props;
  dispatch(setIsUsersLoading(true));

  const userSession = getUserSession();

  const params = {
    $limit: 0,
    companyId: companyId || userSession.companyId,
    '$sort[firstName]': 1,
    active: active === undefined ? undefined : active,
    name: name === undefined ? undefined : name,
    email: email === undefined ? undefined : email,
  };

  try {
    const response = await axios.get<User[]>(SEARCH_USERS_URL, { params });

    if (companyId) {
      dispatch(fetchCampus(companyId));
    }

    dispatch(setUsers(response.data));
    dispatch(setIsUsersLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

const getCampusByUser = async (userId: string) => {
  const params = {
    $limit: -1,
    userId,
    $populate: 'campusId',
    $select: ['campusId'],
  };

  const response = await axios.get<CampusMapping[]>(CAMPUS_MAPPING_URL, { params });
  const userCampus: Campus[] = [];

  for (let index = 0; index < response.data.length; index++) {
    const campus = response.data[index];

    if (campus.campusId && campus.campusId.active) {
      userCampus.push(campus.campusId);
    }
  }

  return userCampus;
};

export const fetchUser = (userId: string) => async (dispatch: Function) => {
  let user: User;
  dispatch(setIsUserLoading(true));
  dispatch(setHasEmailErrorUpdate(false));

  const paramsUser = {
    $select: ['email', 'firstName', 'lastName', 'active', 'companyId', 'sellerId', 'allowXlsxAnalysisReport', 'rolesId', 'allowCompanyBalanceConsumption'],
    '$sort[firstName]': 1,
  };

  try {
    const responseUser = await axios.get<User>(`${USERS_URL}/${userId}`, { params: paramsUser });
    user = responseUser.data;
    user.campus = await getCampusByUser(user._id);
  } catch (e) {
    console.log(e?.response);
    return;
  }

  if (user && (user.roles.includes(roles.SALES) || user.roles.includes(roles.SALES_MANAGER))) {
    user.promoters = await fetchPromotersBySeller(userId);
  }

  dispatch(setSelectedUser(user));
  dispatch(setIsUserLoading(false));
};

export const createUser = (
  userData: CreateUser,
  activeUsers: boolean | undefined,
  resetForm: Function,
) => async (dispatch: Function) => {

  dispatch(setIsFormCreateLoading(true));
  dispatch(setHasEmailErrorCreate(false));
  const userSession = getUserSession();
  const companyId = userData.company?._id || userSession.companyId;

  const body = {
    email: userData.email,
    firstName: userData.firstName,
    lastName: userData.lastName,
    password: userData.password,
    roles: userData.roles,
    sellerId: userData.sellerId,
    companyId,
    allowCompanyBalanceConsumption: userData.allowCompanyBalanceConsumption,
    allowXlsxAnalysisReport: userData.allowXlsxAnalysisReport,
  };

  try {
    const response = await axios.post(USERS_URL, body);

    const campusData: {}[] = [];
    userData.campus.forEach(item => {
      campusData.push({
        userId: response.data._id,
        campusId: item
      });
    });

    await axios.post(CAMPUS_MAPPING_URL, campusData);
    resetForm();
  } catch (e) {
    if (e.response.data?.data?.error === emailError.DUPLICATE) {
      dispatch(setHasEmailErrorCreate(true));
    }
    dispatch(setIsFormCreateLoading(false));
    console.log('ERROR', e);
    return;
  }

  dispatch(setIsFormCreateLoading(false));
  dispatch(fetchUsers({ company: userData.company, active: activeUsers }));
  openSuccessNotification(i18next.t('users.userCreated'));
};

export const updateUser = (
  userData: EditUser,
  activeUsers: boolean | undefined,
  updateSellersData: ({
    userId: string;
    companyId: string;
    sellerId: string;
  } | {
    userId: string;
    companyId: string;
    sellerId: null;
  })[],
  resetForm: Function,
) => async (dispatch: Function) => {
  dispatch(setIsFormUpdateLoading(true));
  dispatch(setIsUserLoading(true));
  dispatch(setHasEmailErrorUpdate(false));

  const userSession = getUserSession();
  const companyId = userData.company?._id || userSession.companyId;
  const patchData = {
    firstName: userData.firstName,
    lastName: userData.lastName,
    email: userData.email,
    roles: userData.roles,
    campus: userData.campus,
    active: userData.active,
    sellerId: userData.sellerId,
    allowXlsxAnalysisReport: userData.allowXlsxAnalysisReport,
    allowCompanyBalanceConsumption: userData.allowCompanyBalanceConsumption,
    companyId,
  };

  try {
    await axios.patch(`${USERS_URL}/${userData.userId}`, patchData);
    resetForm();
  } catch (e) {
    if (e.response?.data?.data?.error === emailError.DUPLICATE) {
      dispatch(setHasEmailErrorUpdate(true));
    }
    dispatch(setIsUserLoading(false));
    dispatch(setIsFormUpdateLoading(false));
    console.log('ERROR', e);
    return;
  }

  const promiseItems = [];
  for (let index = 0; index < updateSellersData.length; index++) {
    const data = updateSellersData[index];
    promiseItems.push(updateSellers(data));
  }
  await Promise.all(promiseItems);

  dispatch(fetchUsers({ company: userData.company, active: activeUsers }));
  dispatch(setIsUserLoading(false));
  dispatch(setIsFormUpdateLoading(false));
  openSuccessNotification(i18next.t('users.userUpdated'));
};

export const fetchCampus = (companyId?: string) => async (dispatch: Function) => {
  const userSession = getUserSession();
  const params = {
    $limit: -1,
    companyId: companyId || userSession.companyId,
    active: true,
    '$sort[name]': 1
  };

  try {
    const response = await axios.get<Campus[]>(CAMPUS_URL, { params });
    dispatch(setCampus(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

const checkTypePasswordError = (e: AxiosError) => (dispatch: Function) => {
  const errorResponse = e.response?.data?.data;
  if (!errorResponse) {
    return;
  }

  dispatch(setPasswordResetErrorKey(errorResponse.error));

  switch (errorResponse.field) {
    case 'currentPassword':
      dispatch(setHasCurrentPasswordError(true));
      break;

    case 'newPassword':
      dispatch(setHasNewPasswordError(true));
      break;
  }
};

export const resetUserPassword = (userId: string, currentPassword: string, newPassword: string) => async (dispatch: Function) => {
  dispatch(setIsResettingPassword(true));
  dispatch(setHasNewPasswordError(false));
  dispatch(setHasCurrentPasswordError(false));
  dispatch(setPasswordResetErrorKey(''));
  dispatch(setCanCloseResettingPasswordModal(false));

  const patchData = {
    currentPassword,
    newPassword
  };

  try {
    await axios.patch(`${PASSWORD_RESET_URL}/${userId}`, patchData);
    openSuccessNotification(i18next.t('users.passwordUpdated'));
    dispatch(setCanCloseResettingPasswordModal(true));

  } catch (e) {
    dispatch(checkTypePasswordError(e));
  }

  dispatch(setIsResettingPassword(false));
};

export const updateOwner = (userData: { userId: string; roles: string[]; companyId?: string }) => async (dispatch: Function) => {
  dispatch(setIsUpdatingOwner(true));
  try {
    const patchData = {
      roles: userData.roles,
      companyId: userData.companyId,
    };

    await axios.patch(`${USERS_URL}/${userData.userId}`, patchData);
  } catch (e) {
    console.log('ERROR', e);
  }
  dispatch(setIsUpdatingOwner(false));
};

export const fetchClient = (clientId: string) => async (dispatch: Function) => {
  let client: Client;
  dispatch(setIsClientLoading(true));

  try {
    const response = await axios.get(`${CLIENTS_URL}/${clientId}`);
    client = response.data;
  } catch (e) {
    console.log(e?.response);
    return;
  }

  dispatch(setOwner(client));
  dispatch(setIsClientLoading(false));
};

export const fetchActiveUsers = (companyId: string) => async (dispatch: Function) => {
  const userSession = getUserSession();

  try {
    const params = {
      $limit: -1,
      $select: ['firstName', 'lastName'],
      active: true,
      companyId: companyId || userSession.companyId,
      '$sort[firstName]': 1
    };

    const response = await axios.get(USERS_URL, { params });
    dispatch(setActiveUsers(response.data));

  } catch (e) {
    console.log('ERROR', e);
  }
};

export const assignOwner = (userData: { userId: string; companyId?: string }) => async (dispatch: Function) => {
  dispatch(setIsAssigningOwner(true));

  const paramsUser = {
    $select: ['_id'],
  };

  try {
    const responseUser = await axios.get(`${USERS_URL}/${userData.userId}`, { params: paramsUser });

    const patchData = {
      roles: [...responseUser.data.roles, roles.CLIENT_OWNER],
      companyId: userData.companyId,
    };

    await axios.patch(`${USERS_URL}/${userData.userId}`, patchData);
  } catch (e) {
    console.log('ERROR', e);
  }
  dispatch(setIsAssigningOwner(false));
};

export const fetchUnassignedPromoters = () => async (dispatch: Function) => {
  dispatch(setPromoters([]));

  try {
    const response = await axios.get<User[]>(SEARCH_UNASSIGNED_PROMOTERS_URL);
    if (response.data) {
      dispatch(setPromoters(response.data));
    }
  } catch (e) {
    console.log('ERROR', e?.response);
  }
};

const fetchPromotersBySeller = async (sellerId: string) => {
  try {
    const response = await axios.get<User[]>(`${SEARCH_PROMOTERS_URL}/${sellerId}`);
    return response.data;
  } catch (e) {
    console.log(e?.response);
  }

  return [];
};

export const updateSellers = async (userData: { userId: string; companyId: string; sellerId: string | null }) => {
  const patchData = {
    sellerId: userData.sellerId,
    companyId: userData.companyId,
  };

  try {
    await axios.patch(`${USERS_URL}/${userData.userId}`, patchData);
  } catch (e) {
    console.log('ERROR', e?.response);
  }
};

export const fetchSellers = () => async (dispatch: Function) => {
  try {
    const response = await axios.get<User[]>(SEARCH_SELLER_URL);
    dispatch(setSellers(response.data));
  } catch (e) {
    console.log('ERROR', e?.response);
  }
};

export const getDateToChangePassword = async (userId: string) => {
  try {
    const params = {
      '$select': ['dateToChangePassword'],
    };

    const response = await axios.get(`${USERS_URL}/${userId}`, { params });
    return response?.data?.dateToChangePassword || undefined;
  } catch (error) {
    return undefined;
  }
};

export default userSlice.reducer;
