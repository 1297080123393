import { Form, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import { getLanguage } from '../../../../utils/language';
import Icon from '../../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../../helpers/theme';
import { Language } from '../../../../common/interfaces/commons';
import { DEFAULT_CURRENCY_CODE } from '../../../../config/commons';
import { LrvForm } from '../../../../common/components/LrvForm/LrvForm';
import { useCurrency } from '../../../../hooks/currency-codes/useCurrency';
import { LrvSelect } from '../../../../common/components/LrvSelect/LrvSelect';
import ActionButton from '../../../../common/components/buttons/ActionButton';

import styles from './currency.module.scss';
import * as companySettingsSlice from './../companySettingsSlice';

const { Option } = Select;

export const Currency = () => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const theme = getCurrentTheme();
  const { currencyList } = useCurrency();
  const language = getLanguage().toLowerCase() as Language;

  const initCurrency = localStorage.getItem('currencyCode') || DEFAULT_CURRENCY_CODE;
  const [currencyCode, setCurrencyCode] = useState(initCurrency);
  
  useEffect(() => {
    form.setFieldsValue({
      currencyCode,
    });
  }, [form, currencyCode]);

  return (
    <div>
      <LrvForm
        id='formCompanySetting'
        layout='vertical'
        form={form}
        theme={theme}
        onFinish={() => {
          dispatch(companySettingsSlice.updateCurrencyCode({ currencyCode }));
        }}
      >
        <Form.Item
          required
          name='currencyCode'
          label={t('clients.currencyCode')}
        >
          <LrvSelect
            id='select_currency_codes'
            showSearch
            theme={theme}
            value={currencyCode}
            placeholder={t('clients.currencyCode')}
            suffixIcon={<Icon name='arrow-down-s' />}
            onChange={(value) => setCurrencyCode(value)}
          >
            {currencyList.map((currency) => {
              return (
                <Option key={currency.code} value={currency.code}>
                  {`${currency.code} - ${currency.name[language]} (${currency.symbol})`}
                </Option>
              );
            })}
          </LrvSelect>
        </Form.Item>

        <Form.Item>
          <div>
            <ActionButton
              id='submit_unit_button'
              className={styles.submitButton}
              type='primary'
              htmlType='submit'
            >
              {t('weightUnit.save')}
            </ActionButton>
          </div>
        </Form.Item>
      </LrvForm>
    </div>
  );
};
