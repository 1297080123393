import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getLanguage } from '../../../utils/language';
import { downloadFile } from '../../../utils/download';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { SUCCESS_QUADRANT_PDF_URL, TRANSFERS_URL } from '../../../config/config.api';

import { TransferState, TransferPayload } from './interfaces';

const initialState: TransferState = {
  transferParameters: {},
  stocking: {
    _id: '',
    name: '',
    code: '',
    campusName: '',
    moduleName: '',
    tankName: '',
  },
  transfer: {
    _id: '',
    name: '',
    transferDate: '',
    maxSmallAnimalsWeight: 0,
  },
  isDownloadingFile: false,
  isLoading: false,
};

export const transferGraphSlice = createSlice({
  name: 'transferGraph',
  initialState,
  reducers: {
    setCapsules: (state: TransferState, action: PayloadAction<TransferPayload>) => {
      state.transferParameters = action.payload.transferParameters;
      state.stocking = action.payload.stocking;
      state.transfer = action.payload.transfer;
    },
    setIsLoading: (state: TransferState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsDownloadingFile: (state: TransferState, action: PayloadAction<boolean>) => {
      state.isDownloadingFile = action.payload;
    },
  },
});

export const {
  setCapsules,
  setIsLoading,
  setIsDownloadingFile,
} = transferGraphSlice.actions;

export const fetchTransferChartData = (props: { transferId: string; accessToken: string; }) => async (dispatch: Function) => {
  const { accessToken, transferId } = props;
  dispatch(setIsLoading(true));

  const url = `${TRANSFERS_URL}/${transferId}/chart-data`;

  try {
    const response = await axios.get<TransferPayload>(url, {
      headers: { 'Authorization': accessToken },
    });

    dispatch(setCapsules(response.data));
    dispatch(setIsLoading(false));
  } catch (error) {
    console.log(error?.response);
  }
};

export interface UrlParameterStateProps {
  companyId: string;
  companyName: string;
  campusId: string;
  moduleId: string;
  phaseType: string;
  fromDate: string;
  toDate: string;
  hours: number;
  xAxis: string;
  yAxis: string;
  zAxis: string;
  circleSizeParameter: string;
  filterOutliers: boolean;
  groupData: boolean;
}

export const fetchUrlPdf = (props: UrlParameterStateProps) => async (dispatch: Function) => {
  const { companyId, companyName, campusId, moduleId, phaseType, fromDate, toDate, hours, xAxis, yAxis, zAxis, circleSizeParameter, filterOutliers, groupData } = props;
  dispatch(setIsDownloadingFile(true));

  const language = getLanguage();
  const accessToken = localStorage.getItem('accessToken');

  try {
    const response = await axios.get(SUCCESS_QUADRANT_PDF_URL);
    const url = `${response.data.url}?companyId=${companyId}&campusId=${campusId}&moduleId=${moduleId}&phaseType=${phaseType}&xAxis=${xAxis}&yAxis=${yAxis}&zAxis=${zAxis}&language=${language}&fromDate=${fromDate}&toDate=${toDate}&hours=${hours}&circleSizeParameter=${circleSizeParameter}&filterOutliers=${filterOutliers}&groupData=${groupData}&accessToken=${accessToken}`;
    await downloadFile(url, companyName, 'pdf');
    dispatch(setIsDownloadingFile(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export default transferGraphSlice.reducer;
