import { clone, cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { Col, Form, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { sortByName } from '../../utils/sort';
import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { validateNumber } from '../../utils/validations';
import { filterOptionSelect } from '../../utils/select';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { getContainerVolumeLimits } from '../../helpers/tanks.helpers';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import ActionButton from '../../common/components/buttons/ActionButton';
import { convertKilogramsToPounds, convertPoundsToKilograms, getStockingVolumeLabel } from '../../helpers/stocking.helpers';
import { LrvTooltip } from '../../common/components/LrvTooltip/LrvTooltip';
import { LrvInputNumber } from '../../common/components/LrvInputNumber/LrvInputNumber';
import { applyThousandsSeparator, applyParserThousandsSeparator } from '../../utils/strings';
import { stockingPhaseTypes, stockingCodeLength, containerTypes, weightUnitsByCompany, weightUnits, roundTwoDecimals } from '../../config/commons';

import styles from './DestinationStockingForm.module.scss';
import * as finishStockingSlice from './finishStockingSlice';
import { defaultDestinationStocking } from './sowings.helpers';
import * as transferStockingSlice from './transferStockingSlice';
import { Campus, Module, Container, DataDestinationStocking, DestinationStocking } from './interfaces';

interface Props {
  theme?: 'dark' | 'light';
  destinationStocking: DataDestinationStocking;
  index: number;
  newStockingPhaseType: string;
}

const { Option } = Select;

function DestinationStockingForm (props: Props) {
  const { destinationStocking, index, theme = 'dark', newStockingPhaseType } = props;
  const { kilogramsTransferred, poundsTransferred, plgTransferred, averageHarvestedWeight, animalsNumber, campusId, code, moduleId, name, tankId, volume } = destinationStocking;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [tanks, setTanks] = useState<Container[]>([]);
  const [modules, setModules] = useState<Module[]>([]);
  const [stocking, setStocking] = useState<DestinationStocking | undefined>();
  const [invalidStocking, setInvalidStocking] = useState<boolean>(false);

  const { dataDestinationStockings, stockingFinishedData } = useSelector((state: Store) => state.finishStocking);
  const { volumeRanges } = useSelector((state: Store) => state.campus);
  const { phaseType, company } = useSelector((state: Store) => state.header);
  const { campuses } = useSelector((state: Store) => state.transferStocking);
  const { selectedStocking } = useSelector((state: Store) => state.stockings);

  const selectedContainer = tanks.find((tank) => tank._id === tankId);
  const selectedContainerType = selectedContainer?.type || containerTypes.TANK;
  const containerVolumeLimits = getContainerVolumeLimits({ volumeRanges, phaseType });

  useEffect(() => {
    if (!tankId) {
      setStocking(undefined);
      setInvalidStocking(false);
    }
  }, [tankId]);

  const onChangeStockingCampus = async (value: string) => {
    const dataDestinationStockingCopy = cloneDeep(dataDestinationStockings);
    dataDestinationStockingCopy[index].campusId = value;
    dataDestinationStockingCopy[index].moduleId = '';
    dataDestinationStockingCopy[index].tankId = '';
    dataDestinationStockingCopy[index].volume = 0;

    dispatch(finishStockingSlice.setDataDestinationStocking(dataDestinationStockingCopy));

    try {
      const modules = await transferStockingSlice.fetchModules({ campusId: value, phaseType: newStockingPhaseType });
      setModules(modules);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeStockingModule = async (value: string) => {
    const dataDestinationStockingCopy = cloneDeep(dataDestinationStockings);
    dataDestinationStockingCopy[index].moduleId = value;
    dataDestinationStockingCopy[index].tankId = '';
    dataDestinationStockingCopy[index].volume = 0;

    dispatch(finishStockingSlice.setDataDestinationStocking(dataDestinationStockingCopy));

    try {
      const tanks = await transferStockingSlice.fetchTanks({ campusId, moduleId: value });
      setTanks(tanks);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeStockingTank = async (value: string) => {
    const dataDestinationStockingCopy = cloneDeep(dataDestinationStockings);
    const tank = tanks.find((tank) => tank._id === value);

    dataDestinationStockingCopy[index].tankId = value;
    dataDestinationStockingCopy[index].tankType = tank?.type;
    dataDestinationStockingCopy[index].volume = tank?.volume;

    try {
      const stockingData = await transferStockingSlice.fetchStocking({ campusId: dataDestinationStockingCopy[index].campusId, moduleId: dataDestinationStockingCopy[index].moduleId, tankId: value });

      if (!stockingData) {
        setInvalidStocking(false);
        setStocking(undefined);
        dataDestinationStockingCopy[index].stockingId = undefined;
        dispatch(finishStockingSlice.setDataDestinationStocking(dataDestinationStockingCopy));
        return;
      }

      if (stockingData?.transferRecord) {
        setInvalidStocking(false);
        setStocking(stockingData);
        dataDestinationStockingCopy[index].stockingId = stockingData._id;
        dispatch(finishStockingSlice.setDataDestinationStocking(dataDestinationStockingCopy));
        return;
      }

      setInvalidStocking(true);
      setStocking(undefined);
      dataDestinationStockingCopy[index].stockingId = undefined;
      dispatch(finishStockingSlice.setDataDestinationStocking(dataDestinationStockingCopy));
    } catch (error) {
      console.log(error);
    }
  };

  const renderUnitDropdown = () => {
    const campusesData = clone(campuses);
    campusesData.sort(sortByName);

    return (
      <Col span={12}>
        <Form.Item
          label={t('stockings.campus')}
          required
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <LrvSelect
            theme={theme}
            showSearch
            value={campusId || undefined}
            onChange={onChangeStockingCampus}
            disabled={!newStockingPhaseType}
            suffixIcon={<Icon name='arrow-down-s' />}
            filterOption={filterOptionSelect}
            dropdownMatchSelectWidth={false}
          >
            {campusesData.map((campus: Campus) => <Option key={campus._id} value={campus._id}>{campus.name}</Option>)}
          </LrvSelect>
        </Form.Item>
      </Col>
    );
  };

  const renderModuleDropdown = () => {
    const modulesData = clone(modules);
    modulesData.sort(sortByName);

    return (
      <Col span={12}>
        <Form.Item
          label={t('stockings.module')}
          required
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <LrvSelect
            theme={theme}
            showSearch
            value={moduleId || undefined}
            onChange={onChangeStockingModule}
            suffixIcon={<Icon name='arrow-down-s' />}
            disabled={!campusId}
            filterOption={filterOptionSelect}
            dropdownMatchSelectWidth={false}
          >
            {modulesData.map((module: Module) => <Option key={module._id} value={module._id}>{module.name}</Option>)}
          </LrvSelect>
        </Form.Item>
      </Col>
    );
  };

  const renderContainerDropdown = () => {
    const tanksData = clone(tanks);
    tanksData.sort(sortByName);

    return (
      <Col span={12}>
        <Form.Item
          label={t('stockings.containerTypes.ADULT')}
          required
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <LrvSelect
            theme={theme}
            showSearch
            value={tankId || undefined}
            onChange={onChangeStockingTank}
            suffixIcon={<Icon name='arrow-down-s' />}
            disabled={!moduleId}
            filterOption={filterOptionSelect}
            dropdownMatchSelectWidth={false}
          >
            {tanksData.map((container: Container) => <Option key={container._id} value={container._id}>{container.name}</Option>)}
          </LrvSelect>
        </Form.Item>
      </Col>
    );
  };

  const onChangeStockingCode = (value: string) => {
    const dataDestinationStockingCopy = cloneDeep(dataDestinationStockings);
    dataDestinationStockingCopy[index].code = value;

    dispatch(finishStockingSlice.setDataDestinationStocking(dataDestinationStockingCopy));
  };

  const onChangeStockingName = (value: string) => {
    const dataDestinationStockingCopy = cloneDeep(dataDestinationStockings);
    dataDestinationStockingCopy[index].name = value;

    dispatch(finishStockingSlice.setDataDestinationStocking(dataDestinationStockingCopy));
  };

  const onChangeStockingVolume = (value: number) => {
    if (!value) {
      return;
    }

    const dataDestinationStockingCopy = cloneDeep(dataDestinationStockings);
    dataDestinationStockingCopy[index].volume = value;

    dispatch(finishStockingSlice.setDataDestinationStocking(dataDestinationStockingCopy));
  };

  const onChangeAnimalsNumber = (value: number) => {
    if (!value) {
      return;
    }

    const dataDestinationStockingCopy = cloneDeep(dataDestinationStockings);
    dataDestinationStockingCopy[index].animalsNumber = value;

    dispatch(finishStockingSlice.setDataDestinationStocking(dataDestinationStockingCopy));
  };

  const onChangeKilogramsTransferred = (value: number) => {
    if (!value) {
      return;
    }

    const kilogramsTransferred: string | number = value || '';
    const pounds = roundTwoDecimals(convertKilogramsToPounds(kilogramsTransferred));
    
    const dataDestinationStockingCopy = cloneDeep(dataDestinationStockings);
    dataDestinationStockingCopy[index].kilogramsTransferred = value;
    dataDestinationStockingCopy[index].poundsTransferred = pounds;

    dispatch(finishStockingSlice.setDataDestinationStocking(dataDestinationStockingCopy));
  };

  const onChangePoundsTransferred = (value: number) => {
    if (!value) {
      return;
    }

    const poundsTransferred: string | number = value || '';
    const kilograms = roundTwoDecimals(convertPoundsToKilograms(poundsTransferred));
    
    const dataDestinationStockingCopy = cloneDeep(dataDestinationStockings);
    dataDestinationStockingCopy[index].poundsTransferred = value;
    dataDestinationStockingCopy[index].kilogramsTransferred = kilograms;

    dispatch(finishStockingSlice.setDataDestinationStocking(dataDestinationStockingCopy));
  };

  const onChangeAverageHarvestedWeight = (value: number) => {
    if (!value) {
      return;
    }

    const dataDestinationStockingCopy = cloneDeep(dataDestinationStockings);
    dataDestinationStockingCopy[index].averageHarvestedWeight = value;

    if (selectedStocking.phaseType === stockingPhaseTypes.LARVAE) {
      const plg = Math.round(1000 / parseFloat(value.toString()) * 100) / 100;
      dataDestinationStockingCopy[index].plgTransferred = plg;
    }

    dispatch(finishStockingSlice.setDataDestinationStocking(dataDestinationStockingCopy));
  };

  const onChangePlgHarvest = (value: number) => {
    if (!value) {
      return;
    }

    const average = Math.round(1000 / parseFloat(value.toString()) * 100) / 100;
    const dataDestinationStockingCopy = cloneDeep(dataDestinationStockings);
    dataDestinationStockingCopy[index].plgTransferred = value;
    dataDestinationStockingCopy[index].averageHarvestedWeight = average;

    dispatch(finishStockingSlice.setDataDestinationStocking(dataDestinationStockingCopy));
  };

  const renderStockingNameDefault = () => {
    if (!stocking?._id) {
      return null;
    }

    return (
      <Col span={12}>
        <Form.Item
          label={t('stockings.stocking')}
        >
          <LrvInput
            theme={theme}
            value={stocking?.name}
            readOnly
          />
        </Form.Item>
      </Col>
    );
  };

  const renderStockingCode = () => {
    if (stocking?._id || !tankId) {
      return null;
    }

    return (
      <Col span={12}>
        <Form.Item
          required
          label={t('stockings.productionCycle')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <LrvInput
            theme={theme}
            value={code}
            style={{ textTransform: 'uppercase' }}
            maxLength={stockingCodeLength}
            onChange={(e) => onChangeStockingCode(e.target.value)}
            disabled={invalidStocking}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderStockingName = () => {
    if (stocking?._id || !tankId) {
      return null;
    }

    return (
      <Col span={12}>
        <Form.Item
          label={t('stockings.name')}
          required
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <LrvInput
            theme={theme}
            value={name}
            onChange={(e) => onChangeStockingName(e.target.value)}
            disabled={invalidStocking}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderStockingVolume = () => {
    if (stocking?._id || !tankId) {
      return null;
    }

    return (
      <Col span={12}>
        <Form.Item
          label={getStockingVolumeLabel(selectedContainerType)}
          required
          rules={
            selectedContainerType === '' ?
              [() => ({ validator (rule, value) { return validateNumber(value, false, 0); } })] :
              [() => ({ validator (rule, value) { return validateNumber(value, false, containerVolumeLimits[selectedContainerType].min, containerVolumeLimits[selectedContainerType].max, t('stockings.volumeError', { min: containerVolumeLimits[selectedContainerType].min, max: containerVolumeLimits[selectedContainerType].max })); } })]
          }
        >
          <LrvInputNumber
            theme={theme}
            value={volume}
            formatter={value => applyThousandsSeparator(value)}
            parser={value => applyParserThousandsSeparator(value, true)}
            min={0}
            onChange={(value) => onChangeStockingVolume(Number(value))}
            disabled={invalidStocking}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderAnimalsNumber = () => {
    if (!tankId) {
      return null;
    }

    const label = t(`stockings.finishStockingLabels.${selectedStocking.phaseType}.animalsTransferred`);

    return (
      <Col span={12}>
        <Form.Item
          label={label}
          required
          rules={[() => ({
            validator (rule, value) {
              return validateNumber(value, false, 1);
            }
          })]}
        >
          <LrvInputNumber
            theme={theme}
            value={animalsNumber}
            formatter={value => applyThousandsSeparator(value)}
            parser={value => applyParserThousandsSeparator(value)}
            min={0}
            onChange={(value) => onChangeAnimalsNumber(Number(value))}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderKilogramsTransferred = () => {
    if (!tankId) {
      return null;
    }

    const label = `stockings.finishStockingLabels.${selectedStocking.phaseType}.kilogramsTransferred`;

    return (
      <Col
        span={12}
        hidden={company.weightUnit === weightUnitsByCompany.POUND}
      >
        <Form.Item
          label={`${t(label)} (${t('common.optional')})`}
        >
          <LrvInputNumber
            theme={theme}
            value={kilogramsTransferred}
            formatter={value => applyThousandsSeparator(value)}
            parser={value => applyParserThousandsSeparator(value, true)}
            onChange={(value) => onChangeKilogramsTransferred(Number(value))}
          />
        </Form.Item>
      </Col>
    );
  };
  
  const renderPoundsTransferred = () => {
    if (!tankId) {
      return null;
    }

    const label = `stockings.finishStockingLabels.${selectedStocking.phaseType}.poundsTransferred`;

    return (
      <Col
        span={12}
        hidden={company.weightUnit === weightUnitsByCompany.KILOGRAM}
      >
        <Form.Item
          label={`${t(label)} (${t('common.optional')})`}
        >
          <LrvInputNumber
            theme={theme}
            value={poundsTransferred}
            formatter={value => applyThousandsSeparator(value)}
            parser={value => applyParserThousandsSeparator(value, true)}
            onChange={(value) => onChangePoundsTransferred(Number(value))}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderPlgHarvest = () => {
    if (selectedStocking.phaseType !== stockingPhaseTypes.LARVAE || !tankId) {
      return null;
    }

    const title = t('stockings.finishStockingLabels.LARVAE.plgTransferred');
    const { min, max } = stockingFinishedData.larvae.larvaePerGram;
    const required = selectedStocking.phaseType === stockingPhaseTypes.LARVAE;

    return (
      <Col span={12}>
        <Form.Item
          label={title}
          required={required}
          rules={required ? [() => ({
            validator (rule, value) {
              return validateNumber(value, false, min, max, t('stockings.plgHarvestError', { min, max }));
            }
          })] : undefined}
        >
          <LrvInputNumber
            theme={theme}
            value={plgTransferred}
            min={min}
            max={max}
            onChange={(value) => onChangePlgHarvest(Number(value))}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderAverageHarvestedWeight = () => {
    if (!tankId) {
      return null;
    }

    let max: number | undefined = stockingFinishedData.larvae.larvaePerGram.max;
    let min: number | undefined = stockingFinishedData.larvae.larvaePerGram.min;

    if (phaseType === stockingPhaseTypes.JUVENILE) {
      min = stockingFinishedData.juvenile.averageHarvestedWeight.min;
      max = undefined;
    }

    if (phaseType === stockingPhaseTypes.ADULT) {
      min = stockingFinishedData.growOut.averageHarvestedWeight.min;
      max = undefined;
    }

    const label = `stockings.finishStockingLabels.${selectedStocking.phaseType}.transferWeight`;
    const unit = phaseType === stockingPhaseTypes.LARVAE ? weightUnits.MG : weightUnits.G;

    return (
      <Col span={12}>
        <Form.Item
          label={`${t(label)} ${unit}`}
          required
          rules={[() => ({
            validator (rule, value) {
              return validateNumber(value, false, min);
            }
          })]}
        >
          <LrvInputNumber
            theme={theme}
            value={averageHarvestedWeight}
            min={min}
            max={max}
            formatter={value => applyThousandsSeparator(value)}
            parser={value => applyParserThousandsSeparator(value, true)}
            onChange={(value) => onChangeAverageHarvestedWeight(Number(value))}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderInvalidStocking = () => {
    if (!invalidStocking) {
      return null;
    }

    return (
      <Col span={24}>
        <Form.Item>
          <LrvText
            text={t('stockings.finishStockingLabels.errorTransfer')}
            theme={theme}
            className={styles.alertError}
          />
        </Form.Item>
      </Col>
    );
  };

  const getAnimalsNumber = () => {
    if (!stocking?._id) {
      return '0';
    }

    switch (newStockingPhaseType) {
      case stockingPhaseTypes.LARVAE:
        return applyThousandsSeparator(`${stocking.naupliusNumber}`);

      case stockingPhaseTypes.JUVENILE:
        return applyThousandsSeparator(`${stocking.juvenilesNumber}`);

      case stockingPhaseTypes.ADULT:
        return applyThousandsSeparator(`${stocking.growOutNumber}`);
    }
  };

  const renderStockingAnimalSown = () => {
    if (invalidStocking || !stocking?._id) {
      return null;
    }

    return (
      <Col span={24}>
        <Form.Item>
          <LrvText text={`${t('stockings.finishStockingLabels.destinationStockingInfo', { stockingName: stocking.name, animals: getAnimalsNumber() })}`} theme={theme} />
        </Form.Item>
      </Col>
    );
  };

  const renderStockingDescription = () => {
    if (dataDestinationStockings.length <= 1) {
      return null;
    }

    return (
      <Row className={styles.titleNewStocking} align='middle' justify='space-between'>
        <LrvText text={`${t('stockings.stocking')}: ${index + 1}`} theme={theme} />

        <LrvTooltip
          themeStyle='light'
          title={t('stockings.delete')}
        >
          <ActionButton
            type='text'
            icon={<Icon name='delete-bin' type='line' theme={theme} />}
            onClick={() => {
              const dataDestinationStockingCopy = cloneDeep(dataDestinationStockings);
              const newDestinationStocking = dataDestinationStockingCopy.filter((item, i) => i !== index);

              if (newDestinationStocking.length === 0) {
                newDestinationStocking.push(defaultDestinationStocking);
              }
              dispatch(finishStockingSlice.setDataDestinationStocking(newDestinationStocking));
            }}
            className={styles.deleteButton}
          />
        </LrvTooltip>
      </Row>
    );
  };

  return (
    <div className={styles.destinationStocking}>
      {renderStockingDescription()}

      <Row gutter={16}>
        {renderUnitDropdown()}
        {renderModuleDropdown()}

        {renderContainerDropdown()}
        {renderStockingNameDefault()}

        {renderStockingCode()}
        {renderStockingName()}

        {renderStockingVolume()}
        {renderKilogramsTransferred()}
        {renderPoundsTransferred()}
        {renderPlgHarvest()}
        {renderAverageHarvestedWeight()}
        {renderAnimalsNumber()}
        {renderInvalidStocking()}
        {renderStockingAnimalSown()}
      </Row>
    </div>
  );
}

export default DestinationStockingForm;