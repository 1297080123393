import i18next from 'i18next';
import { capitalize, groupBy, mean } from 'lodash';

import { GenericParam } from '../../../interfaces/commons';
import { ItemReferenceCurves } from '../../../../pages/Units/interfaces';
import { isConsolidatedAnalysis } from '../../../../pages/Analysis/helpers';
import { Area, DataSource, Point, ResultData } from '../../../../pages/home/interfaces';
import { GrowthDeltaStocking, GrowthDeltaStage, Stocking } from '../../../../pages/Sowings/interfaces';
import { stockingPhaseTypes, DEFAULT_STAGE_MAX, roundTwoDecimals, roundWeight, roundLength, analysisTypes, TICKS_DAYS_RANGE } from '../../../../config/commons';

export const minStage = 1;

export const rectColor = {
  dark: '#030829',
  light: '#fff',
};

export const lineColor = {
  dark: 'white',
  light: '#5074D1',
};

export const selectedTickStoke = {
  dark: 'white',
  light: '#737373',
};

export const colorsPoints = ['#58A03D', '#B9598C', '#42B3C3', '#BBBF47', '#747DEF', '#D98880', '#8E44AD', '#16A085', '#E59866', '#58A03D', '#D5DBDB'];
export const colorsStroke = ['#438c29', '#a54578', '#2ea0af', '#a7ab33', '#6069db', '#CD6155', '#7D3C98 ', '#138D75', '#DC7633', '#438c29', '#BFC9CA'];

export const GREEN_COLOR = 'green';
export const CRIMSON_COLOR = 'crimson';
export const ORANGE_COLOR = 'orange';
export const DARK_RED_COLOR = '#8B0000';
export const LIME_COLOR = 'lime';
export const BLUE_COLOR = '#696ffb';

export const palette = {
  GLOBAL: 'rgba(80, 174, 255, 0.15)',
  COMPANY: 'rgba(254, 255, 0, 0.15)',
  MATURATION: 'rgba(52, 238, 255, 0.15)',
  HIDDEN: 'rgba(31, 41, 64,0)'
};

export const typesChart = {
  STOCKINGS: 'STOCKINGS',
  MATURATIONS: 'MATURATIONS',
  LABORATORY: 'LABORATORY',
};

export const metricsStatuses = {
  COMPANY: 'COMPANY',
  GLOBAL: 'GLOBAL',
  MATURATION: 'MATURATION',
  GROWTH_DELTA: 'GROWTH_DELTA',
  REFERENCE_CURVE: 'REFERENCE_CURVE',
};

export const isReferenceCurveMetric = (metricStatus?: string) => {
  const { COMPANY, MATURATION, GLOBAL, GROWTH_DELTA } = metricsStatuses;
  const match = [COMPANY, GLOBAL, MATURATION, GROWTH_DELTA];
  return !match.some(item => item === metricStatus);
};

export const typeParam = {
  PLG: 'PLG',
  UNIFORMITY: 'UNIFORMITY',
  AVG_WEIGHT: 'AVG_WEIGHT',
  AVG_LENGTH: 'AVG_LENGTH',
  CV_LENGTH: 'CV_LENGTH',
  GROWTH_DELTA: 'GROWTH_DELTA',
  PIGMENTATION: 'PIGMENTATION',
  FACTOR_K: 'FACTOR_K',
};

export const typeScale = {
  LINEAR: 'LINEAR',
  LOGARITHMIC: 'LOGARITHMIC',
};

export const estimators = {
  DERIVATIVE: 'DERIVATIVE',
};

export function fillArea (dataY1: number[], dataY2: number[], initialValue: number) {
  const area: Area[] = [];

  for (let i = 0; i < dataY1.length; ++i) {
    const a: Area = { x: i + initialValue, low: dataY2[i], high: dataY1[i] };
    area.push(a);
  }

  return area;
}

export function fillReferenceCurveArea (props: { dataY1: number[]; dataY2: number[]; stages: number[] }) {
  const { dataY1, dataY2, stages } = props;
  const area: Area[] = [];

  for (let i = 0; i < stages.length; ++i) {
    const a: Area = { x: stages[i], low: dataY2[i], high: dataY1[i] };
    area.push(a);
  }

  return area;
}

export function getFill (params: { dataSource?: string; isMaturationCurves?: boolean }) {
  const { dataSource, isMaturationCurves = false } = params;

  if (isMaturationCurves) {
    return palette.GLOBAL;
  }

  switch (dataSource) {
    case metricsStatuses.GLOBAL:
      return palette.GLOBAL;

    case metricsStatuses.COMPANY:
      return palette.COMPANY;

    default:
      return palette.GLOBAL;
  }
}

export function getMarginArea (parameter: string) {
  const marginPlg = 0.1;
  const marginUniformity = 0.05;
  const marginPigmentation = 0.05;
  const marginDefault = 0.1;

  switch (parameter) {
    case typeParam.PLG:
      return marginPlg;

    case typeParam.UNIFORMITY:
      return marginUniformity;

    case typeParam.PIGMENTATION:
      return marginPigmentation;

    default:
      return marginDefault;
  }
}

/* eslint-disable max-depth*/
export function calcStages (dataSource: DataSource[], initialValue: number, maxStage: number = DEFAULT_STAGE_MAX) {
  const stages = [];

  for (let index = 0; index < dataSource.length; index++) {
    const points = dataSource[index].points;
    const enabled = dataSource[index].enabled;

    if (!enabled) {
      continue;
    }

    if (points && points.length !== 0) {
      for (const point of points) {
        const stage = point.inputData.stage;
        stages.push(stage);
      }
      continue;
    }
    const avgPoint = dataSource[index].avgPoint;
    for (const point of avgPoint) {
      const stage = point.inputData.stage;
      stages.push(stage);
    }
  }

  let firstStage = -1;
  let lastStage = -1;

  for (let index = 0; index < stages.length; index++) {
    const stage = stages[index];
    if ((firstStage === -1 || firstStage > stage)) {
      firstStage = stage;
    }

    if (lastStage < stage) {
      lastStage = stage;
    }
  }

  const shouldDrawWholeArea = (lastStage - firstStage + 1) < 2 || lastStage === firstStage;
  firstStage = shouldDrawWholeArea ? firstStage - 1 < 1 ? initialValue : firstStage - 1 : firstStage;
  lastStage = shouldDrawWholeArea ? lastStage + 1 > maxStage ? maxStage : lastStage + 1 : lastStage;
  return [firstStage, lastStage];
}

export function calcDeltaStages (dataSource: GrowthDeltaStocking[], maxStage: number = DEFAULT_STAGE_MAX) {
  const stages = [];
  for (let index = 0; index < dataSource.length; index++) {
    const points = dataSource[index].data;
    const enabled = dataSource[index].enabled;

    if (enabled) {
      if (points && points.length !== 0) {

        for (const point of points) {
          const stage = point.stage;
          stages.push(stage);
        }
      }
    }
  }

  let firstStage = -1;
  let lastStage = 0;

  for (let index = 0; index < stages.length; index++) {
    const stage = stages[index];
    if ((firstStage === -1 || firstStage > stage)) {
      firstStage = stage;
    }

    if (lastStage < stage) {
      lastStage = stage;
    }
  }

  const shouldDrawWholeArea = (lastStage - firstStage + 1) < 2 || lastStage === firstStage;
  firstStage = shouldDrawWholeArea ? firstStage - 1 < 1 ? 1 : firstStage - 1 : firstStage;
  lastStage = shouldDrawWholeArea ? lastStage + 1 > maxStage ? maxStage : lastStage + 1 : lastStage;
  return [firstStage, lastStage];
}

export function calcReferenceCurveStages (props: { stockingGroupList?: DataSource[], itemReferenceCurves?: ItemReferenceCurves[], maxStage?: number; }) {
  const { stockingGroupList = [], itemReferenceCurves = [], maxStage = DEFAULT_STAGE_MAX } = props;
  const stages = [];

  for (let index = 0; index < stockingGroupList.length; index++) {
    const points = stockingGroupList[index].points;
    const enabled = stockingGroupList[index].enabled;

    if (!enabled) {
      continue;
    }

    if (points && points.length !== 0) {
      for (const point of points) {
        const stage = point.inputData.stage;
        stages.push(stage);
      }
      continue;
    }
    const avgPoint = stockingGroupList[index].avgPoint;
    for (const point of avgPoint) {
      const stage = point.inputData.stage;
      stages.push(stage);
    }
  }

  for (let index = 0; index < itemReferenceCurves.length; index++) {
    const stage = itemReferenceCurves[index].stage;
    stages.push(stage);
  }

  let firstStage = -1;
  let lastStage = -1;

  for (let index = 0; index < stages.length; index++) {
    const stage = stages[index];
    if (stage !== undefined && (firstStage === -1 || firstStage > stage)) {
      firstStage = stage;
    }

    if (stage && lastStage < stage) {
      lastStage = stage;
    }
  }

  const shouldDrawWholeArea = (lastStage - firstStage + 1) < 2 || lastStage === firstStage;
  firstStage = shouldDrawWholeArea ? firstStage - 1 < 1 ? 1 : firstStage - 1 : firstStage;
  lastStage = shouldDrawWholeArea ? lastStage + 1 > maxStage ? maxStage : lastStage + 1 : lastStage;
  return { firstStage, lastStage };
}

export function sortDeltaData (deltaData: GrowthDeltaStocking[], stockingName: string, listStockingsName: string[], enabledStocking: boolean[]) {
  const deltaStockings = [];
  const deltaDataCopy: GrowthDeltaStocking[] = [];

  deltaData.forEach((metric) => {
    const metricCopy: GrowthDeltaStage[] = [];
    metric.data.forEach((value) => {
      if (value.delta !== undefined) {
        metricCopy.push(value);
      }
    });

    deltaDataCopy.push({ stockingId: metric.stockingId, data: metricCopy, enabled: metric.enabled, name: metric.name });
  });

  if (deltaDataCopy.length > 1) {
    deltaDataCopy.forEach((metric, index) => {
      const tempMetric = { ...metric };
      tempMetric.enabled = enabledStocking[index];
      tempMetric.name = listStockingsName[index];
      deltaStockings.push(tempMetric);
    });
  } else {
    const tempMetric = { ...deltaDataCopy[0] };
    tempMetric.enabled = true;
    tempMetric.name = stockingName;
    deltaStockings.push(tempMetric);
  }

  return deltaStockings;
}

export function getMinYForPigmentation (dataSource: DataSource[], firstStage: number, lastStage: number) {
  let minY = 1;

  for (let index = 0; index < dataSource.length; index++) {
    const points = dataSource[index].points;
    const enabled = dataSource[index].enabled;

    if (enabled && points) {
      for (const result of points) {
        if (minY > result.y && firstStage <= result.x && result.x <= lastStage) {
          minY = result.y;
        }
      }
    }
  }

  return minY;
}

export function getMaxYForPigmentation (dataSource: DataSource[], firstStage: number, lastStage: number) {
  let maxY = 0;

  for (let index = 0; index < dataSource.length; index++) {
    const points = dataSource[index].points;
    const enabled = dataSource[index].enabled;

    if (enabled && points) {
      for (const result of points) {
        if (maxY < result.y && firstStage <= result.x && result.x <= lastStage) {
          maxY = result.y;
        }
      }
    }
  }

  return maxY;
}

export function getMinY (dataSource: DataSource[], area: Area[], firstStage: number, lastStage: number, chart: string) {
  let minY = 0;
  for (let index = 0; index < area.length; index++) {
    const element = area[index];
    if (firstStage <= element.x && element.x <= lastStage) {
      if (minY === 0 || minY > element.low) {
        minY = element.low;
      }
    }
  }
  for (let index = 0; index < dataSource.length; index++) {
    const points = getPoints(dataSource[index], chart);
    const enabled = dataSource[index].enabled;

    if (!enabled || !points) {
      continue;
    }

    for (const result of points) {
      if (minY === 0 || (minY > result.y && firstStage <= result.x && result.x <= lastStage)) {
        minY = result.y;
      }
    }
  }

  return minY;
}

export function getMaxY (dataSource: DataSource[], area: Area[], firstStage: number, lastStage: number, chart: string) {
  let maxY = 0;
  for (let index = 0; index < area.length; index++) {
    const element = area[index];
    if (firstStage <= element.x && element.x <= lastStage) {
      if (maxY < element.high) {
        maxY = element.high;
      }
    }
  }

  for (let index = 0; index < dataSource.length; index++) {
    const points = getPoints(dataSource[index], chart);
    const enabled = dataSource[index].enabled;

    if (!enabled || !points) {
      continue;
    }

    for (const result of points) {
      if (maxY < result.y && firstStage <= result.x && result.x <= lastStage) {
        maxY = result.y;
      }
    }
  }

  return maxY;
}

export function getParameterLabel (parameter: string) {
  switch (parameter) {
    case typeParam.PLG:
      return i18next.t('analysis.resultData.larvaePerGram');
    case typeParam.UNIFORMITY:
      return capitalize(i18next.t('stockings.pdf.typeParameter.uniformity').toLowerCase());
    case typeParam.AVG_WEIGHT:
      return i18next.t('analysis.resultData.averageWeight');
    case typeParam.AVG_LENGTH:
      return i18next.t('analysis.resultData.averageLength');
    case typeParam.CV_LENGTH:
      return i18next.t('analysis.resultData.variationCoefficientLength');
    case typeParam.PIGMENTATION:
      return i18next.t('shadedplot.type.pigmentation');
    default:
      return i18next.t('analysis.resultData.larvaePerGram');
  }
}

export function getPointX (point: Point, chart: string) {
  let value = 0;

  switch (chart) {
    case typesChart.STOCKINGS:
      value = point.inputData.stage;
      break;

    case typesChart.LABORATORY:
    case typesChart.MATURATIONS:
      value = point.x;
      break;
  }

  return value;
}

export function getPointY (point: Point, chart: string, parameter: string) {
  let value = 0;

  switch (chart) {
    case typesChart.STOCKINGS:
      value = getValueY(parameter, point.resultData);
      break;

    case typesChart.LABORATORY:
    case typesChart.MATURATIONS:
      value = point.y;
      break;
  }

  return value;
}

export function getPoints (dataSource: DataSource, chart: string) {
  let value: Point[] | undefined;

  switch (chart) {
    case typesChart.STOCKINGS:
      value = dataSource.points;
      break;

    case typesChart.LABORATORY:
    case typesChart.MATURATIONS:
      value = dataSource.avgPoint;
      break;
  }

  return value;
}

export function filterPointByStage (points: Point[], stage: number) {
  return points.filter((point) => point.x === stage);
}

export function filterPointConsolidated (points: Point[]) {
  return points.filter((point) => isConsolidatedAnalysis(point.type));
}

export function filterPointNotConsolidated (points: Point[]) {
  return points.filter((point) => !isConsolidatedAnalysis(point.type));
}

export function getValueY (parameter: string, resultData: ResultData | undefined) {
  if (!resultData) {
    return 0;
  }

  switch (parameter) {
    case typeParam.PLG:
      return resultData.larvaePerGram;
    case typeParam.UNIFORMITY:
      return resultData.uniformity;
    case typeParam.AVG_WEIGHT:
      return resultData.averageWeight;
    case typeParam.AVG_LENGTH:
      return resultData.averageLength;
    case typeParam.CV_LENGTH:
      return resultData.variationCoefficientLength;
    case typeParam.PIGMENTATION:
      return resultData.pigmentation;
    case typeParam.FACTOR_K:
      return resultData.animalsAboveConditionFactor || 0;
    default:
      return 0;
  }
}

export function getMeanValueY (props: { parameter: string; mean: number; forceCast?: boolean }) {
  const { parameter, mean, forceCast = true } = props;

  switch (parameter) {
    case typeParam.PLG:
      return Math.round(mean);
    case typeParam.UNIFORMITY:
    case typeParam.CV_LENGTH:
    case typeParam.PIGMENTATION:
      return mean;
    case typeParam.AVG_WEIGHT:
      return roundWeight({ value: mean, showUnit: false, forceCast });
    case typeParam.AVG_LENGTH:
      return roundLength({ value: mean, showUnit: false, forceCast });

    default:
      return 0;
  }
}

export const getRangeDefault = (props: { parameter: string; typeMetric?: string }) => {
  const { parameter, typeMetric } = props;
  return parameter === typeParam.UNIFORMITY || isReferenceCurveMetric(typeMetric) ? 0.1 : 0.3;
};

export const sortY1 = (props: { data: ItemReferenceCurves[]; parameter: string; typeMetric?: string; }) => {
  const { data, parameter, typeMetric } = props;
  const datay1: number[] = [];

  for (let index = 0; index < data.length; index++) {
    const element: ItemReferenceCurves = data[index];
    const percent = element.range ? element.range / 100 : getRangeDefault({ parameter, typeMetric });

    const y1 = element.mean + (element.mean * percent);
    datay1.push(y1);
  }

  return datay1;
};

export const sortY2 = (props: { data: ItemReferenceCurves[]; parameter: string; typeMetric?: string; }) => {
  const { data, parameter, typeMetric } = props;
  const datay2: number[] = [];

  for (let index = 0; index < data.length; index++) {
    const element: ItemReferenceCurves = data[index];
    const percent = element.range ? element.range / 100 : getRangeDefault({ parameter, typeMetric });

    const y2 = element.mean - (element.mean * percent);
    datay2.push(y2);
  }
  return datay2;
};

const getAverageAnimals = (analysisListByStage: Point[]) => {
  const animals = analysisListByStage.map((analysis) => analysis.resultData.larvaeNumber);
  const average = mean(animals);
  return roundTwoDecimals(average);
};

const getAverageWeight = (analysisListByStage: Point[]) => {
  const weights = analysisListByStage.map((analysis) => analysis.resultData.averageWeight);
  const average = mean(weights);
  return roundTwoDecimals(average);
};

const getAverageLength = (analysisListByStage: Point[]) => {
  const lengths = analysisListByStage.map((analysis) => analysis.resultData.averageLength);
  const average = mean(lengths);
  return roundTwoDecimals(average);
};

const getAverageLarvaePerGram = (analysisListByStage: Point[]) => {
  const larvaePerGram = analysisListByStage.map((analysis) => analysis.resultData.larvaePerGram);
  const average = mean(larvaePerGram);
  return roundTwoDecimals(average);
};

const getAverageUniformity = (analysisListByStage: Point[]) => {
  const uniformity = analysisListByStage.map((analysis) => analysis.resultData.uniformity);
  const average = mean(uniformity);
  return roundTwoDecimals(average);
};

const getAverageCVLength = (analysisListByStage: Point[]) => {
  const variationCoefficientLength = analysisListByStage.map((analysis) => analysis.resultData.variationCoefficientLength);
  const average = mean(variationCoefficientLength);
  return roundTwoDecimals(average);
};

const getAnimalsAboveConditionFactor = (analysisListByStage: Point[]) => {
  const conditionFactor = analysisListByStage.map((analysis) => analysis.resultData.animalsAboveConditionFactor);
  const average = mean(conditionFactor);
  return roundTwoDecimals(average);
};

export const injectPigmentationToPoints = (props: { points: Point[]; parameter: string }) => {
  const { parameter } = props;
  let { points } = props;
  const newPoints: Point[] = [];

  if (parameter === typeParam.FACTOR_K) {
    points = points.filter((point) => point.type === analysisTypes.CONSOLIDATED_ADULT_ANALYSIS && !!point.resultData.animalsAboveConditionFactor);
  }

  for (let index = 0; index < points.length; index++) {
    const point = points[index];

    const resultData = {
      larvaeNumber: point.resultData.larvaeNumber,
      averageWeight: point.resultData.averageWeight,
      averageLength: point.resultData.averageLength,
      larvaePerGram: point.resultData.larvaePerGram,
      uniformity: point.resultData.uniformity,
      variationCoefficientLength: point.resultData.variationCoefficientLength,
      histogramPigmentation: point.resultData.histogramPigmentation,
      lunarAge: point.resultData.lunarAge,
      pigmentation: getAveragePigmentationByPoint(point.resultData.histogramPigmentation),
      animalsAboveConditionFactor: roundTwoDecimals((point.resultData.animalsAboveConditionFactor * 100) / point.resultData.larvaeNumber),
    };

    newPoints[index] = {
      ...point,
      resultData,
    };
  }

  return newPoints;
};

export const getAveragePigmentationByPoint = (points: number[]) => {
  const sumPigmentation = points.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
  const averagePigmentation = points.map((averageValue, index) => averageValue * (((index * 2) + 1) / 10));
  const sumAveragePigmentation = averagePigmentation.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
  const average = sumAveragePigmentation / sumPigmentation;
  return roundTwoDecimals(average);
};

const getAveragePigmentationByStage = (points: Point[]) => {
  const pigmentation = points.map((analysis) => analysis.resultData.pigmentation);
  const average = mean(pigmentation);
  return roundTwoDecimals(average);
};

export const groupPointsByStage = (props: { points?: Point[]; parameter: string }) => {
  const { parameter } = props;
  let { points } = props;

  const avgPoint: Point[] = [];

  if (!points) {
    return avgPoint;
  }

  if (parameter === typeParam.FACTOR_K) {
    points = points.filter((point) => point.type === analysisTypes.CONSOLIDATED_ADULT_ANALYSIS && !!point.resultData.animalsAboveConditionFactor);
  }

  const analysisGroupByStage = groupBy(points, 'inputData.stage');
  for (const key in analysisGroupByStage) {
    if (Object.prototype.hasOwnProperty.call(analysisGroupByStage, key)) {
      const analysisListByStage = analysisGroupByStage[key];

      const analysis: Point = {
        ...analysisListByStage[0],
        resultData: {
          larvaeNumber: getAverageAnimals(analysisListByStage),
          averageWeight: getAverageWeight(analysisListByStage),
          averageLength: getAverageLength(analysisListByStage),
          larvaePerGram: getAverageLarvaePerGram(analysisListByStage),
          uniformity: getAverageUniformity(analysisListByStage),
          variationCoefficientLength: getAverageCVLength(analysisListByStage),
          pigmentation: getAveragePigmentationByStage(analysisListByStage),
          lunarAge: analysisListByStage[0].resultData.lunarAge,
          histogramPigmentation: [],
          animalsAboveConditionFactor: getAnimalsAboveConditionFactor(analysisListByStage),
        },
      };
      avgPoint.push(analysis);
    }
  }

  return avgPoint;
};

export const getStartDate = (stocking: Stocking) => {
  switch (stocking.phaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return stocking.startDate;

    case stockingPhaseTypes.JUVENILE:
      return stocking.startDateJuvenile;

    case stockingPhaseTypes.ADULT:
      return stocking.startDateGrowOut;
  }
};

export const getLabelStage = (phaseType: string) => {
  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return i18next.t('stockings.growthDelta.stage');

    case stockingPhaseTypes.JUVENILE:
    case stockingPhaseTypes.ADULT:
      return i18next.t('stockings.growthDelta.day');
  }
};

export const getTitleDefault = (phaseType: string) => {
  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return i18next.t('shadedplot.icons.stage.stageDefault');

    case stockingPhaseTypes.JUVENILE:
    case stockingPhaseTypes.ADULT:
      return i18next.t('shadedplot.icons.day.stageDefault');
  }
};


export const getTitleExpand = (phaseType: string) => {
  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return i18next.t('shadedplot.icons.stage.expand');

    case stockingPhaseTypes.JUVENILE:
    case stockingPhaseTypes.ADULT:
      return i18next.t('shadedplot.icons.day.expand');
  }
};

export const getNewColor = (colors: string[]) => {
  let newColorHex = colorsPoints[colors.length];

  for (let index = 0; index < colorsPoints.length; index++) {
    const colorPoint = colorsPoints[index];

    if (!colors.includes(colorPoint)) {
      newColorHex = colorPoint;
      break;
    }
  }

  return newColorHex;
};

export const getDefaultDataByStage = (props: { x: number; y: number; analysesNumber: number; stageRange?: string; maturationIds?: string[]; maturationCode?: string }) => {
  const { x, y, analysesNumber, stageRange, maturationIds, maturationCode } = props;

  const dataByStage: Point = {
    _id: '',
    code: '',
    createdAt: '',
    inputData: {
      stage: x,
    },
    type: '',
    resultData: {
      larvaeNumber: 0,
      averageLength: 0,
      averageWeight: 0,
      larvaePerGram: 0,
      uniformity: 0,
      variationCoefficientLength: 0,
      histogramPigmentation: [],
      pigmentation: 0,
      animalsAboveConditionFactor: 0,
      lunarAge: 0,
    },
    stockingId: '',
    totalN: analysesNumber,
    stageRange,
    x: x,
    y: y,
    maturationIds,
    maturationCode,
  };
  return dataByStage;
};

export const renderTickFormat = (domainValue: d3.AxisDomain) => {
  return Number.isInteger(domainValue) && Number(domainValue) >= 0 ? domainValue.toString() : '';
};

export const renderTickFormatForMaturations = (domainValue: d3.AxisDomain, phaseType: string) => {
  if (phaseType === stockingPhaseTypes.LARVAE) {
    return renderTickFormat(domainValue);
  }

  const x = Number(domainValue);
  if (!Number.isInteger(x)) {
    return '';
  }

  if (x < 0) {
    return '';
  }

  const x2 = (x * TICKS_DAYS_RANGE) - 1;
  const x1 = (x * TICKS_DAYS_RANGE) - TICKS_DAYS_RANGE;
  return `${x1}-${x2}`;
};

export const getNumberTicks = (props: { phaseType: string; firstStage: number; lastStage: number; typeMetric?: string; dataMetric: GenericParam[] }) => {
  const { phaseType, firstStage, lastStage, typeMetric, dataMetric } = props;

  if (typeMetric === metricsStatuses.REFERENCE_CURVE) {
    if (phaseType === stockingPhaseTypes.ADULT) {
      return dataMetric.length < DEFAULT_STAGE_MAX ? dataMetric.length : 16;
    }

    return dataMetric.length;
  }

  if (phaseType === stockingPhaseTypes.ADULT) {
    return (lastStage - firstStage) + 1 < DEFAULT_STAGE_MAX ? (lastStage - firstStage) + 1 : 16;
  }

  return (lastStage - firstStage) + 1;
};

export const renderTickLeftFormat = (props: { format: d3.AxisDomain; phaseType: string; parameter: string; forceCast?: boolean; }) => {
  const { format, phaseType, parameter, forceCast } = props;
  const value = format.valueOf();

  if (phaseType === stockingPhaseTypes.LARVAE) {
    return value;
  }

  if (parameter === typeParam.AVG_WEIGHT) {
    if (phaseType === stockingPhaseTypes.JUVENILE) {
      return roundWeight({ value: parseFloat(`${value}`), showUnit: false, forceCast, decimalPlaces: 3 });
    }

    return roundWeight({ value: parseFloat(`${value}`), showUnit: false, forceCast });
  }

  if (parameter === typeParam.AVG_LENGTH) {
    return roundLength({ value: parseFloat(`${value}`), showUnit: false, forceCast });
  }

  return value;
};

interface LeftPositionProps {
  marginLeft: number;
  tooltipDialogWidth: number;
  bubbleWidth: number;
  width: number;
}

export const getStockingChartLeftPosition = (props: LeftPositionProps) => {
  const { marginLeft, tooltipDialogWidth, bubbleWidth, width } = props;

  const tooltipTotalWidth = tooltipDialogWidth + bubbleWidth;

  let value = 0;
  if (marginLeft + tooltipTotalWidth < width) {
    value = marginLeft + (tooltipDialogWidth / 2.5) + (bubbleWidth / 2);
  } else {
    value = marginLeft - (tooltipDialogWidth / 1.5) - (bubbleWidth * 2);
  }

  return `${value}px`;
};

export const getMaturationChartLeftPosition = (props: LeftPositionProps) => {
  const { marginLeft, tooltipDialogWidth, bubbleWidth, width } = props;

  const tooltipTotalWidth = tooltipDialogWidth + bubbleWidth;

  let value = 0;
  if (marginLeft + tooltipTotalWidth < width) {
    value = marginLeft + (tooltipDialogWidth / 2.5) + (bubbleWidth / 2);
  } else {
    value = marginLeft - (tooltipDialogWidth / 2) - (bubbleWidth * 3);
  }

  return `${value}px`;
};

const getExtraMarginLeft = () => {
  const location = window.location;

  if (location.pathname.includes('/reports/growth-delta')) {
    return 16;
  }

  if (location.pathname.includes('/production/insights')) {
    return 32;
  }

  return 0;
};

const getExtraMarginRight = () => {
  const location = window.location;

  if (location.pathname.includes('/reports/growth-delta')) {
    return 22;
  }

  if (location.pathname.includes('/production/insights')) {
    return 32;
  }

  return 0;
};

export const getGrowthDeltaChartLeftPosition = (props: LeftPositionProps) => {
  const { marginLeft, tooltipDialogWidth, bubbleWidth, width } = props;

  const tooltipTotalWidth = tooltipDialogWidth + bubbleWidth;

  let value = 0;
  if (marginLeft + tooltipTotalWidth < width) {
    value = marginLeft + (tooltipDialogWidth / 2) + bubbleWidth - getExtraMarginLeft();
  } else {
    value = marginLeft - (tooltipDialogWidth / 2) - bubbleWidth - getExtraMarginRight();
  }

  return `${value}px`;
};

export const getGrowthDeltaValue = (phaseType: string, value: number) => {
  if (phaseType === stockingPhaseTypes.LARVAE) {
    return roundWeight({ value, showUnit: false, forceCast: false }) as number;
  }

  return roundWeight({ value, showUnit: false, forceCast: true }) as number;
};

export const getLineData = (lineData: { [key: number]: Point[] }) => {
  const linePoints: Point[] = [];

  for (let index = 0; index < Object.values(lineData).length; index++) {
    const analysisItemsAux = Object.values(lineData)[index];
    const analysisItems = analysisItemsAux.filter(item => !item?.unusualAverageWeight);

    if (analysisItems.length === 0) {
      continue;
    }

    if (analysisItems.length === 1) {
      const items = {
        ...analysisItems[0],
        x: analysisItems[0].x,
        y: analysisItems[0].y,
        index,
      };

      linePoints.push(items);
      continue;
    }

    const items = {
      ...analysisItems[0],
      x: analysisItems[0].x,
      y: analysisItems.reduce((accumulator: number, value: Point) => accumulator + value.y, 0) / analysisItems.length,
      index,
    };
    linePoints.push(items);
  }

  return linePoints;
};
