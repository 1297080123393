import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Tank } from '../../Tanks/interfaces';
import { getUserSession } from '../../../utils/userSession';
import { Module, Unit } from '../../Sowings/Analysis/interfaces';
import { PopulationData, Stocking } from '../../Sowings/interfaces';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { CommercialSize, Packer } from '../../Company/Packers/interfaces';
import { getUnitPhaseTypeFromAnalysis } from '../../../helpers/units.helpers';
import { openSuccessNotification } from '../../../common/notification/Notification';
import { CommercialSizePriceTable } from '../../Company/Packers/TablePrices/interfaces';
import { getLastPopulation, sortAndBuildPopulationData } from '../../../helpers/population.helpers';
import { DEFAULT_STAGE_MAX, stockingPhaseTypes, stockingStatuses, transferTypes, unitStatuses } from '../../../config/commons';
import { CAMPUS_URL, COMMERCIAL_SIZE_PRICE_TABLE_URL, MODULES_URL, PACKERS_URL, STOCKINGS_URL, TANKS_URL } from '../../../config/config.api';
import { GenericParam } from '../../../common/interfaces/commons';

import { chartParameters } from './helpers';
import { DataSource, OptimalHarvestPointState, Point } from './interfaces';

const initialState: OptimalHarvestPointState = {
  dataSource: {
    allAnalysis: [],
    excludedAnalyses: [],
    predictions: [],
  },
  pocPoint: undefined,
  allPredictions: [],
  filters: {
    unitId: '',
    moduleId: '',
    tankId: '',
    chartParameter: chartParameters.POC,
    selectedStocking: {
      _id: '',
      name: '',
    } as Stocking,
    commercialSize: undefined,
    packerId: '',
    commercialSizePriceTable: undefined,
  },
  units: [],
  modules: [],
  tanks: [],
  packers: [],
  packer: undefined,
  firstStage: 1,
  lastStage: DEFAULT_STAGE_MAX,
  maxStage: 0,
  isFetchingData: false,
  isFetchingStocking: false,
  isFetchingPriceTable: false,
  isExcluding: false,
  analysesToExclude: [],
  predictionSelected: undefined,
  isUpdatingPoints: false,
  lastPopulation: { } as PopulationData,
  harvestsAndTransfers: 0,
  showUpdatePriceTableModal: false,
  isUpdatingPriceTable: false,
  packersWithCommercialSizes: [],
};

const optimalHarvestPointSlice = createSlice({
  initialState,
  name: 'optimalHarvestPoint',
  reducers: {
    setDataSource: (state: OptimalHarvestPointState, action: PayloadAction<DataSource>) => {
      state.dataSource = action.payload;
    },
    setFirstStage: (state: OptimalHarvestPointState, action: PayloadAction<number>) => {
      state.firstStage = action.payload;
    },
    setLastStage: (state: OptimalHarvestPointState, action: PayloadAction<number>) => {
      state.lastStage = action.payload;
    },
    setMaxStage: (state: OptimalHarvestPointState, action: PayloadAction<number>) => {
      state.maxStage = action.payload;
    },
    setIsFetchingData: (state: OptimalHarvestPointState, action: PayloadAction<boolean>) => {
      state.isFetchingData = action.payload;
    },
    setIsFetchingStocking: (state: OptimalHarvestPointState, action: PayloadAction<boolean>) => {
      state.isFetchingStocking = action.payload;
    },
    setIsFetchingPriceTable: (state: OptimalHarvestPointState, action: PayloadAction<boolean>) => {
      state.isFetchingPriceTable = action.payload;
    },
    resetFilters: (state: OptimalHarvestPointState) => {
      state.filters = initialState.filters;
    },
    setSelectedUnit: (state: OptimalHarvestPointState, action: PayloadAction<string>) => {
      state.filters.unitId = action.payload;
    },
    setSelectedModule: (state: OptimalHarvestPointState, action: PayloadAction<string>) => {
      state.filters.moduleId = action.payload;
    },
    setSelectedTank: (state: OptimalHarvestPointState, action: PayloadAction<string>) => {
      state.filters.tankId = action.payload;
    },
    setSelectedStocking: (state: OptimalHarvestPointState, action: PayloadAction<Stocking>) => {
      state.filters.selectedStocking = action.payload;
    },
    setPackerId: (state: OptimalHarvestPointState, action: PayloadAction<string>) => {
      state.filters.packerId = action.payload;
    },
    setCommercialSize: (state: OptimalHarvestPointState, action: PayloadAction<CommercialSize | undefined>) => {
      state.filters.commercialSize = action.payload;
    },
    setUnits: (state: OptimalHarvestPointState, action: PayloadAction<Unit[]>) => {
      state.units = action.payload;
    },
    setModules: (state: OptimalHarvestPointState, action: PayloadAction<Module[]>) => {
      state.modules = action.payload;
    },
    setTanks: (state: OptimalHarvestPointState, action: PayloadAction<Tank[]>) => {
      state.tanks = action.payload;
    },
    setPackers: (state: OptimalHarvestPointState, action: PayloadAction<Packer[]>) => {
      state.packers = action.payload;
    },
    setPacker: (state: OptimalHarvestPointState, action: PayloadAction<Packer | undefined>) => {
      state.packer = action.payload;
    },
    setCommercialSizePriceTable: (state: OptimalHarvestPointState, action: PayloadAction<CommercialSizePriceTable | undefined>) => {
      state.filters.commercialSizePriceTable = action.payload;
    },
    setIsExcluding: (state: OptimalHarvestPointState, action: PayloadAction<boolean>) => {
      state.isExcluding = action.payload;
    },
    setAnalysesToExclude: (state: OptimalHarvestPointState, action: PayloadAction<string[]>) => {
      state.analysesToExclude = action.payload;
    },
    toggleAnalysisExclusion: (state: OptimalHarvestPointState, action: PayloadAction<string>) => {
      const newAnalysisId = action.payload;
      const analysesToExclude = state.analysesToExclude.slice();
      const index = analysesToExclude.findIndex(analysisId => analysisId === newAnalysisId);
      
      if (index !== -1) { // remove new analysis id
        analysesToExclude.splice(index, 1);
        state.analysesToExclude = analysesToExclude;
        return;
      }
      // add new analysis id
      analysesToExclude.push(newAnalysisId);
      state.analysesToExclude = analysesToExclude;
    },
    setIsUpdatingPoints: (state: OptimalHarvestPointState, action: PayloadAction<boolean>) => {
      state.isUpdatingPoints = action.payload;
    },
    setPredictionSelected: (state: OptimalHarvestPointState, action: PayloadAction<Point | undefined>) => {
      state.predictionSelected = action.payload;
    },
    setLastPopulation: (state: OptimalHarvestPointState, action: PayloadAction<PopulationData | undefined>) => {
      state.lastPopulation = action.payload;
    },
    setChartParameter: (state: OptimalHarvestPointState, action: PayloadAction<string>) => {
      state.filters.chartParameter = action.payload;
    },
    setHarvestsAndTransfers: (state: OptimalHarvestPointState, action: PayloadAction<number>) => {
      state.harvestsAndTransfers = action.payload;
    },
    setAllPredictions: (state: OptimalHarvestPointState, action: PayloadAction<Point[]>) => {
      state.allPredictions = action.payload;
    },
    setPocPoint: (state: OptimalHarvestPointState, action: PayloadAction<Point | undefined>) => {
      state.pocPoint = action.payload;
    },
    setShowUpdatePriceTableModal: (state: OptimalHarvestPointState, action: PayloadAction<boolean>) => {
      state.showUpdatePriceTableModal = action.payload;
    },
    setIsUpdatingPriceTable: (state: OptimalHarvestPointState, action: PayloadAction<boolean>) => {
      state.isUpdatingPriceTable = action.payload;
    },
    setPackersWithCommercialSizes: (state: OptimalHarvestPointState, action: PayloadAction<GenericParam[]>) => {
      state.packersWithCommercialSizes = action.payload;
    },
  }
});

export const {
  setUnits, setTanks, setModules, setIsFetchingStocking, setCommercialSize, setHarvestsAndTransfers, setPocPoint, setPackers,
  setIsExcluding, setAnalysesToExclude, toggleAnalysisExclusion, setPredictionSelected, setLastPopulation, setAllPredictions,
  resetFilters, setSelectedUnit, setSelectedModule, setSelectedStocking, setSelectedTank, setChartParameter, setPackerId, setIsFetchingPriceTable,
  setDataSource, setFirstStage, setLastStage, setMaxStage, setIsFetchingData, setIsUpdatingPoints, setPacker, setCommercialSizePriceTable,
  setShowUpdatePriceTableModal, setIsUpdatingPriceTable, setPackersWithCommercialSizes,
} = optimalHarvestPointSlice.actions;

const optimalHarvestPointReducer = optimalHarvestPointSlice.reducer;
export default optimalHarvestPointReducer;

export const fetchDataSource = (stockingId: string) => async (dispatch: Function) => {
  dispatch(setIsFetchingData(true));
  try {
    const url = `${STOCKINGS_URL}/${stockingId}/optimal-harvest-point`;
    const response = await axios.get<DataSource | undefined>(url);
    const dataSource = response?.data;
    const excludedAnalysisIds = dataSource?.excludedAnalyses.map((item) => item._id);
    dispatch(setDataSource(dataSource || initialState.dataSource));
    dispatch(setAnalysesToExclude(excludedAnalysisIds || []));
  } catch (error) {
    console.log(error?.response);
    dispatch(setDataSource(initialState.dataSource));
    dispatch(setAnalysesToExclude([]));
  }
  dispatch(setIsFetchingData(false));
};

export const fetchUnits = (params: { companyId: string, unitPhaseType: string }) => async (dispatch: Function) => {
  const { companyId, unitPhaseType } = params;
  const userSession = getUserSession();

  const unitParams = {
    $limit: -1,
    companyId: companyId ? companyId : userSession.companyId,
    phaseType: unitPhaseType,
    active: true,
    status: unitStatuses.ACTIVE,
    '$sort[name]': 1,
    $select: ['name'],
  };

  try {
    const response = await axios.get(CAMPUS_URL, { params: unitParams });
    dispatch(setUnits(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchModules = (campusId: string, phaseType: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    campusId,
    active: true,
    phaseType,
    '$sort[name]': 1,
    $select: ['name'],
  };

  try {
    const response = await axios.get<Module[]>(MODULES_URL, { params });
    dispatch(setModules(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchTanks = (campusId: string, moduleId: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    active: true,
    campusId,
    moduleId,
    '$sort[name]': 1,
    $select: ['name'],
  };

  try {
    const response = await axios.get<Tank[]>(TANKS_URL, { params: params });
    dispatch(setTanks(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchActiveStocking = (props: { campusId?: string; moduleId?: string; containerId?: string; companyId?: string }) => async (dispatch: Function) => {
  const { campusId, moduleId, containerId, companyId } = props;
  const userSession = getUserSession();
  dispatch(setIsFetchingStocking(true));
  dispatch(setDataSource(initialState.dataSource));
  dispatch(setSelectedStocking(initialState.filters.selectedStocking));

  const params = {
    $limit: -1,
    active: true,
    isArchived: false,
    'status[$in]': [stockingStatuses.ACTIVE, transferTypes.PARTIAL_TRANSFER],
    campusId,
    moduleId,
    tankId: containerId,
    companyId: companyId || userSession.companyId,
    $select: ['name', 'growOutNumber', 'startDateGrowOut', 'hectares', 'phaseType', 'cubicMeters', 'transfers', 'harvests', 'populations'],
  };

  try {
    const response = await axios.get<Stocking[]>(STOCKINGS_URL, { params });
    const firstStocking = response.data[0];

    dispatch(setSelectedStocking(firstStocking));
    const populations = sortAndBuildPopulationData(firstStocking);
    const harvestsAndTransfers = populations.reduce((sum, { animalsTransferred, harvestQuantity }) => (animalsTransferred || 0) + (harvestQuantity || 0) + sum, 0);
    dispatch(setHarvestsAndTransfers(harvestsAndTransfers));
    const lastPopulation = getLastPopulation(populations);
    dispatch(setLastPopulation(lastPopulation));
  } catch (error) {
    dispatch(setSelectedStocking(initialState.filters.selectedStocking));
    dispatch(setHarvestsAndTransfers(0));
    dispatch(setLastPopulation(undefined));
    console.log(error?.response);
  }
  dispatch(setIsFetchingStocking(false));
};

export const resetChartFilters = (params: { phaseType: string; companyId: string }) => (dispatch: Function) => {
  const { phaseType, companyId } = params;

  dispatch(resetFilters());
  dispatch(setUnits([]));
  dispatch(setTanks([]));
  dispatch(setModules([]));
  dispatch(setPackers([]));
  dispatch(setPacker(undefined));
  dispatch(setIsExcluding(false));
  dispatch(setPredictionSelected(initialState.predictionSelected));
  dispatch(setDataSource(initialState.dataSource));

  if (phaseType === stockingPhaseTypes.ADULT) {
    const unitPhaseType = getUnitPhaseTypeFromAnalysis(phaseType);
    dispatch(fetchUnits({ companyId, unitPhaseType }));
    dispatch(fetchPackers({ companyId }));
    // dispatch(fetchPackersWithCommercialSizes({ companyId }));
  }
};

export const applyExcludeAnalyses = (params: { stockingId: string; analysesToExclude: string[]; analysesToInclude: string[] }) => async (dispatch: Function) => {
  const { stockingId, analysesToExclude, analysesToInclude } = params;
  const body = { analysesToExclude, analysesToInclude };
  dispatch(setIsUpdatingPoints(true));
  try {
    const url = `${STOCKINGS_URL}/${stockingId}/exclude-analyses-from-prediction`;
    await axios.patch(url, body);
    dispatch(setIsExcluding(false));
    dispatch(fetchDataSource(stockingId));
  } catch (error) {
    console.log(error?.response);
  }
  dispatch(setIsUpdatingPoints(false));
};

export const fetchPackers = (props: { companyId: string }) => async (dispatch: Function) => {
  const { companyId } = props;

  const params = {
    companyId,
    $select: ['name', 'active'],
    active: true,
    '$sort[name]': 1,
    $limit: -1,
  };

  try {
    const response = await axios.get<Packer[]>(PACKERS_URL, { params });
    dispatch(setPackers(response.data));
  } catch (e) {
    dispatch(setPackers([]));
    console.log(e?.response);
  }
};

export const fetchPacker = (props: { packerId: string }) => async (dispatch: Function) => {
  const { packerId } = props;

  const params = {
    $select: ['name', 'active', 'commercialSizes'],
  };

  const url = `${PACKERS_URL}/${packerId}`;

  try {
    const response = await axios.get<Packer>(url, { params });
    dispatch(setPacker(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchCommercialSizePriceTable = (props: { packerId: string; commercialSizeId: string; }) => async (dispatch: Function) => {
  const { packerId, commercialSizeId } = props;

  const params = {
    packerId,
    commercialSizeId,
    active: true,
    $limit: -1,
    '$sort[date]': -1,
  };

  dispatch(setIsFetchingPriceTable(true));
  try {
    const response = await axios.get<CommercialSizePriceTable[]>(COMMERCIAL_SIZE_PRICE_TABLE_URL, { params });
    const data = response?.data[0];
    dispatch(setCommercialSizePriceTable(data));
  } catch (e) {
    dispatch(setCommercialSizePriceTable(undefined));
    console.log(e?.response);
  }
  dispatch(setIsFetchingPriceTable(false));
};

export const fetchCommercialSizePriceTableById = (props: {commercialSizePriceTableId: string; }) => async (dispatch: Function) => {
  const { commercialSizePriceTableId } = props;

  const url = `${COMMERCIAL_SIZE_PRICE_TABLE_URL}/${commercialSizePriceTableId}`;

  try {
    const response = await axios.get<CommercialSizePriceTable>(url);
    dispatch(setCommercialSizePriceTable(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const updateCommercialSizePriceTable = (props: { commercialSizePriceTableId: string; body: CommercialSizePriceTable; onSuccess: () => void; }) => async (dispatch: Function) => {
  const { commercialSizePriceTableId, body, onSuccess } = props;
  
  dispatch(setIsUpdatingPriceTable(true));
  const url = `${COMMERCIAL_SIZE_PRICE_TABLE_URL}/${commercialSizePriceTableId}`;

  try {
    await axios.patch(url, body);
    dispatch(fetchCommercialSizePriceTableById({ commercialSizePriceTableId }));
    onSuccess();
    openSuccessNotification(i18next.t('priceTable.updated'));
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsUpdatingPriceTable(false));
};

export const fetchPackersWithCommercialSizes = (params: { companyId: string }) => async (dispatch: Function) => {
  const { companyId } = params;
  const url = `${PACKERS_URL}/packers-with-commercial-sizes/${companyId}`;
  try {
    const response = await axios.get(url);
    dispatch(setPackersWithCommercialSizes(response.data));
  } catch (e) {
    dispatch(setPackersWithCommercialSizes([]));
    console.log(e?.response);
  }
};
