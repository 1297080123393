import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CommercialSize, Packer } from '../interfaces';
import { axiosClient as axios } from '../../../../utils/axios_instance';
import { showErrorCommercialSize } from '../../../../helpers/commercial-size.helpers';
import { GROW_OUT_SIZES_SETTINGS_URL, PACKERS_URL } from '../../../../config/config.api';

import { CommercialSizeState, DefaultCommercialSize } from './interfaces';

const initialState: CommercialSizeState = {
  isLoading: false,
  showCreateModal: false,
  isUpdating: false,
  showUpdateModal: false,
  packer: {
    name: '',
    companyId: '',
    commercialSizes: [],
  },
  defaultCommercialSizes: {
    whole: { labels: [], ranges: [], numericLabels: [] },
    tail: { labels: [], ranges: [], numericLabels: [] },
  },
};

export const commercialSizeSlice = createSlice({
  name: 'commercialSizes',
  initialState,
  reducers: {
    setPacker: (state: CommercialSizeState, action: PayloadAction<Packer>) => {
      state.packer = action.payload;
    },
    setIsLoading: (state: CommercialSizeState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShowCreateModal: (state: CommercialSizeState, action: PayloadAction<boolean>) => {
      state.showCreateModal = action.payload;
    },
    setIsUpdating: (state: CommercialSizeState, action: PayloadAction<boolean>) => {
      state.isUpdating = action.payload;
    },
    setShowUpdateModal: (state: CommercialSizeState, action: PayloadAction<boolean>) => {
      state.showUpdateModal = action.payload;
    },
    setCommercialSize: (state: CommercialSizeState, action: PayloadAction<CommercialSize>) => {
      state.commercialSize = action.payload;
    },
    setDefaultCommercialSizes: (state: CommercialSizeState, action: PayloadAction<DefaultCommercialSize>) => {
      state.defaultCommercialSizes = action.payload;
    },
  },
});

export const {
  setPacker,
  setIsLoading,
  setShowCreateModal,
  setIsUpdating,
  setShowUpdateModal,
  setCommercialSize,
  setDefaultCommercialSizes,
} = commercialSizeSlice.actions;

export const fetchPacker = (props: {packerId: string}) => async (dispatch: Function) => {
  const { packerId } = props;

  const params = {
    $select: ['name', 'active', 'commercialSizes'],
  };

  const url = `${PACKERS_URL}/${packerId}`;
  dispatch(setIsLoading(true));

  try {
    const response = await axios.get<Packer>(url, { params });
    dispatch(setPacker(response.data));
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsLoading(false));
};

export const updateCommercialSize = (props: { packerId: string; commercialSizes: CommercialSize[]; onSuccess: () => void; commercialSizeId?: string }) => async (dispatch: Function) => {
  const { packerId, commercialSizes, onSuccess, commercialSizeId } = props;
  
  dispatch(setIsUpdating(true));
  const url = `${PACKERS_URL}/${packerId}`;

  const body = {
    commercialSizes,
    commercialSizeId,
  };

  try {
    await axios.patch(url, body);
    dispatch(fetchPacker({ packerId }));
    onSuccess();
  } catch (e) {
    const error = e.response?.data?.data?.error;
    showErrorCommercialSize({ error });
  }

  dispatch(setIsUpdating(false));
};

export const fetchDefaultCommercialSizes = () => async (dispatch: Function) => {
  try {
    const response = await axios.get<DefaultCommercialSize>(GROW_OUT_SIZES_SETTINGS_URL);
    dispatch(setDefaultCommercialSizes(response.data));
  } catch (error) {
    console.log(error);
    dispatch(setDefaultCommercialSizes(initialState.defaultCommercialSizes));
  }
};

export default commercialSizeSlice.reducer;
