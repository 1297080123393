import cx from 'classnames';
import 'leaflet/dist/leaflet.css';
import { Layout, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import './assets/fonts/LexendDeca-Light.ttf';
import SignIn from './pages/auth';
import Register from './pages/Register';
import styles from './App.module.scss';
import Users from './pages/Users/Users';
import { history } from './helpers/history';
import Stockings from './pages/Sowings/Sowings';
import Settings from './pages/Settings/Settings';
import Sidebar from './pages/Sidebar/Sidebar';
import Clients from './pages/Clients/Clients';
import Icon from './common/components/Icon/Icon';
import Stocking from './pages/Analysis/Sowing';
import AppHeader from './pages/AppHeader/AppHeader';
import PasswordReset from './pages/Password/PasswordReset';
import PasswordForgot from './pages/Password/PasswordForgot';
import { PublicRoute } from './common/components/publicRoute';
import { PrivateRoute } from './common/components/privateRoute';
import AdminDashboard from './pages/AdminDashboard/AdminDashboard';
import { DetailAnalysis } from './pages/Analysis/Detail/DetailAnalysis';
import ActivityDashboard from './pages/ActivityDashboard/ActivityDashboard';
import { StockingAnalysis } from './pages/Sowings/Analysis/StockingAnalysis';
import NotFoundPage from './common/components/NotFoundPage/NotFoundPage';
import UnauthorizedPage from './common/components/UnauthorizedPage/UnauthorizedPage';
import PrivacyPolicyPage from './common/components/PrivacyPolicyPage/PrivacyPolicyPage';
import './state/middleware/axios.interceptor';
import Payments from './pages/Payments/Payments';
import { ENVIRONMENT } from './config/config.api';
import { pathsPerms } from './config/config.perms';
import TanksPdf from './pages/Units/Pdf/TanksPdf';
import StockingCharts from './pages/Pdfs/StockingCharts/ChartsPdf';
import DetailPayment from './pages/Payments/Detail/DetailPayment';
import ActiveTanks from './pages/Reports/ActiveTanksDashboard/ActiveTanks';
import Onboarding from './common/components/Onboarding/Onboarding';
import ActivateAccount from './pages/ActivateAccount/ActivateAccount';
import { MultiphasePdf } from './pages/Pdfs/Multiphase/MultiphasePdf';
import BalanceConsumption from './pages/BalanceConsumption/BalanceConsumption';
import { environments, ribbonEnvironment, getMainRole, roles, THEME } from './config/commons';
import { StockingComparisonPdf } from './pages/Pdfs/StockingComparison/SowingComparisionPdf';
import { StockingParametersPdf } from './pages/Pdfs/StockingParameter/StockingParametersPdf';
import AnalysisWithoutStocking from './pages/AnalysisWithoutStocking/AnalysisWithoutStocking';
import GrowthDelta from './pages/Reports/GrowthDelta/GrowthDelta';
import SurvivalRate from './pages/Reports/SurvivalRate/SurvivalRate';
import GeneticsSetup from './pages/Genetics/Setup/GeneticsSetup';
import Tanks from './pages/Tanks/Tanks';
import Archive from './pages/Archive/Archive';
import Modules from './pages/Modules/Modules';
import { Store } from './state/store.interfaces';
import { getCurrentTheme } from './helpers/theme';
import { Harvests } from './pages/Harvests/Harvests';
import { UnitScreen } from './pages/Units/UnitScreen';
import PrevStats from './pages/Reports/PrevStats/PrevStats';
import { HarvestsPdf } from './pages/Pdfs/Harvests/HarvestsPdf';
import LaboratoryPdf from './pages/Pdfs/Laboratory/LaboratoryPdf';
import CompanySettings from './pages/Company/Settings/Settings';
import { Transports } from './pages/Company/Transports/Transports';
import GeneticsGlobal from './pages/Genetics/Global/GeneticsGlobal';
import GrowthDeltaPdf from './pages/Pdfs/GrowthDelta/GrowthDeltaPdf';
import ReferenceCurves from './pages/ReferenceCurves/ReferenceCurves';
import { AnalysisCard } from './pages/Company/AnalysisCard/AnalysisCard';
import GeneticsAnalysis from './pages/Genetics/Analysis/GeneticsAnalysis';
import AnalysisDetailPdf from './pages/Pdfs/AnalysisDetail/AnalysisDetailPdf';
import SuccessQuadrant from './pages/Reports/SuccessQuadrant/SuccessQuadrant';
import { ParameterState } from './pages/Reports/ParameterState/ParameterState';
import { TransferGraphPdf } from './pages/Pdfs/TransferGraph/TransferGraphPdf';
import { LaboratoryChart } from './pages/Reports/LaboratoryChart/LaboratoryChart';
import { ParameterStatePdf } from './pages/Pdfs/ParameterState/ParameterStatePdf';
import StockingPopulations from './pages/Sowings/Populations/StockingPopulations';
import { StockingParameters } from './pages/Sowings/Parameters/StockingParameters';
import GeneticsAnalysisPdf from './pages/Pdfs/GeneticsAnalysis/GeneticsAnalysisPdf';
import { SuccessQuadrantPdf } from './pages/Pdfs/SuccessQuadrant/SuccessQuadrantPdf';
import { ImmediateAttentionTopBar } from './pages/AppHeader/ImmediateAttentionTopBar';
import GeneticsGlobalDetail from './pages/Genetics/Global/Detail/GeneticsGlobalDetail';
import { HistoryManagement } from './pages/Company/HistoryManagement/HistoryManagement';
import { AnalysisObservation } from './pages/Company/AnalysisObservation/AnalysisObservation';
import { LrvDefaultPhaseModal } from './common/components/LrvDefaultPhaseModal/LrvDefaultPhaseModal';
import FunctionalitiesListener from './common/components/FunctionalitiesModal/FunctionalitiesListener';
import { StockingParameters as CompanyStockingParameters } from './pages/Company/StockingParameters/StockingParameters';
// import { Packers } from './pages/Company/Packers/Packers';
// import { CommercialSizes } from './pages/Company/Packers/CommercialSizes/CommercialSizes';
// import { TablePrices } from './pages/Company/Packers/TablePrices/TablePrices';
// import OptimalHarvestPoint from './pages/Reports/OptimalHarvestPoint/OptimalHarvestPoint';

const { Content } = Layout;

Modal.defaultProps = {
  closeIcon: <Icon name='close' />
};

function App () {
  const higherRole = getMainRole();
  const defaultPath = higherRole === roles.FINANCE ? '/balance/manage' : '/production/stockings';

  const { company } = useSelector((state: Store) => state.header);
  const profile = useSelector((state: Store) => state.profile);

  const theme = profile?.theme || getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  function renderCornerEnvironmentRibbon () {
    if (ENVIRONMENT === environments.STG) {
      return <div className={styles.cornerRibbon}>
        <span>{ribbonEnvironment[ENVIRONMENT]}</span>
      </div>;
    }
  }

  document.body.classList.add(isLightTheme ? styles.bodyLight : styles.bodyDark);
  document.body.classList.remove(isLightTheme ? styles.bodyDark : styles.bodyLight);

  return (
    <Router history={history}>
      <FunctionalitiesListener />
      <Switch>
        <Route path='/login' component={SignIn} />
        <Route path='/account/active/:token' component={ActivateAccount} />
        <Route path='/register' component={Register} />
        <Route path='/password/forgot' component={PasswordForgot} />
        <Route path='/password/reset' component={PasswordReset} />
        <Route path='/notFound' component={NotFoundPage} />
        <Route path='/privacy' component={PrivacyPolicyPage} />
        <Route path='/tanks/pdf/:campusId/:accessToken' component={TanksPdf} />
        <Route path='/analysis/:id/pdf/:bins/:accessToken/:language/:hours' component={AnalysisDetailPdf} />
        <Route path='/stocking/:stockingId/pdf/:dataSource/:parameter/:scale/:language/:firstStage/:lastStage/:accessToken/:movingAverage/:estimator/:stockingsId?/:stockingsName?/' component={StockingComparisonPdf} />
        <Route path='/stocking/multiphase/:stockingId/pdf/:companyId/:parameter/:scale/:stockingPhaseType/:language/:phaseTypeSelected/:hours/:accessToken/:juvenileStockingId?/:larvaeStockingId?/' component={MultiphasePdf} />
        <Route path='/stocking/multiphase/:stockingId/pdf/:companyId/:parameter/:scale/:stockingPhaseType/:language/:phaseTypeSelected/:accessToken/:juvenileStockingId?/:larvaeStockingId?/' component={MultiphasePdf} />
        <Route path='/stocking/:stockingId/pdf/:language/:accessToken/' component={StockingCharts} />
        <Route path='/growth-delta/pdf/:companyId/:campusId/:moduleId/:language/:firstStage/:lastStage/:accessToken/:fromDate/:toDate/:movingAverage/:tankId?/:stockingId?/' component={GrowthDeltaPdf} />
        <Route path='/maturation/pdf/:companyId/:maturationsName/:language/:parameter/:scale/:phaseType/:minimumDate/:maximumDate/:firstStage/:lastStage/:referenceId/:accessToken/:unitId?/:unitName?' component={GeneticsAnalysisPdf} />
        <Route path='/laboratory/pdf/:companyId/:laboratories/:language/:parameter/:scale/:phaseType/:minimumDate/:maximumDate/:firstStage/:lastStage/:accessToken/:unitId?/:unitName?' component={LaboratoryPdf} />
        <Route path='/stocking-parameters/:stockingId/pdf/:parameter/:dateOption/:language/:accessToken/' component={StockingParametersPdf} />
        <Route path='/parameter-state/pdf/:companyId/:unitId/:moduleId/:phaseType/:parameter/:dateOption/:language/:accessToken/:referenceId?' component={ParameterStatePdf} />
        <Route path='/harvest/:harvestId/pdf/:companyId/:harvestType/:histogram/:language/:accessToken/' component={HarvestsPdf} />
        <Route path='/success-quadrant/pdf/:companyId/:campusId/:moduleId/:phaseType/:fromDate/:toDate/:hours/:xAxis/:yAxis/:zAxis/:circleSizeParameter/:filterOutliers/:groupData/:language/:accessToken/' component={SuccessQuadrantPdf} />
        <Route path='/transfer/pdf/:companyId/:transferId/:language/:accessToken/' component={TransferGraphPdf} />
        <Route exact path='/password/renew/:accessToken' component={PasswordReset}></Route>
        <Route>
          <Layout>
            <ImmediateAttentionTopBar />
            <Layout className=''>
              {renderCornerEnvironmentRibbon()}
              <Sidebar />
              <Layout>
                <AppHeader />
                <Layout>
                  <Content
                    className={cx(styles.mainContent, isLightTheme ? styles.mainContentLight : styles.mainContentDark)}
                  >
                    <Switch>
                      <Route exact path='/'>
                        <Redirect to={defaultPath} />
                      </Route>
                      <PrivateRoute
                        exact
                        path='/reports/current-state'
                        component={ActiveTanks}
                        requiredRoles={pathsPerms['/reports/current-state']}
                      />
                      <PrivateRoute
                        exact
                        path='/reports/performance'
                        component={PrevStats}
                        requiredRoles={pathsPerms['/reports/performance']}
                      />
                      <PrivateRoute
                        exact
                        path='/reports/quadrant'
                        component={SuccessQuadrant}
                        requiredRoles={pathsPerms['/reports/quadrant']}
                      />
                      {/* <PrivateRoute
                        exact
                        path='/reports/optimal-harvest-point'
                        component={OptimalHarvestPoint}
                        requiredRoles={pathsPerms['/reports/optimal-harvest-point']}
                      /> */}
                      <PrivateRoute
                        exact
                        path='/reports/growth-delta'
                        component={GrowthDelta}
                        requiredRoles={pathsPerms['/reports/growth-delta']}
                      />
                      <PrivateRoute
                        exact
                        path='/reports/survival-rate'
                        component={SurvivalRate}
                        requiredRoles={pathsPerms['/reports/survival-rate']}
                      />
                      <PrivateRoute
                        exact
                        path='/reports/laboratory'
                        component={LaboratoryChart}
                        requiredRoles={pathsPerms['/reports/laboratory']}
                      />
                      <PrivateRoute
                        exact
                        path='/reports/parameter-state'
                        component={ParameterState}
                        requiredRoles={pathsPerms['/reports/parameter-state']}
                      />
                      <PrivateRoute
                        exact
                        path='/balance-consumption'
                        component={BalanceConsumption}
                        requiredRoles={pathsPerms['/balance-consumption']}
                      />
                      <PrivateRoute
                        exact
                        path='/clients'
                        component={Clients}
                        requiredRoles={pathsPerms['/clients']}
                      />
                      <PrivateRoute
                        exact
                        path='/users'
                        component={Users}
                        requiredRoles={pathsPerms['/users']}
                      />
                      <PrivateRoute
                        exact
                        path='/units'
                        component={UnitScreen}
                        requiredRoles={pathsPerms['/units']}
                      />
                      <PrivateRoute
                        exact
                        path='/units/:unitId'
                        component={Modules}
                        requiredRoles={pathsPerms['/units/:unitId']}
                      />
                      <PrivateRoute
                        exact
                        path='/units/:unitId/modules/:moduleId'
                        component={Tanks}
                        requiredRoles={pathsPerms['/units/:unitId/modules/:moduleId']}
                      />
                      <PrivateRoute
                        exact
                        path='/production/insights'
                        component={StockingAnalysis}
                        requiredRoles={pathsPerms['/production/insights']}
                      />
                      <PrivateRoute
                        exact
                        path='/production/stockings'
                        component={Stockings}
                        requiredRoles={pathsPerms['/production/stockings']}
                      />
                      {
                        company.showStockingParameterSection &&
                        <PrivateRoute
                          exact
                          path='/production/parameters'
                          component={StockingParameters}
                          requiredRoles={pathsPerms['/production/parameters']}
                        />
                      }
                      <PrivateRoute
                        exact
                        path='/production/harvests'
                        component={Harvests}
                        requiredRoles={pathsPerms['/production/harvests']}
                      />

                      <PrivateRoute
                        exact
                        path='/analysis-without-stockings'
                        component={AnalysisWithoutStocking}
                        requiredRoles={pathsPerms['/analysis-without-stockings']}
                      />
                      <PrivateRoute
                        exact
                        path='/archive'
                        component={Archive}
                        requiredRoles={pathsPerms['/archive']}
                      />
                      <PrivateRoute
                        exact
                        path='/archive/analysis/:id'
                        component={DetailAnalysis}
                        requiredRoles={pathsPerms['/production/analysis/:id']}
                      />
                      <PrivateRoute
                        exact
                        path='/archive/stockings/:id'
                        component={Stocking}
                        requiredRoles={pathsPerms['/production/stockings/:id']}
                      />
                      <PrivateRoute
                        exact
                        path='/production/stockings/:id'
                        component={Stocking}
                        requiredRoles={pathsPerms['/production/stockings/:id']}
                      />
                      <PrivateRoute
                        exact
                        path='/production/stockings/:id/population'
                        component={StockingPopulations}
                        requiredRoles={pathsPerms['/production/stockings/:id']}
                      />
                      <PrivateRoute
                        exact
                        path='/stocking/:id'
                        requiredRoles={pathsPerms['/production/stockings/:id']}
                        render={({ match }) => <Redirect to={`/production/stockings/${match.params?.id}`} />}
                      />
                      <PublicRoute
                        exact
                        path='/production/analysis/:id'
                        component={DetailAnalysis}
                      />
                      <PrivateRoute
                        exact
                        path='/reference-curves'
                        component={ReferenceCurves}
                        requiredRoles={pathsPerms['/reference-curves']}
                      />
                      <PublicRoute
                        exact
                        path='/analysis/:id'
                        render={({ match }) => <Redirect to={`/production/analysis/${match.params?.id}`} />}
                      />
                      <PrivateRoute
                        exact
                        path='/settings'
                        component={Settings}
                        requiredRoles={pathsPerms['/settings']}
                      />
                      <PrivateRoute
                        exact
                        path='/unauthorized'
                        component={UnauthorizedPage}
                        requiredRoles={pathsPerms['/unauthorized']}
                      />
                      <PrivateRoute
                        exact
                        path='/dashboard'
                        component={ActivityDashboard}
                        requiredRoles={pathsPerms['/dashboard']}
                      />
                      <PrivateRoute
                        exact
                        path='/genetics/global'
                        component={GeneticsGlobal}
                        requiredRoles={pathsPerms['/genetics/global']}
                      />
                      <PrivateRoute
                        exact
                        path='/genetics/global/:id'
                        component={GeneticsGlobalDetail}
                        requiredRoles={pathsPerms['/genetics/global/:id']}
                      />
                      <PrivateRoute
                        exact
                        path='/genetics/setup'
                        component={GeneticsSetup}
                        requiredRoles={pathsPerms['/genetics/setup']}
                      />
                      <PrivateRoute
                        exact
                        path='/genetics/insights'
                        component={GeneticsAnalysis}
                        requiredRoles={pathsPerms['/genetics/insights']}
                      />

                      <PrivateRoute
                        exact
                        path='/company/administration-history'
                        component={HistoryManagement}
                        requiredRoles={pathsPerms['/company/administration-history']}
                      />
                      <PrivateRoute
                        exact
                        path='/company/settings'
                        component={CompanySettings}
                        requiredRoles={pathsPerms['/company/settings']}
                      />
                      <PrivateRoute
                        exact
                        path='/company/analysis-card'
                        component={AnalysisCard}
                        requiredRoles={pathsPerms['/company/analysis-card']}
                      />
                      {
                        company.showStockingParameterSection &&
                        <PrivateRoute
                          exact
                          path='/company/stocking-parameters'
                          component={CompanyStockingParameters}
                          requiredRoles={pathsPerms['/company/stocking-parameters']}
                        />
                      }
                      <PrivateRoute
                        exact
                        path='/company/transports'
                        component={Transports}
                        requiredRoles={pathsPerms['/company/transports']}
                      />
                      <PrivateRoute
                        exact
                        path='/company/observations'
                        component={AnalysisObservation}
                        requiredRoles={pathsPerms['/company/observations']}
                      />
                      {/* <PrivateRoute
                        exact
                        path='/company/packers'
                        component={Packers}
                        requiredRoles={pathsPerms['/company/packers']}
                      /> */}
                      {/* <PrivateRoute
                        exact
                        path='/company/packers/:packerId'
                        component={CommercialSizes}
                        requiredRoles={pathsPerms['/company/packers/:packerId']}
                      /> */}
                      {/* <PrivateRoute
                        exact
                        path='/company/packers/:packerId/commercial-size/:commercialSizeId'
                        component={TablePrices}
                        requiredRoles={pathsPerms['/company/packers/:packerId/commercial-size/:commercialSizeId']}
                      /> */}

                      <PrivateRoute
                        exact
                        path='/payments'
                        component={Payments}
                        requiredRoles={pathsPerms['/payments']}
                      />
                      <PrivateRoute
                        exact
                        path='/payment/manage/:id'
                        component={DetailPayment}
                        requiredRoles={pathsPerms['/payment/manage/:id']}
                      />
                      <PrivateRoute
                        exact
                        path='/admin'
                        component={AdminDashboard}
                        requiredRoles={pathsPerms['/admin']}
                      />
                      <Redirect from='/stocking' to='/production/stockings' />
                      <Redirect from='*' to='/' />
                    </Switch >
                  </Content >
                </Layout >
              </Layout >
              <Onboarding />
              <LrvDefaultPhaseModal />
            </Layout >
          </Layout >
        </Route >
      </Switch >
    </Router >
  );
}

export default App;
