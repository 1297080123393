import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Company } from '../../AppHeader/interfaces';
import { setCompany } from '../../AppHeader/headerSlice';
import { COMPANIES_URL } from '../../../config/config.api';
import { getUserSession } from '../../../utils/userSession';
import { UserSession } from '../../../common/interfaces/auth';
import { DEFAULT_CURRENCY_CODE } from '../../../config/commons';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { openSuccessNotification } from '../../../common/notification/Notification';

import { CompanySettingsState } from './interfaces';

const initialState: CompanySettingsState = {
  company: {
    weightUnit: '',
    currencyCode: '',
  },
};

export const weigthUnitSlice = createSlice({
  name: 'companySettings',
  initialState,
  reducers: {
    setWeightUnit: (state: CompanySettingsState, action: PayloadAction<string>) => {
      state.company.weightUnit = action.payload;
    },
    setCurrencyCode: (state: CompanySettingsState, action: PayloadAction<string>) => {
      state.company.currencyCode = action.payload;
    },
  },
});

export const {
  setWeightUnit, setCurrencyCode,
} = weigthUnitSlice.actions;

export const fetchCompanySetting = () => async (dispatch: Function) => {
  const userSession: UserSession = getUserSession();
  const url = `${COMPANIES_URL}/${userSession.companyId}`;
  const params = {
    $select: ['weightUnit', 'currencyCode'],
  };

  try {
    const response = await axios.get<Company>(url, { params });
    const { weightUnit } = response.data;
    const currencyCode = response.data?.currencyCode || DEFAULT_CURRENCY_CODE;
    dispatch(setWeightUnit(weightUnit));
    dispatch(setCurrencyCode(currencyCode));
  } catch (e) {
    console.log(e?.response);
  }
};

export const updateCompanySetting = (props: { weightUnit: string }) => async (dispatch: Function) => {
  const { weightUnit } = props;

  const userSession: UserSession = getUserSession();
  const url = `${COMPANIES_URL}/${userSession.companyId}`;
  const body = { weightUnit };

  try {
    const response = await axios.patch<Company>(url, body);
    const { weightUnit } = response.data;
    dispatch(setCompany(response.data));
    dispatch(setWeightUnit(weightUnit));
    openSuccessNotification(i18next.t('weightUnit.success'));
  } catch (e) {
    console.log(e?.response);
  }
};

export const updateCurrencyCode = (props: { currencyCode: string }) => async (dispatch: Function) => {
  const { currencyCode } = props;

  const userSession: UserSession = getUserSession();
  const url = `${COMPANIES_URL}/${userSession.companyId}`;
  const body = { currencyCode };

  const response = await axios.patch<Company>(url, body);
  const newCurrencyCode = response.data?.currencyCode || DEFAULT_CURRENCY_CODE;
  dispatch(setCurrencyCode(newCurrencyCode));
  localStorage.setItem('currencyCode', newCurrencyCode);

  openSuccessNotification(i18next.t('weightUnit.success'));
};

export default weigthUnitSlice.reducer;
