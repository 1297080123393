import { Form, Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import { LrvForm } from '../../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvInput } from '../../../common/components/LrvInput/LrvInput';
import { createDataApiKey, getDataApiKey, updateDataApiKey } from '../settingsSlice';

import styles from './TabApiService.module.scss';

interface Props {
  theme?: 'dark' | 'light';
  description: string;
}

export const TabApiService = (props: Props) => {
  const { theme, description } = props;

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [formApiToken] = Form.useForm();

  const [apiKey, setApiKey] = useState('');
  const [copy, setCopy] = useState(t('share.copy'));
  const [showTooltip, setShowTooltip] = useState(false);

  const { dataApiKey } = useSelector((state: Store) => state.profile);

  useEffect(() => {
    if (dataApiKey?._id) {
      return;
    }

    dispatch(getDataApiKey());
  }, [dispatch, dataApiKey._id]);

  useEffect(() => {
    setApiKey(dataApiKey.apiKey);

    formApiToken.setFieldsValue({
      apiKey: dataApiKey.apiKey
    });
  }, [formApiToken, dataApiKey]);

  return (
    <LrvForm
      id='formApiToken'
      className={styles.container}
      form={formApiToken}
      name='formApiToken'
      layout='vertical'
      theme={theme}
      onFinish={() => {
        if (!apiKey) {
          dispatch(createDataApiKey());
          return;
        }

        dispatch(updateDataApiKey());
      }}
    >
      <Form.Item>
        <LrvText theme={theme} text={description} />
      </Form.Item>

      <div className={styles.line} />
      <Form.Item
        name='apiKey'
        label={t('apiService.apiKey')}
        required
      >
        <Tooltip
          open={showTooltip}
          placement='top'
          title={copy}
        >
          <LrvInput
            className={styles.apiToken}
            theme={theme}
            onMouseEnter={() => {
              setShowTooltip(true);
            }}
            onMouseLeave={() => {
              setCopy(t('share.copy'));
              setShowTooltip(false);
            }}
            onClick={() => {
              setCopy(t('share.copied'));
              navigator.clipboard.writeText(apiKey);
            }}
            value={apiKey}
            readOnly
          />
        </Tooltip>
      </Form.Item>

      <Form.Item>
        <div className={styles.alignButtonRight} >
          <Button id='submit_api_token_button' type='primary' htmlType='submit'>
            {t('apiService.generate')}
          </Button>
        </div>
      </Form.Item>
    </LrvForm>
  );
};
