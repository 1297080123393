export interface CompanySettingsState {
  company: Company;
}

export interface Company {
  currencyCode: string;
  weightUnit: string;
}

export type CompanySettingsTab = 'WEIGHT_UNITS' | 'CURRENCY';

export const companySettingsTabs: Record<CompanySettingsTab, CompanySettingsTab> = {
  WEIGHT_UNITS: 'WEIGHT_UNITS',
  CURRENCY: 'CURRENCY',
};
