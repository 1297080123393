import i18next from 'i18next';
import { groupBy } from 'lodash';
import seedrandom from 'seedrandom';

import { Analysis } from '../../Analysis/interfaces';
import { convertKilogramsToPounds } from '../../../helpers/stocking.helpers';
import { CommercialSize, CommercialSizeRange } from '../../Company/Packers/interfaces';
import { CommercialSizePriceTable } from '../../Company/Packers/TablePrices/interfaces';
import { getCurrentElementHeight, getCurrentElementWidth } from '../../../utils/dimensions';
import { commercialSizeTypes, DEFAULT_STAGE_MAX, roundFourDecimals, weightUnits, weightUnitsByCompany } from '../../../config/commons';
import { EXTRA_VALUE_AVERAGE_WEGIHT, generateCommercialSizeRanges, getClickableMinValue, getMaxValueDisplay, MIN_VALUE_DISPLAY_GROW_OUT, processCommercialSizeRanges } from '../../../helpers/commercial-size.helpers';

import { BuildAndGetFirstPredictionParams, CalcDataSourceParams, CheckValidInputsProps, Dataset, DataSourceByStage, GrowOutSize, Point } from './interfaces';

const POWER_BASE = 5.83;
const POWER_EXPONENT = -0.407;
const DAYS_OF_THE_WEEK = 7;

export const chartParameters = {
  POC: 'POC',
  WEIGHT: 'WEIGHT',
  BIOMASS: 'BIOMASS',
  BIOMASS_KG: 'BIOMASS_KG',
  BIOMASS_LB: 'BIOMASS_LB',
  CORRECTED_FOOD: 'CORRECTED_FOOD',
  POTENTIAL_GAIN: 'POTENTIAL_GAIN',
  POTENTIAL_INCOME: 'POTENTIAL_INCOME',
  TOTAL_ACCUMULATED_COST: 'TOTAL_ACCUMULATED_COST',
};

export const FEEDING_STRATEGY = {
  HIGH: 1.05,
  NORMAL: 1,
  LOW: 0.95,
};

interface HeightProps {
  filters: React.RefObject<HTMLDivElement>;
}

interface WidthProps {
  inputsContainer?: React.RefObject<HTMLDivElement>;
}

export const getHeightOfTheOtherElements = (props: HeightProps) => {
  const { filters } = props;

  const headerHeight = 64;
  const extraHeight = 136;

  const value = getCurrentElementHeight(filters) + headerHeight + extraHeight;
  return value;
};

export const getWidthOfTheOtherElements = (props: WidthProps) => {
  const { inputsContainer } = props;
  const elementWidth = inputsContainer ? getCurrentElementWidth(inputsContainer) : 0;

  const extraWidth = 90;
  let sidebarWidth = 0;

  if (window.innerWidth > 950) {
    sidebarWidth = 80;
  }

  if (window.innerWidth > 1420) {
    sidebarWidth = 240;
  }

  return sidebarWidth + extraWidth + elementWidth;
};

export const getNumberTicks = (props: { firstStage: number; lastStage: number; predictions: Analysis[]; isExcluding: boolean; }) => {
  const { firstStage, lastStage, predictions, isExcluding } = props;
  const SEVEN_DAYS = 7;
  const daysAfterLastStage = predictions.length * SEVEN_DAYS;

  if (!isExcluding) {
    const stagesDiff = (lastStage - firstStage) / 2;
    return stagesDiff < DEFAULT_STAGE_MAX ? stagesDiff : 16;
  }

  const stagesDiff = (lastStage - firstStage) - daysAfterLastStage;
  return stagesDiff < DEFAULT_STAGE_MAX ? stagesDiff : 16;
};

function randomNormal (rng: seedrandom.PRNG, weightPredicted: number, sigma: number) {
  const u1 = rng();
  const u2 = rng();
  const z0 = Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2.0 * Math.PI * u2); // Transformed from Box-Muller
  return (z0 * sigma) + weightPredicted;
}

export const getRandomWeights = (params: { uniformity: number; averageWeight: number; size?: number }) => {
  const { size = 150, uniformity, averageWeight } = params;
  const rng = seedrandom('1');
  
  const cv = 100 - uniformity;
  const sigma = (cv / 100) * averageWeight;

  return Array.from({ length: size }, () => randomNormal(rng, averageWeight, sigma));
};

/* eslint-disable max-depth*/
export const classifyGrowOutSizes = (ranges: CommercialSizeRange[], individualWeights: number[]) => {
  const constantToConvert = 1000;
  const length = ranges.length + 1;
  const values: number[] = Array(length).fill(0);

  for (const weight of individualWeights) {
    const convertedWeight = weight / constantToConvert;
    let rangeIndex = -1;

    for (const item of ranges) {
      const { averageMinWeight, averageMaxWeight } = item;
      rangeIndex++;

      if (rangeIndex === 0 && convertedWeight >= averageMinWeight) {
        values[rangeIndex]++;
        break;
      }

      if (rangeIndex === ranges.length - 1 && convertedWeight < (averageMaxWeight + EXTRA_VALUE_AVERAGE_WEGIHT)) {
        values[rangeIndex]++;
        break;
      }

      if (convertedWeight >= averageMinWeight && convertedWeight < (averageMaxWeight + EXTRA_VALUE_AVERAGE_WEGIHT)) {
        values[rangeIndex]++;
        break;
      }
    }
  }

  return values;
};

const getDataSourceByStagePoint = (params: { analysesByStage: Analysis[]; }) => {
  let { analysesByStage } = params;
  const stage = analysesByStage[0].inputData.stage;
  const isPrediction = !analysesByStage[0]?._id;

  if (analysesByStage.length > 1) {
    analysesByStage = analysesByStage.sort((a, b) => b.resultData.averageWeight - a.resultData.averageWeight);
  }

  const points: Point[] = [];
  let totalWeight = 0;
  let N = 0;

  for (const analysis of analysesByStage) {
    const { _id, code, createdAt, inputData, resultData, excludedFromPrediction } = analysis;
    const { uniformity, averageWeight } = resultData;
    const y = roundFourDecimals(averageWeight / 1000);
    const point: Point = { _id, code, createdAt, x: inputData.stage, y, uniformity, excludedFromPrediction } as Point;
    points.push(point);
    totalWeight += excludedFromPrediction ? 0 : averageWeight;
    N += excludedFromPrediction ? 0 : 1;
  }

  // because divide any number to 0, gives an error
  const meanWeight = (N === 0) ? 0 : roundFourDecimals((totalWeight / N) / 1000);

  const dataSourceByStagePoint: DataSourceByStage = { isPrediction, x: stage, y: meanWeight, points };
  
  return dataSourceByStagePoint;
};

export const buildGrowthSizes = (params: { biomass: number; uniformity: number; averageWeight: number; commercialSize: CommercialSize; commercialSizePriceTable: CommercialSizePriceTable }) => {
  const { biomass: totalBiomass, commercialSize, commercialSizePriceTable, uniformity, averageWeight } = params;
  
  const { whole: commercialSizes } = commercialSize.commercialSizes;
  const commercialSizeType = commercialSizeTypes.GROW_OUT_WHOLE;
  const prices: number[] = commercialSizePriceTable.prices.whole || [];
  
  const yieldPercentaje = 100;
  const wastePrice = 0;
  const animals = 150;
  const randomWeights = getRandomWeights({ uniformity, averageWeight, size: animals });

  const maxValueDisplay = getMaxValueDisplay({ commercialSizeType });
  const weightUnit = commercialSizeType === commercialSizeTypes.GROW_OUT_TAIL ? weightUnitsByCompany.POUND : weightUnitsByCompany.KILOGRAM;
  const commercialSizeRanges = generateCommercialSizeRanges({ commercialSizes, minValueDisplay: MIN_VALUE_DISPLAY_GROW_OUT, maxValueDisplay, weightUnit });
  const values = classifyGrowOutSizes(commercialSizeRanges, randomWeights);
  
  const clickableMinValue = getClickableMinValue({ commercialSizeType });
  const processedDataSource = processCommercialSizeRanges({ ranges: commercialSizeRanges, maxValueDisplay, commercialSizeType, clickableMinValue });
  const labels = processedDataSource.map(item => item.commercialSizeLabel);

  const growOutSize: GrowOutSize = { labels, values, prices };
  
  const dataset: Dataset[] = [];
  let incomeByYield = 0;

  for (let index = 0; index < growOutSize.values.length - 1; index++) {
    const frequency = growOutSize.values[index];
    const label = growOutSize.labels[index];
    const sizePrice = prices[index];
    const percent = roundFourDecimals((frequency * 100) / animals);
    const biomass = roundFourDecimals(totalBiomass * percent / 100);
    const biomassLb = roundFourDecimals(convertKilogramsToPounds(biomass, false));

    let pricePerPound = 0;
    if (commercialSizeType === commercialSizeTypes.GROW_OUT_WHOLE) {
      pricePerPound = (biomass * yieldPercentaje / 100) * sizePrice;
    }
    if (commercialSizeType === commercialSizeTypes.GROW_OUT_TAIL) {
      pricePerPound = (biomassLb * yieldPercentaje / 100) * sizePrice;
    }

    incomeByYield += pricePerPound;

    dataset.push({
      index,
      frequency,
      label,
      percent,
      biomass,
      biomassLb,
      sizePrice,
      pricePerPound,
      commercialSizeRanges,
    });
  }

  let incomeByRejection = 0;
  const rejectionPercentaje = 100 - yieldPercentaje;
  if (commercialSizeType === commercialSizeTypes.GROW_OUT_WHOLE) {
    incomeByRejection = (totalBiomass * rejectionPercentaje / 100) * wastePrice;
  }
  if (commercialSizeType === commercialSizeTypes.GROW_OUT_TAIL) {
    const totalBiomassLb = convertKilogramsToPounds(totalBiomass, false);
    incomeByRejection = (totalBiomassLb * rejectionPercentaje / 100) * wastePrice;
  }
  const potentialIncome = roundFourDecimals(incomeByYield + incomeByRejection);

  return { growOutSize, dataset, potentialIncome, randomWeights };
};

// export const buildGrowthSizes = (params: { randomWeights: number[]; biomass: number; commercialSize: CommercialSize; commercialSizePriceTable: CommercialSizePriceTable; animals: number }) => {
//   const { biomass: totalBiomass, commercialSize, commercialSizePriceTable, randomWeights, animals } = params;
//   const { type: commercialSizeType, sizes: commercialSizes } = commercialSize;
//   const { prices } = commercialSizePriceTable;
//   const yieldPercentaje = 90;
//   const wastePrice = 2;

//   const maxValueDisplay = getMaxValueDisplay({ commercialSizeType });
//   const weightUnit = commercialSizeType === commercialSizeTypes.GROW_OUT_TAIL ? weightUnitsByCompany.POUND : weightUnitsByCompany.KILOGRAM;
//   const commercialSizeRanges = generateCommercialSizeRanges({ commercialSizes, minValueDisplay: MIN_VALUE_DISPLAY_GROW_OUT, maxValueDisplay, weightUnit });
//   const values = classifyGrowOutSizes(commercialSizeRanges, randomWeights);
  
//   const clickableMinValue = getClickableMinValue({ commercialSizeType });
//   const processedDataSource = processCommercialSizeRanges({ ranges: commercialSizeRanges, maxValueDisplay, commercialSizeType, clickableMinValue });
//   const labels = processedDataSource.map(item => item.commercialSizeLabel);

//   const growOutSize: GrowOutSize = { labels, values, prices };
  
//   const dataset: Dataset[] = [];
//   let incomeByYield = 0;

//   for (let index = 0; index < growOutSize.values.length - 1; index++) {
//     const frequency = growOutSize.values[index];
//     const label = growOutSize.labels[index];
//     const sizePrice = prices[index];
//     const percent = roundFourDecimals((frequency * 100) / animals);
//     const biomass = roundFourDecimals(totalBiomass * percent / 100);
//     const biomassLb = roundFourDecimals(convertKilogramsToPounds(biomass, false));

//     let pricePerPound = 0;
//     if (commercialSizeType === commercialSizeTypes.GROW_OUT_WHOLE) {
//       pricePerPound = (biomass * yieldPercentaje / 100) * sizePrice;
//     }
//     if (commercialSizeType === commercialSizeTypes.GROW_OUT_TAIL) {
//       pricePerPound = (biomassLb * yieldPercentaje / 100) * sizePrice;
//     }

//     incomeByYield += pricePerPound;

//     dataset.push({
//       index,
//       frequency,
//       label,
//       percent,
//       biomass,
//       biomassLb,
//       sizePrice,
//       pricePerPound,
//       commercialSizeRanges,
//     });
//   }

//   let incomeByRejection = 0;
//   const rejectionPercentaje = 100 - yieldPercentaje;
//   if (commercialSizeType === commercialSizeTypes.GROW_OUT_WHOLE) {
//     incomeByRejection = (totalBiomass * rejectionPercentaje / 100) * wastePrice;
//   }
//   if (commercialSizeType === commercialSizeTypes.GROW_OUT_TAIL) {
//     const totalBiomassLb = convertKilogramsToPounds(totalBiomass, false);
//     incomeByRejection = (totalBiomassLb * rejectionPercentaje / 100) * wastePrice;
//   }
//   const potentialIncome = roundFourDecimals(incomeByYield + incomeByRejection);

//   return { growOutSize, dataset, potentialIncome, randomWeights };
// };

const buildAndGetFirstPrediction = (params: BuildAndGetFirstPredictionParams) => {
  const {
    dataSourceByStage, initialPopulation, survival, dailyFeeding, costPerVolumeDay,
    volume, commercialSize, commercialSizePriceTable,
  } = params;

  const biomass = roundFourDecimals(initialPopulation * dataSourceByStage.y) / 1000;
  const biomassLb = roundFourDecimals(convertKilogramsToPounds(biomass, false));
  const noExcludedPoints = dataSourceByStage.points?.filter(item => !item.excludedFromPrediction) as Point[];
  const uniformitySum = noExcludedPoints.reduce((sum, point) => sum + point.uniformity, 0);
  const uniformity = roundFourDecimals(uniformitySum / noExcludedPoints.length);
  const createdAt = dataSourceByStage?.points ? dataSourceByStage.points[0].createdAt : '';
  const correctedFoodQuantity = roundFourDecimals(dailyFeeding);
  const modelFoodQuantity = roundFourDecimals(Math.pow(POWER_BASE * dataSourceByStage.y, POWER_EXPONENT) * biomass);
  const accumulatedCost = costPerVolumeDay * dataSourceByStage.x * volume;
  const balancedAccumulatedCost = params.accumulatedCost;
  const totalAccumulatedCost = accumulatedCost + balancedAccumulatedCost;
  const { potentialIncome, dataset, growOutSize, randomWeights } = buildGrowthSizes({ biomass, uniformity, averageWeight: (dataSourceByStage.y * 1000), commercialSize, commercialSizePriceTable });
  const potentialGain = potentialIncome - totalAccumulatedCost;

  const firstPrediction: Point = {
    x: dataSourceByStage.x, y: dataSourceByStage.y,
    createdAt,
    uniformity,
    biomass, biomassLb,
    population: initialPopulation,
    survival,
    correctedFoodQuantity, modelFoodQuantity,
    accumulatedCost,
    balancedAccumulatedCost,
    totalAccumulatedCost,
    dataset,
    growOutSize,
    potentialIncome, potentialGain,
    randomWeights,
  };
  return firstPrediction;
};

// const buildAndGetFirstPrediction = (params: BuildAndGetFirstPredictionParams) => {
//   const {
//     dataSourceByStage, initialPopulation, survival, dailyFeeding, costPerVolumeDay,
//     volume, commercialSize, commercialSizePriceTable
//   } = params;

//   const packersWithCommercialSizes = params.packersWithCommercialSizes as GenericParam[];
//   const biomass = roundFourDecimals(initialPopulation * dataSourceByStage.y) / 1000;
//   const biomassLb = roundFourDecimals(convertKilogramsToPounds(biomass, false));
//   const noExcludedPoints = dataSourceByStage.points?.filter(item => !item.excludedFromPrediction) as Point[];
//   const uniformitySum = noExcludedPoints.reduce((sum, point) => sum + point.uniformity, 0);
//   const uniformity = roundFourDecimals(uniformitySum / noExcludedPoints.length);
//   const createdAt = dataSourceByStage?.points ? dataSourceByStage.points[0].createdAt : '';
//   const correctedFoodQuantity = roundFourDecimals(dailyFeeding);
//   const modelFoodQuantity = roundFourDecimals(Math.pow(POWER_BASE * dataSourceByStage.y, POWER_EXPONENT) * biomass);
//   const accumulatedCost = costPerVolumeDay * dataSourceByStage.x * volume;
//   const balancedAccumulatedCost = params.accumulatedCost;
//   const totalAccumulatedCost = accumulatedCost + balancedAccumulatedCost;

//   const animals = 150;
//   const randomWeights = getRandomWeights({ uniformity, averageWeight: (dataSourceByStage.y * 1000), size: animals });

//   const commercialSizeData: CommercialSizeData[] = [];
//   for (let index = 0; index < packersWithCommercialSizes.length; index++) {
//     const item = packersWithCommercialSizes[index];
    
//   }
//   const { potentialIncome, dataset, growOutSize } = buildGrowthSizes({ randomWeights, biomass, animals, commercialSize, commercialSizePriceTable });
//   const potentialGain = potentialIncome - totalAccumulatedCost;

//   const firstPrediction: Point = {
//     x: dataSourceByStage.x, y: dataSourceByStage.y,
//     createdAt,
//     uniformity,
//     biomass, biomassLb,
//     population: initialPopulation,
//     survival,
//     correctedFoodQuantity, modelFoodQuantity,
//     accumulatedCost,
//     balancedAccumulatedCost,
//     totalAccumulatedCost,
//     dataset,
//     growOutSize,
//     potentialIncome, potentialGain,
//     randomWeights,
//   };
//   return firstPrediction;
// };

export const calcDataSource = (params: CalcDataSourceParams) => {
  const {
    dataSource, isExcluding, firstStage, commercialSize, commercialSizePriceTable,
    dailyFeeding, costPerVolumeDay, initialPopulation, volume,
    harvestsAndTransfers, mortality, animalsSown, foodPricePerKg,
  } = params;

  const dataSourceByStage: DataSourceByStage[] = [];
  const allPredictions: Point[] = [];
  const allPoints: Point[] = [];

  if (dataSource.allAnalysis.length === 0 || dataSource.predictions.length === 0) {
    return { dataSourceByStage, allPredictions, allPoints };
  }

  let analysesToGroup = dataSource.allAnalysis;

  if (!isExcluding) {
    analysesToGroup = dataSource.allAnalysis.filter(item => item.inputData.stage >= firstStage);
  }

  if (analysesToGroup.length === 0) {
    return { dataSourceByStage, allPredictions, allPoints };
  }

  const analysisGroupByStage = groupBy(analysesToGroup, 'inputData.stage');
  
  for (const key in analysisGroupByStage) {
    if (Object.prototype.hasOwnProperty.call(analysisGroupByStage, key)) {
      const dataSourceByStagePoint = getDataSourceByStagePoint({ analysesByStage: analysisGroupByStage[key] });
      dataSourceByStage.push(dataSourceByStagePoint);
    }
  }

  const lastItem = dataSourceByStage[dataSourceByStage.length - 1];
  const firstPrediction = buildAndGetFirstPrediction({ accumulatedCost: params.accumulatedCost, costPerVolumeDay, dailyFeeding, dataSourceByStage: lastItem, initialPopulation, survival: params.survival, volume, commercialSize, commercialSizePriceTable });
  const firstPredictionStage = firstPrediction.x;
  const firstPredictionWeight = firstPrediction.y;
  const firstPredictionUniformity = firstPrediction.uniformity;
  allPredictions.push(firstPrediction);

  const lastPrediction = dataSource.predictions[dataSource.predictions.length - 1];
  const lastPredictionWeight = lastPrediction.resultData.averageWeight / 1000;
  const lastPredictionUniformity = lastPrediction.resultData.uniformity;
  const lastPredictionStage = lastPrediction.inputData.stage;

  let prevPrediction = firstPrediction;
  let stage = firstPredictionStage + 1;

  while (stage < lastPredictionStage) {
    const { y: prevAverageWeight, uniformity: prevUniformity, createdAt: prevCreatedAt } = prevPrediction;
    const prevPopulation = prevPrediction.population as number;
    const prevCorrectedFoodQuantity = prevPrediction.correctedFoodQuantity as number;
    const prevModelFoodQuantity = prevPrediction.modelFoodQuantity as number;
    const prevBalancedAccumulatedCost = prevPrediction.balancedAccumulatedCost as number;
  
    const uniformity = roundFourDecimals(prevUniformity + ((lastPredictionUniformity - firstPredictionUniformity) / (lastPredictionStage - firstPredictionStage)));
    const averageWeight = roundFourDecimals(prevAverageWeight + ((lastPredictionWeight - firstPredictionWeight) / (lastPredictionStage - firstPredictionStage)));
    const population = Math.round(prevPopulation - (prevPopulation * mortality / (DAYS_OF_THE_WEEK * 100)));
    const biomass = roundFourDecimals(population * averageWeight / 1000);
    const biomassLb = roundFourDecimals(convertKilogramsToPounds(biomass, false));
    const survival = roundFourDecimals((population + harvestsAndTransfers) / animalsSown * 100);
    const modelFoodQuantity = roundFourDecimals(Math.pow(POWER_BASE * averageWeight, POWER_EXPONENT) * biomass);
    const correctedFoodQuantity = roundFourDecimals(prevCorrectedFoodQuantity * modelFoodQuantity / prevModelFoodQuantity);
    const accumulatedCost = costPerVolumeDay * stage * volume;
    const balancedAccumulatedCost = prevBalancedAccumulatedCost + (correctedFoodQuantity * foodPricePerKg);
    const totalAccumulatedCost = accumulatedCost + balancedAccumulatedCost;
    const { potentialIncome, dataset, growOutSize, randomWeights } = buildGrowthSizes({ biomass, uniformity, averageWeight: (averageWeight * 1000), commercialSize, commercialSizePriceTable });
    const potentialGain = potentialIncome - totalAccumulatedCost;
    const createdAt = new Date(prevCreatedAt);
    createdAt.setDate(createdAt.getDate() + 1);
  
    const newPrediction: Point = {
      isPrediction: true,
      uniformity,
      x: stage, y: averageWeight,
      population,
      biomass, biomassLb,
      survival,
      modelFoodQuantity, correctedFoodQuantity,
      accumulatedCost,
      balancedAccumulatedCost,
      totalAccumulatedCost,
      potentialIncome, potentialGain,
      createdAt: createdAt.toISOString(),
      dataset, growOutSize,
      randomWeights,
    };
    const dataSourceByStagePoint: DataSourceByStage = { isPrediction: true, x: stage, y: averageWeight, points: [newPrediction] };
    dataSourceByStage.push(dataSourceByStagePoint);
    allPredictions.push(newPrediction);
    prevPrediction = newPrediction;
    stage += 1;
  }

  const prevPopulation = prevPrediction.population as number;
  const prevCorrectedFoodQuantity = prevPrediction.correctedFoodQuantity as number;
  const prevModelFoodQuantity = prevPrediction.modelFoodQuantity as number;
  const prevBalancedAccumulatedCost = prevPrediction.balancedAccumulatedCost as number;
  const prevCreatedAt = prevPrediction.createdAt;
  
  const population = Math.round(prevPopulation - (prevPopulation * mortality / (DAYS_OF_THE_WEEK * 100)));
  const biomass = roundFourDecimals(population * lastPredictionWeight / 1000);
  const biomassLb = roundFourDecimals(convertKilogramsToPounds(biomass, false));
  const survival = roundFourDecimals((population + harvestsAndTransfers) / animalsSown * 100);
  const modelFoodQuantity = roundFourDecimals(Math.pow(POWER_BASE * lastPredictionWeight, POWER_EXPONENT) * biomass);
  const correctedFoodQuantity = prevCorrectedFoodQuantity * modelFoodQuantity / prevModelFoodQuantity;
  const accumulatedCost = costPerVolumeDay * stage * volume;
  const balancedAccumulatedCost = prevBalancedAccumulatedCost + (correctedFoodQuantity * foodPricePerKg);
  const totalAccumulatedCost = accumulatedCost + balancedAccumulatedCost;
  const { potentialIncome, dataset, growOutSize, randomWeights } = buildGrowthSizes({ biomass, uniformity: lastPredictionUniformity, averageWeight: lastPrediction.resultData.averageWeight, commercialSize, commercialSizePriceTable });
  const potentialGain = potentialIncome - totalAccumulatedCost;
  const createdAt = new Date(prevCreatedAt);
  createdAt.setDate(createdAt.getDate() + 1);

  const newPrediction: Point = {
    isPrediction: true,
    x: stage,
    y: lastPredictionWeight,
    uniformity: lastPredictionUniformity,
    population,
    biomass, biomassLb,
    survival,
    modelFoodQuantity, correctedFoodQuantity,
    accumulatedCost,
    balancedAccumulatedCost,
    totalAccumulatedCost,
    potentialIncome, potentialGain,
    createdAt: createdAt.toISOString(),
    dataset, growOutSize,
    randomWeights,
  };
  const dataSourceByStagePoint: DataSourceByStage = { isPrediction: true, x: stage, y: lastPredictionWeight, points: [newPrediction] };
  dataSourceByStage.push(dataSourceByStagePoint);
  allPredictions.push(newPrediction);

  for (const item of dataSourceByStage) {
    const { points } = item;
    if (!points || points.length === 0) {
      continue;
    }
    for (const point of points) {
      allPoints.push(point);
    }
  }

  return { dataSourceByStage, allPredictions, allPoints };
};

// export const calcDataSource = (params: CalcDataSourceParams) => {
//   const {
//     dataSource, isExcluding, firstStage, commercialSize, commercialSizePriceTable,
//     dailyFeeding, costPerVolumeDay, initialPopulation, volume, packersWithCommercialSizes,
//     harvestsAndTransfers, mortality, animalsSown, foodPricePerKg,
//   } = params;

//   const dataSourceByStage: DataSourceByStage[] = [];
//   const allPredictions: Point[] = [];
//   const allPoints: Point[] = [];

//   if (dataSource.allAnalysis.length === 0 || dataSource.predictions.length === 0 || !packersWithCommercialSizes) {
//     return { dataSourceByStage, allPredictions, allPoints };
//   }

//   let analysesToGroup = dataSource.allAnalysis;

//   if (!isExcluding) {
//     analysesToGroup = dataSource.allAnalysis.filter(item => item.inputData.stage >= firstStage);
//   }

//   if (analysesToGroup.length === 0) {
//     return { dataSourceByStage, allPredictions, allPoints };
//   }

//   const analysisGroupByStage = groupBy(analysesToGroup, 'inputData.stage');
  
//   for (const key in analysisGroupByStage) {
//     if (Object.prototype.hasOwnProperty.call(analysisGroupByStage, key)) {
//       const dataSourceByStagePoint = getDataSourceByStagePoint({ analysesByStage: analysisGroupByStage[key] });
//       dataSourceByStage.push(dataSourceByStagePoint);
//     }
//   }

//   const lastItem = dataSourceByStage[dataSourceByStage.length - 1];
//   const firstPrediction = buildAndGetFirstPrediction({
//     accumulatedCost: params.accumulatedCost,
//     costPerVolumeDay, dailyFeeding,
//     dataSourceByStage: lastItem,
//     initialPopulation,
//     survival: params.survival,
//     volume, commercialSize, commercialSizePriceTable,
//     packersWithCommercialSizes,
//   });
//   const firstPredictionStage = firstPrediction.x;
//   const firstPredictionWeight = firstPrediction.y;
//   const firstPredictionUniformity = firstPrediction.uniformity;
//   allPredictions.push(firstPrediction);

//   const lastPrediction = dataSource.predictions[dataSource.predictions.length - 1];
//   const lastPredictionWeight = lastPrediction.resultData.averageWeight / 1000;
//   const lastPredictionUniformity = lastPrediction.resultData.uniformity;
//   const lastPredictionStage = lastPrediction.inputData.stage;

//   let prevPrediction = firstPrediction;
//   let stage = firstPredictionStage + 1;

//   while (stage < lastPredictionStage) {
//     const { y: prevAverageWeight, uniformity: prevUniformity, createdAt: prevCreatedAt } = prevPrediction;
//     const prevPopulation = prevPrediction.population as number;
//     const prevCorrectedFoodQuantity = prevPrediction.correctedFoodQuantity as number;
//     const prevModelFoodQuantity = prevPrediction.modelFoodQuantity as number;
//     const prevBalancedAccumulatedCost = prevPrediction.balancedAccumulatedCost as number;
  
//     const uniformity = roundFourDecimals(prevUniformity + ((lastPredictionUniformity - firstPredictionUniformity) / (lastPredictionStage - firstPredictionStage)));
//     const averageWeight = roundFourDecimals(prevAverageWeight + ((lastPredictionWeight - firstPredictionWeight) / (lastPredictionStage - firstPredictionStage)));
//     const population = Math.round(prevPopulation - (prevPopulation * mortality / (DAYS_OF_THE_WEEK * 100)));
//     const biomass = roundFourDecimals(population * averageWeight / 1000);
//     const biomassLb = roundFourDecimals(convertKilogramsToPounds(biomass, false));
//     const survival = roundFourDecimals((population + harvestsAndTransfers) / animalsSown * 100);
//     const modelFoodQuantity = roundFourDecimals(Math.pow(POWER_BASE * averageWeight, POWER_EXPONENT) * biomass);
//     const correctedFoodQuantity = roundFourDecimals(prevCorrectedFoodQuantity * modelFoodQuantity / prevModelFoodQuantity);
//     const accumulatedCost = costPerVolumeDay * stage * volume;
//     const balancedAccumulatedCost = prevBalancedAccumulatedCost + (correctedFoodQuantity * foodPricePerKg);
//     const totalAccumulatedCost = accumulatedCost + balancedAccumulatedCost;
//     const { potentialIncome, dataset, growOutSize, randomWeights } = buildGrowthSizes({ biomass, uniformity, averageWeight: (averageWeight * 1000), commercialSize, commercialSizePriceTable });
//     const potentialGain = potentialIncome - totalAccumulatedCost;
//     const createdAt = new Date(prevCreatedAt);
//     createdAt.setDate(createdAt.getDate() + 1);
  
//     const newPrediction: Point = {
//       isPrediction: true,
//       uniformity,
//       x: stage, y: averageWeight,
//       population,
//       biomass, biomassLb,
//       survival,
//       modelFoodQuantity, correctedFoodQuantity,
//       accumulatedCost,
//       balancedAccumulatedCost,
//       totalAccumulatedCost,
//       potentialIncome, potentialGain,
//       createdAt: createdAt.toISOString(),
//       dataset, growOutSize,
//       randomWeights,
//     };
//     const dataSourceByStagePoint: DataSourceByStage = { isPrediction: true, x: stage, y: averageWeight, points: [newPrediction] };
//     dataSourceByStage.push(dataSourceByStagePoint);
//     allPredictions.push(newPrediction);
//     prevPrediction = newPrediction;
//     stage += 1;
//   }

//   const prevPopulation = prevPrediction.population as number;
//   const prevCorrectedFoodQuantity = prevPrediction.correctedFoodQuantity as number;
//   const prevModelFoodQuantity = prevPrediction.modelFoodQuantity as number;
//   const prevBalancedAccumulatedCost = prevPrediction.balancedAccumulatedCost as number;
//   const prevCreatedAt = prevPrediction.createdAt;
  
//   const population = Math.round(prevPopulation - (prevPopulation * mortality / (DAYS_OF_THE_WEEK * 100)));
//   const biomass = roundFourDecimals(population * lastPredictionWeight / 1000);
//   const biomassLb = roundFourDecimals(convertKilogramsToPounds(biomass, false));
//   const survival = roundFourDecimals((population + harvestsAndTransfers) / animalsSown * 100);
//   const modelFoodQuantity = roundFourDecimals(Math.pow(POWER_BASE * lastPredictionWeight, POWER_EXPONENT) * biomass);
//   const correctedFoodQuantity = prevCorrectedFoodQuantity * modelFoodQuantity / prevModelFoodQuantity;
//   const accumulatedCost = costPerVolumeDay * stage * volume;
//   const balancedAccumulatedCost = prevBalancedAccumulatedCost + (correctedFoodQuantity * foodPricePerKg);
//   const totalAccumulatedCost = accumulatedCost + balancedAccumulatedCost;
//   const { potentialIncome, dataset, growOutSize, randomWeights } = buildGrowthSizes({ biomass, uniformity: lastPredictionUniformity, averageWeight: lastPrediction.resultData.averageWeight, commercialSize, commercialSizePriceTable });
//   const potentialGain = potentialIncome - totalAccumulatedCost;
//   const createdAt = new Date(prevCreatedAt);
//   createdAt.setDate(createdAt.getDate() + 1);

//   const newPrediction: Point = {
//     isPrediction: true,
//     x: stage,
//     y: lastPredictionWeight,
//     uniformity: lastPredictionUniformity,
//     population,
//     biomass, biomassLb,
//     survival,
//     modelFoodQuantity, correctedFoodQuantity,
//     accumulatedCost,
//     balancedAccumulatedCost,
//     totalAccumulatedCost,
//     potentialIncome, potentialGain,
//     createdAt: createdAt.toISOString(),
//     dataset, growOutSize,
//     randomWeights,
//   };
//   const dataSourceByStagePoint: DataSourceByStage = { isPrediction: true, x: stage, y: lastPredictionWeight, points: [newPrediction] };
//   dataSourceByStage.push(dataSourceByStagePoint);
//   allPredictions.push(newPrediction);

//   for (const item of dataSourceByStage) {
//     const { points } = item;
//     if (!points || points.length === 0) {
//       continue;
//     }
//     for (const point of points) {
//       allPoints.push(point);
//     }
//   }

//   return { dataSourceByStage, allPredictions, allPoints };
// };

export const checkValidInputs = (props: CheckValidInputsProps) => {
  const { survival, mortality, weeklyFeeding, foodPricePerKg, costPerVolumeDay, accumulatedCost } = props;
  
  return (
    mortality === 0 || weeklyFeeding === 0 || foodPricePerKg === 0 || costPerVolumeDay === 0 ||
    accumulatedCost === 0 || survival === 0
  );
};

export const getLabelsAxisY = (props: { chartParameter: string; weightUnit: string; currencySymbol: string; }) => {
  const { chartParameter, weightUnit, currencySymbol } = props;

  switch (chartParameter) {
    case chartParameters.WEIGHT:
      return `${i18next.t('optimalHarvestPoint.averageWeight')} ${weightUnits.G}`;

    case chartParameters.BIOMASS:
      if (weightUnit === weightUnitsByCompany.POUND) {
        return `${i18next.t('optimalHarvestPoint.biomass')} (${weightUnits.LB})`;
      }

      return `${i18next.t('optimalHarvestPoint.biomass')} (${weightUnits.KG})`;
      
    case chartParameters.CORRECTED_FOOD:
      return `${i18next.t('optimalHarvestPoint.food')}`;
      
    case chartParameters.POC:
      return `${i18next.t('optimalHarvestPoint.potentialGain')} (${currencySymbol})`;
      
    case chartParameters.POTENTIAL_INCOME:
      return `${i18next.t('optimalHarvestPoint.potentialIncome')} (${currencySymbol})`;
      
    case chartParameters.TOTAL_ACCUMULATED_COST:
      return `${i18next.t('optimalHarvestPoint.costs')} (${currencySymbol})`;

    default:
      return '';
  }
};
