import { useEffect, useState } from 'react';

import { Currency } from './interfaces';
import currencyCodes from './currency-codes.json';

export const useCurrency = () => {
  const [currencyList, setCurrencyList] = useState<Currency[]>([]);

  useEffect(() => {
    const currencyList: Currency[] = currencyCodes;
    setCurrencyList(currencyList);
  }, []);

  const findCurrencyByCode = (code: string) => {
    const currency = currencyList.find(item => item.code === code);
    return currency;
  };

  return {
    currencyList,
    findCurrencyByCode,
  };
};
