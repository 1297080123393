import { useEffect, useState } from 'react';
import { Form, Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import { validateString } from '../../../utils/validations';
import { LrvForm } from '../../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvInput } from '../../../common/components/LrvInput/LrvInput';
import { createDataApiKey, getDataApiKey, updateDataApiKey, updateWebHook } from '../settingsSlice';

import styles from './TabApiService.module.scss';

interface Props {
  theme?: 'dark' | 'light';
}

export const TabImageApi = (props: Props) => {
  const { theme } = props;

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [formApiToken] = Form.useForm();
  const [formWebHook] = Form.useForm();

  const [url, setUrl] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [disabledButton, setDisabledButton] = useState(true);

  const [copy, setCopy] = useState(t('share.copy'));
  const [showTooltip, setShowTooltip] = useState(false);

  const { dataApiKey } = useSelector((state: Store) => state.profile);

  useEffect(() => {
    dispatch(getDataApiKey());
  }, [dispatch]);

  useEffect(() => {
    setUrl(dataApiKey.url);
    setApiKey(dataApiKey.apiKey);

    formApiToken.setFieldsValue({
      apiKey: dataApiKey.apiKey
    });

    formWebHook.setFieldsValue({
      url: dataApiKey.url,
    });

    setDisabledButton(!dataApiKey.url);
  }, [formApiToken, formWebHook, dataApiKey]);

  const renderGenerateApiTokenButton = () => {
    return (
      <Button id='submit_api_token_button' type='primary' htmlType='submit'>
        {t('apiService.generate')}
      </Button>
    );
  };

  const renderSaveUrlButton = () => {
    return (
      <Form.Item>
        <div className={styles.alignButtonRight} >
          <Button id='submit_url_button' type='primary' htmlType='submit' disabled={disabledButton}>
            {t('apiService.save')}
          </Button>
        </div>
      </Form.Item>
    );
  };

  return (
    <>
      <LrvForm
        id='formApiToken'
        className={styles.container}
        form={formApiToken}
        name='formApiToken'
        layout='vertical'
        theme={theme}
        onFinish={() => {
          if (!apiKey) {
            dispatch(createDataApiKey());
            return;
          }
  
          dispatch(updateDataApiKey());
        }}
      >
        <Form.Item>
          <LrvText theme={theme} text={t('apiService.description.image')} />

          <ul>
            <li>
              <LrvText theme={theme} text={`${t('apiService.urls.larvae')} ${(t('apiService.params.larvae').toUpperCase())}`} />
            </li>

            <li>
              <LrvText theme={theme} text={`${t('apiService.urls.juvenile')} ${(t('apiService.params.juvenile').toUpperCase())}`} />
            </li>

            <li>
              <LrvText theme={theme} text={`${t('apiService.urls.growOut')} ${(t('apiService.params.growOut').toUpperCase())}`} />
            </li>
          </ul>
        </Form.Item>

        <div className={styles.line} />

        <Form.Item
          name='apiKey'
          label={t('apiService.apiKey')}
        >
          <Tooltip
            open={showTooltip}
            placement='top'
            title={copy}
          >
            <LrvInput
              theme={theme}
              className={styles.apiToken}
              onMouseEnter={() => {
                setShowTooltip(true);
              }}
              onMouseLeave={() => {
                setCopy(t('share.copy'));
                setShowTooltip(false);
              }}
              onClick={() => {
                setCopy(t('share.copied'));
                navigator.clipboard.writeText(apiKey);
              }}
              value={apiKey}
              readOnly
              onChange={(e) => setApiKey(e.target.value)}
              addonAfter={renderGenerateApiTokenButton()}
            />
          </Tooltip>

        </Form.Item>

        <div className={styles.line} />
      </LrvForm>

      <LrvForm
        id='formWebHook'
        form={formWebHook}
        name='formWebHook'
        layout='vertical'
        theme={theme}
        onFinish={() => {
          dispatch(updateWebHook({ url }));
        }}
        onFieldsChange={() => {
          const disabled = formWebHook.getFieldsError().filter(({ errors }) => errors.length).length > 0;
          setDisabledButton(disabled);
        }}
      >
        <Form.Item
          name='url'
          required
          label={t('apiService.url')}
          rules={[() => ({ validator (rule, value) { return validateString(value); } })]}
        >
          <LrvInput
            theme={theme}
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </Form.Item>

        {renderSaveUrlButton()}
      </LrvForm>
    </>
  );
};
