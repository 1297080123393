import { isBusinessManagerRole, isPromoterRole, isSalesManagerRole, isSalesRole, isSuperAdmin } from '../../config/commons';

import { Client, Plan } from './interfaces';

const FREE_QUOTA_DISTRIBUTION_COMPANIES = 70;

export const hasPermissionToManageCompanies = isSuperAdmin() || isSalesRole() || isSalesManagerRole() || isPromoterRole() || isBusinessManagerRole();

export const CLIENTS = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const PERSONALIZED_PLAN = 'PERSONALIZED_PLAN';
export const PREPAID_PLAN = 'PREPAID_PLAN';

export const COUNTRY_CODE = 'EC';
export const PHONE_PREFIX = '593';

export const MIN_DAYS_STAGE = 0;
export const MAX_DAYS_STAGE = 200;
export const MIN_DAYS_JUVENILE = 0;
export const MAX_DAYS_JUVENILE = 200;
export const MIN_DAYS_GROW_OUT = 0;
export const MAX_DAYS_GROW_OUT = 360;
export const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const MAX_LENGTH_COMPANY_CODE = 8;

export const discountPorcentage = {
  min: 5,
  max: 20,
};

export function getNextQuantityAnalysis (plans: Plan[], quota: number) {
  const quantityAnalysis = plans.map((plan) => plan.amountAnalysis);
  quantityAnalysis.sort((a, b) => a - b);
  let nextQuantityAnalysis = 0;

  if (quota > quantityAnalysis[quantityAnalysis.length - 1]) {
    return quantityAnalysis[quantityAnalysis.length - 1];
  }

  for (let index = 0; index < quantityAnalysis.length; index++) {
    if (quantityAnalysis[index] > quota && nextQuantityAnalysis === 0) {
      nextQuantityAnalysis = quantityAnalysis[index];
    }
  }

  return nextQuantityAnalysis;
}

export function getPreviousQuantityAnalysis (plans: Plan[], quota: number) {
  const quantityAnalysis = plans.map((plan) => plan.amountAnalysis);
  quantityAnalysis.sort((a, b) => a - b);

  let previuosQuantityAnalysis = 0;

  if (quota > quantityAnalysis[quantityAnalysis.length - 1]) {
    return quantityAnalysis[quantityAnalysis.length - 1];
  }

  for (let index = 1; index < quantityAnalysis.length; index++) {
    if (quantityAnalysis[index] > quota && previuosQuantityAnalysis === 0) {
      // eslint-disable-next-line
      if (index === 1 && quantityAnalysis[index - 1] > quota) {
        return 0;
      }
      previuosQuantityAnalysis = quantityAnalysis[index - 1];
    }
  }

  return previuosQuantityAnalysis;
}

function calculatePlanPriceNormalCompany (plans: Plan[], newQuota: number) {
  let planPrice = 0;

  const previousQuantityAnalysis = getPreviousQuantityAnalysis(plans, newQuota);
  const nextQuantityAnalysis = getNextQuantityAnalysis(plans, newQuota);

  const nextPlan = plans.find((plan) => plan.amountAnalysis === nextQuantityAnalysis);
  const previousPlan = plans.find((plan) => plan.amountAnalysis === previousQuantityAnalysis) ?? { amountAnalysis: 0, value: 0 };

  if (nextPlan) {
    const differenceAnalysis = newQuota - previousPlan.amountAnalysis;
    const pricePerAnalysis = nextPlan.value / nextPlan.amountAnalysis;

    planPrice = previousPlan.value + Math.round(pricePerAnalysis * differenceAnalysis);
  }

  return planPrice;
}

function calculatePlanPriceDistributionCompany (plans: Plan[], newQuota: number) {
  const planPriceTotal = calculatePlanPriceNormalCompany(plans, newQuota);
  const planPrice = planPriceTotal / newQuota;
  return Math.round(planPriceTotal - (planPrice * FREE_QUOTA_DISTRIBUTION_COMPANIES));
}

export function calculatePlanPrice (props: { plans: Plan[]; newQuota: number; isDistributor: boolean }) {
  const { plans, newQuota, isDistributor } = props;
  const planPrice = isDistributor ? calculatePlanPriceDistributionCompany(plans, newQuota) : calculatePlanPriceNormalCompany(plans, newQuota);
  return planPrice > 0 ? planPrice : 0;
}

export const getValueActiveUsers = (value: string) => {
  switch (value) {
    case CLIENTS.ACTIVE:
    default:
      return true;

    case CLIENTS.INACTIVE:
      return false;

    case CLIENTS.ALL:
      return undefined;
  }
};

export const clientDefault: Client = {
  _id: '',
  name: '',
  code: '',
  businessName: '',
  active: false,
  isDistributor: false,
  isInternational: false,
  daysToInitialStage: 0,
  allowAutomaticConsolidation: false,
  showStockingParameterSection: false,
  maxStage: 0,
  phonePrefix: '',
  countryCode: '',
  phone: '',
  maxDayJuvenile: 0,
  maxDayGrowOut: 0,
  sellerId: '',
  promoterId: '',
  planType: '',
  createdAt: '',
  address: '',
  ruc: '',
  owner: {
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
  },
  currencyCode: '',
};
