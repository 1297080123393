import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { SIGN_IN_URL, USERS_URL } from '../../config/config.api';
import { ApiClient, axiosClient } from '../../utils/axios_instance';
import { DEFAULT_CURRENCY_CODE, getMainRole, roles } from '../../config/commons';
import { openErrorNotification, openSuccessNotification } from '../../common/notification/Notification';
import { getUserSession } from '../../utils/userSession';

import { UserState, SignInForm, SignInPayload, RefreshingTokenPayload } from './interfaces';

const trialPhaseDateExpired = 'trialPhaseDateExpired';
const initialState: UserState = {
  id: '',
  language: '',
  email: '',
  companyId: '',
  firstName: '',
  lastName: '',
  accessToken: '',
  refreshToken: '',
  isAssignedCompany: false,
  currencyCode: DEFAULT_CURRENCY_CODE,
  isRefreshingToken: false,
  errorCodeLogin: 0,
  trialPhaseHasEnded: false,
  haveAccessPaymentReport: false,
  isLoading: false,
  isDistributor: false,
};

const apiClient: ApiClient = new ApiClient(false);
const axios = apiClient.axios;

export const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: (state: UserState, action: PayloadAction<SignInPayload>) => {
      const { _id, language, email, firstName, lastName } = action.payload.user;
      const { accessToken, refreshToken, currencyCode, isAssignedCompany, isDistributor } = action.payload;
      state.id = _id;
      state.language = language;
      state.email = email;
      state.firstName = firstName;
      state.lastName = lastName;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.currencyCode = currencyCode || DEFAULT_CURRENCY_CODE;
      state.isAssignedCompany = isAssignedCompany;
      state.isDistributor = isDistributor;
    },
    setIsLoading: (state: UserState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    signOut: (state: UserState) => {
      Object.assign(state, initialState);
      localStorage.removeItem('user');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('isAssignedCompany');
      localStorage.removeItem('isDistributor');
      localStorage.removeItem('currencyCode');
      window.location.href = '/';
    },
    setRefreshingToken: (state: UserState, action: PayloadAction<RefreshingTokenPayload>) => {
      state.isRefreshingToken = action.payload.isRefreshingToken;
    },
    setErrorCode: (state: UserState, action: PayloadAction<number>) => {
      state.errorCodeLogin = action.payload;
    },
    setTrialPhaseHasEnded: (state: UserState, action: PayloadAction<boolean>) => {
      state.trialPhaseHasEnded = action.payload;
    },
  },
});

export const { signIn, setIsLoading, signOut, setRefreshingToken, setErrorCode, setTrialPhaseHasEnded } = userSlice.actions;

export const signInUser = (data: SignInForm) => async (dispatch: Function) => {
  dispatch(setIsLoading(true));
  dispatch(setTrialPhaseHasEnded(false));

  try {
    const authResponse = await axios.post(SIGN_IN_URL, {
      email: data.email,
      password: data.password,
    });

    dispatch(signIn(authResponse.data));
    localStorage.setItem('user', JSON.stringify(authResponse.data.user));
    localStorage.setItem('isAssignedCompany', authResponse.data.isAssignedCompany);
    localStorage.setItem('currencyCode', authResponse.data?.currencyCode || DEFAULT_CURRENCY_CODE);
    localStorage.setItem('isDistributor', authResponse.data.isDistributor);
    localStorage.setItem('refreshToken', authResponse.data.refreshToken);
    localStorage.setItem('accessToken', authResponse.data.accessToken);

    let pathname = window.history.state?.state?.from?.pathname ?? '/';
    const higherRole = getMainRole();

    if (pathname === '/production/stockings' && higherRole === roles.FINANCE) {
      pathname = '/balance/manage';
    }

    window.location.href = pathname; // temporal, usar/configurar connected-react-router en su lugar
  } catch (error) {
    console.log(error);
    console.log(error.response);
    if (error.response?.status) {
      dispatch(setErrorCode(error.response.status));
    }

    if (error.response?.data?.data?.error && error.response?.data?.data?.error === trialPhaseDateExpired) {
      dispatch(setTrialPhaseHasEnded(true));
    }
  }

  dispatch(setIsLoading(false));
};

export const saveDefaultPhase = (params: { userId: string; companyId: string; defaultPhase: string; onSuccess: () => void }) => async (dispatch: Function) => {
  const { userId, companyId, defaultPhase, onSuccess } = params;
  const data = { companyId, defaultPhase };

  try {
    dispatch(setIsLoading(true));
    await axiosClient.patch(`${USERS_URL}/${userId}`, data);

    const userSession = getUserSession();
    userSession.defaultPhase = defaultPhase;
    localStorage.setItem('user', JSON.stringify(userSession));

    onSuccess();
    openSuccessNotification(i18next.t('defaultPhaseModal.success'));
  } catch (error) {
    console.log('error', error?.response);
    openErrorNotification(i18next.t('analysis.unknowError'));
  }
  dispatch(setIsLoading(false));
};

export default userSlice.reducer;
