import i18next from 'i18next';
import { isEqual } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Company } from '../AppHeader/interfaces';
import * as headerSlice from '../AppHeader/headerSlice';
import { GenericParam } from '../../common/interfaces/commons';
import { axiosClient as axios } from '../../utils/axios_instance';
import { emailError, plansTypes, typeErrorUpdateClient, isAdminUser } from '../../config/commons';
import { openSuccessNotification, openErrorNotification } from '../../common/notification/Notification';
import { CLIENTS_URL, USERS_URL, SEARCH_CLIENTS_URL, PLANS_URL, COMPANIES_URL, COMPANY_BALANCES_URL, CLIENT_BALANCES_URL, SEARCH_SELLER_URL, SEARCH_PROMOTERS_URL } from '../../config/config.api';

import { CLIENTS } from './clientsHelper';
import { Client, ClientsState, EditClientBody, NewClientBody, SettingClientBody, User } from './interfaces';

const initialState: ClientsState = {
  total: 0,
  skip: 0,
  clients: [],
  sellers: [],
  promoters: [],
  selectedClient: {
    _id: '',
    name: '',
    businessName: '',
    code: '',
    active: true,
    isDistributor: false,
    isInternational: false,
    daysToInitialStage: 0,
    maxStage: 0,
    maxDayJuvenile: 0,
    maxDayGrowOut: 0,
    phone: '',
    phonePrefix: '',
    countryCode: '',
    trialEndDate: '',
    sellerId: '',
    promoterId: '',
    planType: '',
    createdAt: '',
    address: '',
    ruc: '',
    owner: {
      _id: '',
      firstName: '',
      lastName: '',
      email: '',
    },
    balance: {
      _id: '',
      quota: 0,
      bonusQuota: 0,
      quotaUsage: 0,
      planPrice: 0,
      planPriceOverride: 0,
      extraPricePerAnalysis: 0,
      availableQuota: 0,
      renuevalMonths: [],
      discountPercentage: 0,
    },
    currencyCode: '',
    users: [],
    maturations: [],
    assignedCompanies: [],
    showStockingParameterSection: false,
  },
  isClientLoading: false,
  isClientsLoading: false,
  isFormCreateLoading: false,
  isFormUpdateLoading: false,
  hasEmailError: false,
  plans: [],
  companies: [],
  activeAssignedCompanies: [],
  inactiveAssignedCompanies: [],
  filters: {
    clientStatus: CLIENTS.ACTIVE,
  },
  showEditClientModal: false,
  showSettingClientModal: false,
  showCreateClientModal: false,
};

export const userSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setClients: (state: ClientsState, action: PayloadAction<Client[]>) => {
      state.clients = action.payload;
    },
    setSelectedClient: (state: ClientsState, action: PayloadAction<Client>) => {
      state.selectedClient = action.payload;
    },
    setIsClientLoading: (state: ClientsState, action: PayloadAction<boolean>) => {
      state.isClientLoading = action.payload;
    },
    setIsClientsLoading: (state: ClientsState, action: PayloadAction<boolean>) => {
      state.isClientsLoading = action.payload;
    },
    setIsFormCreateLoading: (state: ClientsState, action: PayloadAction<boolean>) => {
      state.isFormCreateLoading = action.payload;
    },
    setIsFormUpdateLoading: (state: ClientsState, action: PayloadAction<boolean>) => {
      state.isFormUpdateLoading = action.payload;
    },
    setHasEmailError: (state: ClientsState, action: PayloadAction<boolean>) => {
      state.hasEmailError = action.payload;
    },
    setPlans: (state: ClientsState, action: PayloadAction<[]>) => {
      state.plans = action.payload;
    },
    setCompanies: (state: ClientsState, action: PayloadAction<Company[]>) => {
      state.companies = action.payload;
    },
    setActiveAssignedCompanies: (state: ClientsState, action: PayloadAction<Company[]>) => {
      state.activeAssignedCompanies = action.payload;
    },
    setInactiveAssignedCompanies: (state: ClientsState, action: PayloadAction<Company[]>) => {
      state.inactiveAssignedCompanies = action.payload;
    },
    setSellers: (state: ClientsState, action: PayloadAction<User[]>) => {
      state.sellers = action.payload;
    },
    setPromoters: (state: ClientsState, action: PayloadAction<User[]>) => {
      state.promoters = action.payload;
    },

    setClientStatus: (state: ClientsState, action: PayloadAction<string>) => {
      state.filters.clientStatus = action.payload;
    },
    setShowEditClientModal: (state: ClientsState, action: PayloadAction<boolean>) => {
      state.showEditClientModal = action.payload;
    },
    setShowSettingClientModal: (state: ClientsState, action: PayloadAction<boolean>) => {
      state.showSettingClientModal = action.payload;
    },
    setShowCreateClientModal: (state: ClientsState, action: PayloadAction<boolean>) => {
      state.showCreateClientModal = action.payload;
    },
  },
});

export const {
  setClients, setSelectedClient, setIsClientLoading, setIsClientsLoading,
  setIsFormCreateLoading, setHasEmailError, setPlans,
  setCompanies, setActiveAssignedCompanies, setInactiveAssignedCompanies,
  setSellers, setPromoters, setIsFormUpdateLoading,

  setClientStatus,
  setShowEditClientModal,
  setShowSettingClientModal,
  setShowCreateClientModal,
} = userSlice.actions;

export const fetchClients = (props: { active?: boolean }) => async (dispatch: Function) => {
  const { active } = props;
  dispatch(setIsClientsLoading(true));

  const params = {
    testing: false,
    active: active === undefined ? undefined : active,
  };

  try {
    const response = await axios.get<Client[]>(CLIENTS_URL, { params });
    dispatch(setClients(response.data));
    dispatch(setIsClientsLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchSearchClients = (data: { name?: string | number; email?: string | number; clientStatus?: boolean }) => async (dispatch: Function) => {
  const { name, email, clientStatus } = data;
  let clients = [];

  dispatch(setIsClientsLoading(true));
  const params = {
    $limit: 0,
    active: clientStatus === undefined ? undefined : clientStatus,
    name: name === undefined ? undefined : name,
    email: email === undefined ? undefined : email,
  };

  try {
    const response = await axios.get<Client[]>(SEARCH_CLIENTS_URL, { params });
    clients = response.data;
    dispatch(setClients(clients));
    dispatch(setIsClientsLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

const fetchClientBalances = async (companyId: string) => {
  const response = await axios.get(`${CLIENT_BALANCES_URL}/${companyId}`);
  return response.data.assignedCompanyBalances;
};

const fetchUsers = async (companyId: string) => {
  const params = {
    $limit: -1,
    companyId: companyId,
    active: true,
    '$sort[firstName]': 1
  };

  const response = await axios.get(USERS_URL, { params });
  return response.data;
};

export const fetchClient = (clientId: string, isDistributor?: boolean) => async (dispatch: Function) => {
  let client: Client;
  dispatch(setIsClientLoading(true));

  try {
    const response = await axios.get(`${CLIENTS_URL}/${clientId}`);
    client = response.data;

    client.users = await fetchUsers(clientId);

    if (isDistributor) {
      client.assignedCompanyBalances = await fetchClientBalances(clientId);
    }

    if (!client.owner) {
      client.owner = {
        _id: '',
        firstName: '',
        lastName: '',
        email: ''
      };
    }
  } catch (e) {
    console.log('ERROR', e);
    return;
  }

  dispatch(setSelectedClient(client));
  dispatch(setIsClientLoading(false));
};

export const createClient = (props: { data: NewClientBody; active: boolean | undefined; }) => async (dispatch: Function) => {
  const { data, active } = props;
  dispatch(setIsFormCreateLoading(true));
  dispatch(setHasEmailError(false));

  try {
    await axios.post(CLIENTS_URL, data);

    if (isAdminUser()) {
      dispatch(headerSlice.fetchCompanies());
    }
  } catch (e) {
    if (e.response.data.data && e.response.data.data.error === emailError.DUPLICATE) {
      dispatch(setHasEmailError(true));
    }
    dispatch(setIsFormCreateLoading(false));
    console.log('ERROR', e);
    return;
  }

  dispatch(setIsFormCreateLoading(false));
  dispatch(fetchClients({ active }));
  openSuccessNotification(i18next.t('clients.clientCreated'));
};

export const updateClient = (props: { data: EditClientBody; active: boolean | undefined; resetEditForm: Function; }) => async (dispatch: Function) => {
  const { active, data, resetEditForm } = props;

  dispatch(setIsFormUpdateLoading(true));
  dispatch(setIsClientLoading(true));

  const shouldUpdatePlan = data.planPriceOverride !== data.currentPlanPriceOverride && data.planType === plansTypes.POSTPAID;

  try {
    await axios.patch(`${CLIENTS_URL}/${data._id}`, data);

    if (isAdminUser() && shouldUpdatePlan) {
      const patchData = { planPriceOverride: data.planPriceOverride };
      await axios.patch(`${COMPANY_BALANCES_URL}/${data._id}/plan-price`, patchData);
    }

    resetEditForm();
  } catch (e) {
    console.log('ERROR', e);
    if (e.response?.data?.data?.error) {
      showErrorUpdateClient(e.response.data.data.error);
      dispatch(setIsFormUpdateLoading(false));
      dispatch(setIsClientLoading(false));
    }
    return;
  }

  dispatch(fetchClients({ active }));
  dispatch(setIsFormUpdateLoading(false));
  dispatch(setIsClientLoading(false));
  openSuccessNotification(i18next.t('clients.clientUpdated'));
};

export const updateClientSetting = (props: { data: SettingClientBody; active: boolean | undefined; resetEditForm: Function; }) => async (dispatch: Function) => {
  const { active, data, resetEditForm } = props;

  dispatch(setIsFormUpdateLoading(true));
  dispatch(setIsClientLoading(true));

  try {
    await axios.patch(`${CLIENTS_URL}/${data._id}`, data);
    resetEditForm();
  } catch (e) {
    console.log('ERROR', e);
    if (e.response?.data?.data?.error) {
      showErrorUpdateClient(e.response.data.data.error);
      dispatch(setIsFormUpdateLoading(false));
      dispatch(setIsClientLoading(false));
    }
    return;
  }

  dispatch(fetchClients({ active }));
  dispatch(setIsFormUpdateLoading(false));
  dispatch(setIsClientLoading(false));
  openSuccessNotification(i18next.t('clients.clientUpdated'));
};

export const updateClient2 = (
  clientData: {
    clientId: string;
    newOwnerId: string; currentOwnerId: string;
    newName: string; currentName: string;
    newCode?: string; currentCode?: string;
    newBusinessName?: string; currentBusinessName?: string;
    newActive: boolean; currentActive: boolean;
    newAssignedCompanies?: string[]; currentAssignedCompanies?: string[];
    newAssignedCompaniesActive?: string[]; currentAssignedCompaniesActive?: string[];
    newPlanPriceOverride: string | number; currentPlanPriceOverride: string;
    newIsDistributor: boolean; currentIsDistributor: boolean;
    newTrialPhase: boolean; currentTrialPhase: boolean;
    newIsInternational: boolean; currentIsInternational: boolean;
    newAllowAutomaticConsolidation?: boolean; currentAllowAutomaticConsolidation?: boolean;
    newDaysToInitialStage: number | string; currentDaysToInitialStage: number;
    newMaxStage: number | string; currentMaxStage: number;
    newMaxDayJuvenile: number | string; currentMaxDayJuvenile: number;
    newMaxDayGrowOut: number | string; currentMaxDayGrowOut: number;
    newRuc?: string; currentRuc?: string;
    newPhone: string; currentPhone: string;
    newPhonePrefix: string; currentPhonePrefix: string;
    newCountryCode: string; currentCountryCode: string;
    newAddress?: string; currentAddress?: string;
    newSeller?: string; currentSeller?: string;
    newPromoter?: string; currentPromoter?: string;
    newPlanType?: string; currentPlanType?: string;
    newAllowXlsxAnalysisReport?: boolean; currentAllowXlsxAnalysisReport?: boolean;
    newShowStockingParameterSection: boolean; currentShowStockingParameterSection: boolean;
  },
  activeClients: boolean | undefined,
  resetEditForm: Function,
) => async (dispatch: Function) => {
  dispatch(setIsFormUpdateLoading(true));
  dispatch(setIsClientLoading(true));
  let patchData: GenericParam = {};

  if (clientData.newName !== clientData.currentName) {
    patchData.name = clientData.newName;
  }

  if (clientData.newCode !== clientData.currentCode) {
    patchData.code = clientData.newCode;
  }

  if (clientData.newBusinessName !== clientData.currentBusinessName) {
    patchData.businessName = clientData.newBusinessName;
  }

  if (clientData.newOwnerId !== clientData.currentOwnerId) {
    patchData.ownerId = clientData.newOwnerId;
  }

  if (clientData.newActive !== clientData.currentActive) {
    patchData.active = clientData.newActive;
  }

  if (clientData.newTrialPhase !== clientData.currentTrialPhase) {
    patchData.hasTrialPhase = clientData.newTrialPhase;
  }

  if (clientData.newIsInternational !== clientData.currentIsInternational) {
    patchData.isInternational = clientData.newIsInternational;
  }

  if (clientData.newAllowAutomaticConsolidation !== clientData.currentAllowAutomaticConsolidation) {
    patchData.allowAutomaticConsolidation = clientData.newAllowAutomaticConsolidation;
  }

  if (clientData.newDaysToInitialStage !== clientData.currentDaysToInitialStage) {
    patchData.daysToInitialStage = clientData.newDaysToInitialStage;
  }

  if (clientData.newMaxStage !== clientData.currentMaxStage) {
    patchData.maxStage = clientData.newMaxStage;
  }

  if (clientData.newMaxDayJuvenile !== clientData.currentMaxDayJuvenile) {
    patchData.maxDayJuvenile = clientData.newMaxDayJuvenile;
  }

  if (clientData.newMaxDayGrowOut !== clientData.currentMaxDayGrowOut) {
    patchData.maxDayGrowOut = clientData.newMaxDayGrowOut;
  }

  if (clientData.newRuc !== clientData.currentRuc) {
    patchData.ruc = clientData.newRuc;
  }

  if (clientData.newPhone !== clientData.currentPhone) {
    patchData.phone = clientData.newPhone;
  }

  if (clientData.newPhonePrefix !== clientData.currentPhonePrefix) {
    patchData.phonePrefix = clientData.newPhonePrefix;
  }

  if (clientData.newCountryCode !== clientData.currentCountryCode) {
    patchData.countryCode = clientData.newCountryCode;
  }

  if (clientData.newAddress !== clientData.currentAddress) {
    patchData.address = clientData.newAddress;
  }

  if (!isEqual(clientData.newAssignedCompanies, clientData.currentAssignedCompanies)) {
    patchData.assignedCompanies = clientData.newAssignedCompanies;
  }

  if (!isEqual(clientData.newAssignedCompaniesActive, clientData.currentAssignedCompaniesActive)) {
    patchData.activeAssignedCompanies = clientData.newAssignedCompaniesActive;
  }

  if (clientData.newIsDistributor !== clientData.currentIsDistributor) {
    patchData.isDistributor = clientData.newIsDistributor;

    if (!clientData.newIsDistributor) {
      patchData.assignedCompanies = [];
    }
  }

  if (clientData.newSeller !== clientData.currentSeller) {
    patchData.sellerId = clientData.newSeller ?? null;
  }

  if (clientData.newPromoter !== clientData.currentPromoter) {
    patchData.promoterId = clientData.newPromoter ?? null;
  }

  if (clientData.newPlanType !== clientData.currentPlanType) {
    patchData.planType = clientData.newPlanType;
  }

  if (clientData.newAllowXlsxAnalysisReport !== clientData.currentAllowXlsxAnalysisReport) {
    patchData.allowXlsxAnalysisReport = clientData.newAllowXlsxAnalysisReport;
  }

  if (clientData.newShowStockingParameterSection !== clientData.currentShowStockingParameterSection) {
    patchData.showStockingParameterSection = clientData.newShowStockingParameterSection;
  }

  const canUpdatePlan = clientData.newPlanPriceOverride.toString() !== clientData.currentPlanPriceOverride && clientData.newPlanType === plansTypes.POSTPAID;

  if (isAdminUser() && canUpdatePlan) {
    patchData.currentPlanPriceOverride = clientData.currentPlanPriceOverride;
    patchData.planPriceOverride = clientData.newPlanPriceOverride;
  }

  try {
    await axios.patch(`${CLIENTS_URL}/${clientData.clientId}`, patchData);

    if (isAdminUser() && canUpdatePlan) {
      patchData = { planPriceOverride: clientData.newPlanPriceOverride };
      await axios.patch(`${COMPANY_BALANCES_URL}/${clientData.clientId}/plan-price`, patchData);
    }
    resetEditForm();
  } catch (e) {
    console.log('ERROR', e);
    if (e.response?.data?.data?.error) {
      showErrorUpdateClient(e.response.data.data.error);
      dispatch(setIsFormUpdateLoading(false));
      dispatch(setIsClientLoading(false));
    }
    return;
  }

  dispatch(fetchClient(clientData.clientId, clientData.newIsDistributor));
  dispatch(fetchClients({ active: activeClients }));
  dispatch(setIsFormUpdateLoading(false));
  dispatch(setIsClientLoading(false));
  openSuccessNotification(i18next.t('clients.clientUpdated'));
};

export const updateRenuevalMonths = async (
  props: {
    companyBalanceId: string;
    renuevalMonths?: number[];
    discountPercentage?: number;
  }
) => {
  const { companyBalanceId, renuevalMonths, discountPercentage } = props;

  const patchData = {
    renuevalMonths,
    discountPercentage,
  };

  try {
    await axios.patch(`${COMPANY_BALANCES_URL}/${companyBalanceId}`, patchData);
  } catch (e) {
    console.log('ERROR', e);
  }
};

const showErrorUpdateClient = (error: string) => {
  switch (error) {
    case typeErrorUpdateClient.INVALID_QUOTA_DISTRIBUTION_COMPANY:
      openErrorNotification(i18next.t('clients.errorsUpdate.invalidQuotaDistributionCompany'));
      break;

    case typeErrorUpdateClient.COMPANY_ALREADY_ASSIGNED:
      openErrorNotification(i18next.t('clients.errorsUpdate.companyAlreadyAssigned'));
      break;

    case typeErrorUpdateClient.COMPANY_BE_ASSIGNED_TO_ITSELF:
      openErrorNotification(i18next.t('clients.errorsUpdate.companyBeAssignedToItself'));
      break;

    case typeErrorUpdateClient.PARENT_COMPANY_BE_ASSIGNED:
      openErrorNotification(i18next.t('clients.errorsUpdate.parentCompanyBeAssigned'));
      break;
  }
};

export const fetchPlans = () => async (dispatch: Function) => {
  const params = {
    '$limit': -1,
    '$sort[value]': 1,
    'type': plansTypes.POSTPAID,
  };

  try {
    const response = await axios.get(PLANS_URL, { params });
    dispatch(setPlans(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchCompanies = () => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    '$sort[name]': 1,
    active: true,
    $select: ['name']
  };

  try {
    const response = await axios.get<Company[]>(COMPANIES_URL, { params });
    dispatch(setCompanies(response.data));
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const fetchCompaniesByIds = (ids: string[]) => async (dispatch: Function) => {
  try {
    const params = {
      _id: ids,
      $limit: -1,
      '$sort[name]': 1,
      $select: ['name', 'active']
    };

    const response = await axios.get(COMPANIES_URL, { params });

    const companies: Company[] = response.data;
    const activeCompanies: Company[] = companies.filter((company) => company.active);
    const inactiveCompanies: Company[] = companies.filter((company) => !company.active);

    dispatch(setActiveAssignedCompanies(activeCompanies));
    dispatch(setInactiveAssignedCompanies(inactiveCompanies));
  } catch (e) {
    console.log('ERROR', e);
    return;
  }
};

export const fetchSellers = () => async (dispatch: Function) => {
  try {
    const response = await axios.get<User[]>(SEARCH_SELLER_URL);
    dispatch(setSellers(response.data));
  } catch (e) {
    console.log('ERROR', e?.response);
  }
};

export const fetchPromotersBySeller = (sellerId: string) => async (dispatch: Function) => {
  try {
    dispatch(setPromoters([]));
    const response = await axios.get<User[]>(`${SEARCH_PROMOTERS_URL}/${sellerId}`);
    dispatch(setPromoters(response.data));
  } catch (e) {
    console.log('ERROR', e?.response);
  }
};

export default userSlice.reducer;
