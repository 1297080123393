import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CommercialSize, Packer } from '../interfaces';
import { axiosClient as axios } from '../../../../utils/axios_instance';
import { openSuccessNotification } from '../../../../common/notification/Notification';
import { COMMERCIAL_SIZE_PRICE_TABLE_URL, PACKERS_URL } from '../../../../config/config.api';

import { CommercialSizePriceTable, TablePriceState } from './interfaces';

const initialState: TablePriceState = {
  isLoading: false,
  isCreating: false,
  showCreateModal: false,
  isUpdating: false,
  showUpdateModal: false,
  packer: {
    name: '',
    companyId: '',
    commercialSizes: [],
  },
  commercialSize: {
    active: false,
    name: '',
    commercialSizes: {
      tail: [],
      whole: [],
    },
  },
  commercialSizePriceTables: [],
};

export const tablePriceSlice = createSlice({
  name: 'tablePrices',
  initialState,
  reducers: {
    setPacker: (state: TablePriceState, action: PayloadAction<Packer>) => {
      state.packer = action.payload;
    },
    setCommercialSizePriceTables: (state: TablePriceState, action: PayloadAction<CommercialSizePriceTable[]>) => {
      state.commercialSizePriceTables = action.payload;
    },
    setIsLoading: (state: TablePriceState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsCreating: (state: TablePriceState, action: PayloadAction<boolean>) => {
      state.isCreating = action.payload;
    },
    setShowCreateModal: (state: TablePriceState, action: PayloadAction<boolean>) => {
      state.showCreateModal = action.payload;
    },
    setIsUpdating: (state: TablePriceState, action: PayloadAction<boolean>) => {
      state.isUpdating = action.payload;
    },
    setShowUpdateModal: (state: TablePriceState, action: PayloadAction<boolean>) => {
      state.showUpdateModal = action.payload;
    },
    setCommercialSize: (state: TablePriceState, action: PayloadAction<CommercialSize | undefined>) => {
      state.commercialSize = action.payload;
    },
    setCommercialSizePriceTable: (state: TablePriceState, action: PayloadAction<CommercialSizePriceTable>) => {
      state.commercialSizePriceTable = action.payload;
    },
  },
});

export const {
  setPacker,
  setCommercialSizePriceTables,
  setIsLoading,
  setIsCreating,
  setShowCreateModal,
  setIsUpdating,
  setShowUpdateModal,
  setCommercialSize,
  setCommercialSizePriceTable,
} = tablePriceSlice.actions;

export const fetchPacker = (props: {packerId: string; commercialSizeId: string; }) => async (dispatch: Function) => {
  const { packerId, commercialSizeId } = props;

  const params = {
    $select: ['name', 'active', 'commercialSizes'],
  };

  const url = `${PACKERS_URL}/${packerId}`;

  try {
    const response = await axios.get<Packer>(url, { params });
    const packer = response.data;
    dispatch(setPacker(packer));
    
    const commercialSize = packer.commercialSizes ? packer.commercialSizes.find((commercialSize) => commercialSize._id === commercialSizeId) : undefined;
    dispatch(setCommercialSize(commercialSize));
    
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchCommercialSizePriceTables = (props: {packerId: string; commercialSizeId: string; }) => async (dispatch: Function) => {
  const { packerId, commercialSizeId } = props;

  const params = {
    packerId,
    commercialSizeId,
    $limit: -1,
    $select: ['date', 'active'],
  };

  dispatch(setIsLoading(true));

  try {
    const response = await axios.get<CommercialSizePriceTable[]>(COMMERCIAL_SIZE_PRICE_TABLE_URL, { params });
    dispatch(setCommercialSizePriceTables(response.data));
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsLoading(false));
};

export const fetchCommercialSizePriceTable = (props: {commercialSizePriceTableId: string; }) => async (dispatch: Function) => {
  const { commercialSizePriceTableId } = props;

  const url = `${COMMERCIAL_SIZE_PRICE_TABLE_URL}/${commercialSizePriceTableId}`;

  try {
    const response = await axios.get<CommercialSizePriceTable>(url);
    dispatch(setCommercialSizePriceTable(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const createCommercialSizePriceTable = (props: { body: CommercialSizePriceTable; onSuccess: () => void; }) => async (dispatch: Function) => {
  const { body, onSuccess } = props;
  
  dispatch(setIsCreating(true));

  try {
    await axios.post(COMMERCIAL_SIZE_PRICE_TABLE_URL, body);
    dispatch(fetchCommercialSizePriceTables({ packerId: body.packerId, commercialSizeId: body.commercialSizeId }));

    onSuccess();
    openSuccessNotification(i18next.t('priceTable.created'));
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsCreating(false));
};

export const updateCommercialSizePriceTable = (props: { commercialSizePriceTableId: string; body: CommercialSizePriceTable; onSuccess: () => void; }) => async (dispatch: Function) => {
  const { commercialSizePriceTableId, body, onSuccess } = props;
  
  dispatch(setIsUpdating(true));
  const url = `${COMMERCIAL_SIZE_PRICE_TABLE_URL}/${commercialSizePriceTableId}`;

  try {
    await axios.patch(url, body);
    dispatch(fetchCommercialSizePriceTables({ packerId: body.packerId, commercialSizeId: body.commercialSizeId }));

    onSuccess();
    openSuccessNotification(i18next.t('priceTable.updated'));
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsUpdating(false));
};

export default tablePriceSlice.reducer;
