import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import TabPane from 'antd/lib/tabs/TabPane';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getCurrentTheme } from '../../../helpers/theme';
import { changeHeader } from '../../AppHeader/headerSlice';
import Content from '../../../common/components/Content/Content';
import { LrvTabs } from '../../../common/components/LrvTabs/LrvTabs';

import styles from './Settings.module.scss';
import { Currency } from './Currency/Currency';
import { WeightUnit } from './WeightUnit/WeightUnit';
import * as companySettingsSlice from './companySettingsSlice';
import { CompanySettingsTab, companySettingsTabs } from './interfaces';

const Settings = () => {
  const theme = getCurrentTheme();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  
  const mode = window.innerWidth >= 620 ? 'left' : 'top';
  const [settingsTab, setSettingsTab] = useState<CompanySettingsTab>(companySettingsTabs.WEIGHT_UNITS);

  useEffect(() => {
    dispatch(companySettingsSlice.fetchCompanySetting());
    dispatch(changeHeader({ title: 'sidebar.companySettings' }));
  }, [dispatch]);

  return (
    <div className={styles.settings}>
      <Row>
        <Col className={styles.columnFlex} span={24}>
          <Content className={styles.content}>
            <LrvTabs
              className='tabs'
              defaultActiveKey={settingsTab}
              tabPosition={mode}
              theme={theme}
              onChange={(key) => {
                setSettingsTab(key as CompanySettingsTab);
              }}
            >
              <TabPane
                className={styles.tabPane}
                key={companySettingsTabs.WEIGHT_UNITS}
                tab={<div id='tab_weight_units'>{t('companySettings.weightUnits')}</div>}
              >
                <WeightUnit />
              </TabPane>
              <TabPane
                className={styles.tabPane}
                key={companySettingsTabs.CURRENCY}
                tab={<div id='tab_currency'>{t('companySettings.currency')}</div>}
              >
                <Currency />
              </TabPane>
            </LrvTabs>
          </Content>
        </Col>
      </Row>
    </div>
  );
};

export default Settings;
