import moment from 'moment';
import i18next from 'i18next';

import { Stocking } from '../pages/Pdfs/GrowthDelta/interfaces';
import { formatLongDateWithZone, formatYearMonthDay, getHoursOffset } from '../utils/date';
import { openErrorNotification } from '../common/notification/Notification';
import { typeParam, typesChart } from '../common/components/charts/ShadedPlot/helpers';
import { StockingDateRange, Stocking as IStocking } from '../pages/Sowings/interfaces';
import { animalDaysUnit, inactiveCampus, lengthUnits, stockingPhaseTypes, typeErrorStocking, unitPhaseTypes, weightUnits, LBS_TO_KG_RATIO, containerTypes, roundWeight, volumeUnits } from '../config/commons';

export const calcDensityLarvae = (props: { litersNumber: number; naupliusNumber: number }) => {
  const { litersNumber, naupliusNumber } = props;
  return Math.round(naupliusNumber / litersNumber);
};

export const calcDensity = (stocking: Stocking) => {
  let density = 0;

  switch (stocking.phaseType) {
    case stockingPhaseTypes.LARVAE:
      density = stocking.litersNumber ? calcDensityLarvae({ litersNumber: stocking.litersNumber, naupliusNumber: stocking.naupliusNumber }) : 0;
      break;

    case stockingPhaseTypes.JUVENILE:
      if (stocking?.hectares) {
        density = Math.round(stocking.juvenilesNumber / stocking.hectares);
      } else if (stocking?.cubicMeters) {
        density = Math.round(stocking.juvenilesNumber / stocking.cubicMeters);
      }
      break;

    case stockingPhaseTypes.ADULT:
      if (stocking?.hectares) {
        density = Math.round(stocking.growOutNumber / stocking.hectares);
      } else if (stocking?.litersNumber) {
        density = Math.round(stocking.growOutNumber / stocking.litersNumber);
      } else if (stocking.cubicMeters) {
        density = Math.round(stocking.growOutNumber / stocking.cubicMeters);
      }
      break;
  }

  return density;
};

export const calcHarvestedDensity = (stocking: Stocking) => {
  let density = 0;

  switch (stocking.phaseType) {
    case stockingPhaseTypes.LARVAE:
      if (stocking.litersNumber) {
        density = Math.round(stocking.harvestQuantity / stocking.litersNumber);
      }

      break;

    case stockingPhaseTypes.JUVENILE:
      if (stocking.hectares) {
        density = Math.round(stocking.harvestQuantity / stocking.hectares);
      } else if (stocking.cubicMeters) {
        density = Math.round(stocking.harvestQuantity / stocking.cubicMeters);
      }
      break;

    case stockingPhaseTypes.ADULT:
      if (stocking.hectares) {
        density = Math.round(stocking.harvestQuantity / stocking.hectares);
      } else if (stocking.litersNumber) {
        density = Math.round(stocking.harvestQuantity / stocking.litersNumber);
      }
      break;
  }

  return density;
};

export const getUnitDensity = (stocking: Stocking) => {
  let unitDensity = '';

  switch (stocking.phaseType) {
    case stockingPhaseTypes.LARVAE:
      unitDensity = i18next.t('stockings.densityNauplii');
      break;

    case stockingPhaseTypes.JUVENILE:
      if (stocking?.hectares) {
        unitDensity = i18next.t('stockings.densityJuvenileHectares');
      } else if (stocking?.cubicMeters) {
        unitDensity = i18next.t('stockings.densityJuvenileCubicMeters');
      }
      break;

    case stockingPhaseTypes.ADULT:
      if (stocking?.hectares) {
        unitDensity = i18next.t('stockings.densityJuvenileHectares');
      } else if (stocking?.litersNumber) {
        unitDensity = i18next.t('stockings.densityGrowOutLiters');
      }
      break;
  }

  return unitDensity;
};

export const getVolume = (stocking: Stocking) => {
  switch (stocking?.phaseType) {
    case stockingPhaseTypes.LARVAE:
      if (stocking?.litersNumber) {
        return stocking.litersNumber;
      }
      break;

    case stockingPhaseTypes.JUVENILE:
      if (stocking?.hectares) {
        return stocking.hectares;
      }

      if (stocking?.cubicMeters) {
        return stocking.cubicMeters;
      }
      break;

    case stockingPhaseTypes.ADULT:
      if (stocking?.hectares) {
        return stocking.hectares;
      }
      if (stocking?.litersNumber) {
        return stocking.litersNumber;
      }

      if (stocking?.cubicMeters) {
        return stocking.cubicMeters;
      }
      break;
  }

  return 0;
};

export const getLabelAxisX = (stockingPhaseType: string, typeChart = typesChart.STOCKINGS) => {
  const stageLabel = i18next.t('shadedplot.stage');
  const daysLabel = (typeChart === typesChart.MATURATIONS || typeChart === typesChart.LABORATORY) ? i18next.t('shadedplot.days') : i18next.t('shadedplot.day');

  switch (stockingPhaseType) {
    case stockingPhaseTypes.LARVAE:
      return `${stageLabel} (${animalDaysUnit.PL})`;
    case stockingPhaseTypes.ADULT:
    case stockingPhaseTypes.JUVENILE:
      return daysLabel;
  }
};

export const getLabelParameter = (parameter: string) => {
  switch (parameter) {
    case typeParam.PLG:
      return i18next.t('stockings.pdf.typeParameter.plg');

    case typeParam.UNIFORMITY:
      return i18next.t('stockings.pdf.typeParameter.uniformity');

    case typeParam.AVG_WEIGHT:
      return i18next.t('stockings.pdf.typeParameter.avg_weight');

    case typeParam.AVG_LENGTH:
      return i18next.t('stockings.pdf.typeParameter.avg_length');

    case typeParam.CV_LENGTH:
      return i18next.t('stockings.pdf.typeParameter.cv_length');

    case typeParam.GROWTH_DELTA:
      return i18next.t('stockings.pdf.typeParameter.growth_delta');

    case typeParam.PIGMENTATION:
      return i18next.t('stockings.pdf.typeParameter.pigmentation');

    case typeParam.FACTOR_K:
      return i18next.t('stockings.pdf.typeParameter.factorK');

    default:
      return '';
  }
};

export const getUnitMeasure = (props: { parameter: string; phaseType: string }) => {
  const { parameter, phaseType } = props;

  switch (parameter) {
    case typeParam.AVG_WEIGHT:
      return phaseType === stockingPhaseTypes.LARVAE ? weightUnits.MG : weightUnits.G;

    case typeParam.AVG_LENGTH:
      return phaseType === stockingPhaseTypes.LARVAE ? lengthUnits.MM : lengthUnits.CM;

    case typeParam.UNIFORMITY:
    case typeParam.CV_LENGTH:
      return '%';

    case typeParam.GROWTH_DELTA:
      return phaseType === stockingPhaseTypes.LARVAE ? weightUnits.MG : weightUnits.G;

    default:
      return '';
  }
};

export const getParameter = (props: { parameter: string; stockingPhaseType: string; addUnit?: boolean }) => {
  const { parameter, stockingPhaseType, addUnit = true } = props;

  if (!addUnit) {
    let value = getLabelParameter(parameter);
    value = value.toLowerCase();
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  let value = getLabelParameter(parameter);
  value = value.toLowerCase();
  return `${value.charAt(0).toUpperCase()}${value.slice(1)} ${getUnitMeasure({ parameter, phaseType: stockingPhaseType })}`;
};

export const getUnitPhaseTypeFromStocking = (phaseType: string) => {
  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return unitPhaseTypes.LARVAE;

    case stockingPhaseTypes.JUVENILE:
    case stockingPhaseTypes.ADULT:
      return unitPhaseTypes.PRODUCTION;
  }
};

export const getTitleByStockingPhaseType = (stockingPhaseTypeSelected: string) => {
  switch (stockingPhaseTypeSelected) {
    case stockingPhaseTypes.LARVAE:
      return i18next.t('stockings.larvaeSubtitle');

    case stockingPhaseTypes.JUVENILE:
      return i18next.t('stockings.juvenileSubtitle');

    case stockingPhaseTypes.ADULT:
      return i18next.t('stockings.growOutSubtitle');

    default:
      return i18next.t('stockings.title');
  }
};

export const showErrorStocking = (props: { error: string; minimumDate?: string; days?: string }) => {
  const { error, minimumDate, days } = props;

  switch (error) {
    case typeErrorStocking.STATUS_INVALID:
      openErrorNotification(i18next.t('stockings.errors.statusInvalid'));
      break;

    case typeErrorStocking.HARVEST_QUANTITY_REQUIRED:
      openErrorNotification(i18next.t('stockings.errors.harvestQuantityRequired'));
      break;

    case typeErrorStocking.PLG_INVALID:
      openErrorNotification(i18next.t('stockings.errors.plgInvalid'));
      break;

    case typeErrorStocking.AVERAGE_WEIGHT_INVALID:
      openErrorNotification(i18next.t('stockings.errors.averageWeightInvalid'));
      break;

    case typeErrorStocking.END_DATE_REQUIRED:
      openErrorNotification(i18next.t('stockings.errors.endDateRequired'));
      break;

    case typeErrorStocking.STOCKING_FINISH:
      openErrorNotification(i18next.t('stockings.errors.stockingFinished'));
      break;

    case typeErrorStocking.HARVEST_QUANTITY_INVALID:
      openErrorNotification(i18next.t('stockings.errors.harvestQuantityInvalid'));
      break;

    case typeErrorStocking.END_DATE_INVALID:
      if (minimumDate) {
        openErrorNotification(i18next.t('stockings.errors.endDateInvalid', { minimumDate: formatYearMonthDay(minimumDate) }));
      }
      break;

    case typeErrorStocking.START_DATE_INVALID:
      openErrorNotification(i18next.t('stockings.errors.startDateInvalid'));
      break;

    case typeErrorStocking.START_DATE_JUVENILE_INVALID:
      openErrorNotification(i18next.t('stockings.errors.startDateJuvenileInvalid'));
      break;

    case inactiveCampus:
      openErrorNotification(i18next.t('stockings.errors.inactiveCampus'));
      break;

    case typeErrorStocking.START_DATE_GREATER_TO_END_DATE:
      openErrorNotification(i18next.t('stockings.errors.startDateGreaterToEndDate'));
      break;

    case typeErrorStocking.STOCKING_IS_PUBLISHED:
      openErrorNotification(i18next.t('stockings.errors.stockingIsPublished'));
      break;

    case typeErrorStocking.PHASE_TYPE_INVALID:
      openErrorNotification(i18next.t('stockings.errors.phaseTypeToBindingInvalid'));
      break;

    case typeErrorStocking.MAX_DATE_TO_BINDING_CODE_INVALID:
      openErrorNotification(i18next.t('stockings.errors.stockingDateToBindingInvalid', { days }));
      break;

    case typeErrorStocking.STOCKING_NOT_FOUND:
      openErrorNotification(i18next.t('stockings.errors.stockingNotFound'));
      break;

    case typeErrorStocking.BINDING_CODE_NOT_FOUND:
      openErrorNotification(i18next.t('stockings.errors.bindingCodeNotFound'));
      break;

    case typeErrorStocking.ACTIVE:
      openErrorNotification(i18next.t('stockings.errors.stockingActive'));
      break;

    case typeErrorStocking.ARCHIVED:
      openErrorNotification(i18next.t('stockings.errors.stockingArchived'));
      break;

    case typeErrorStocking.UNARCHIVED:
      openErrorNotification(i18next.t('stockings.errors.stockingUnarchived'));
      break;

    case typeErrorStocking.INVALID_PHASE_TYPE_TO_BINDING:
      openErrorNotification(i18next.t('stockings.errors.invalidPhaseTypeToBinding'));
      break;

    case typeErrorStocking.NUMBER_STOCKINGS_TO_BINDING:
      openErrorNotification(i18next.t('stockings.errors.numberOfStockingsToBinding'));
      break;

    case typeErrorStocking.NUMBER_STOCKINGS_TO_TRANSFER:
      openErrorNotification(i18next.t('stockings.errors.errorNumberOfStockingsToTransfer'));
      break;

    case typeErrorStocking.STOCKINGS_MUST_BE_FINISHED:
      openErrorNotification(i18next.t('stockings.errors.stockingsMustBeFinished'));
      break;

    case typeErrorStocking.DAYS_BETWEEN_STOCKINGS:
      openErrorNotification(i18next.t('stockings.errors.daysBetweenStockings'));
      break;

    case typeErrorStocking.REPEATED_CODES:
      openErrorNotification(i18next.t('stockings.errors.repeatedCodes'));
      break;

    case typeErrorStocking.STOCKING_ARE_ALREADY_BINDING:
      openErrorNotification(i18next.t('stockings.errors.stockingAreAlreadyBinding'));
      break;

    case typeErrorStocking.TANK_SOWN:
      openErrorNotification(i18next.t('stockings.enableDescription.TANK.notAvailable'));
      break;

    case typeErrorStocking.INCORRECT_ORIGIN_STOCKING_DATE:
      openErrorNotification(i18next.t('stockings.errors.incorrectOriginStockingDate'));
      break;

    case typeErrorStocking.INVALID_END_DATE_IN_FULL_TRANSFER:
      openErrorNotification(i18next.t('stockings.errors.invalidEndDateInFullTransfer'));
      break;

    case typeErrorStocking.CONFLICT_WITH_LAST_POPULATION_DATE:
      openErrorNotification(i18next.t('stockings.errors.conflictWithLastPopulationDate'));
      break;

    case typeErrorStocking.CONFLICT_WITH_PARTIAL_HARVEST_DATE:
    case typeErrorStocking.CONFLICT_WITH_POPULATION_DATE:
    case typeErrorStocking.CONFLICT_WITH_TRANSFER_DATE:
      openErrorNotification(i18next.t('stockings.errors.conflictWithPopulationDate'));
      break;
  }
};

export const getStartDateStocking = (props: { stocking: Stocking; phaseType: string }) => {
  const { phaseType, stocking } = props;

  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return stocking.startDate;

    case stockingPhaseTypes.JUVENILE:
      return stocking.startDateJuvenile;

    case stockingPhaseTypes.ADULT:
      return stocking.startDateGrowOut;
  }
};

export const getAnimals = (props: { stocking: Stocking; phaseTypeSelected: string }) => {
  const { phaseTypeSelected, stocking } = props;

  switch (phaseTypeSelected) {
    case stockingPhaseTypes.LARVAE:
    default:
      return stocking.naupliusNumber;

    case stockingPhaseTypes.JUVENILE:
      return stocking.juvenilesNumber;

    case stockingPhaseTypes.ADULT:
      return stocking.growOutNumber;
  }
};

export const getHoursOffsetStocking = (props: { stocking: Stocking; phaseType: string }) => {
  const { phaseType, stocking } = props;

  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return getHoursOffset(stocking.startDate);

    case stockingPhaseTypes.JUVENILE:
      return getHoursOffset(stocking.startDateJuvenile);

    case stockingPhaseTypes.ADULT:
      return getHoursOffset(stocking.startDateGrowOut);
  }
};

export const disabledDateLarvae = (props: { currentDate: moment.Moment; stockingDateRanges: StockingDateRange }) => {
  const { currentDate, stockingDateRanges } = props;

  const { max, min } = stockingDateRanges.larvae.startDateLarvae;
  return currentDate && (currentDate < moment().subtract(min, 'd') || currentDate > moment().add(max, 'd'));
};

export const disabledDateJuvenile = (props: { currentDate: moment.Moment; stockingDateRanges: StockingDateRange }) => {
  const { currentDate, stockingDateRanges } = props;

  const { max, min } = stockingDateRanges.juvenile.startDateJuvenile;
  return currentDate && (currentDate < moment().subtract(min, 'd') || currentDate > moment().add(max, 'd'));
};

export const disabledDateGrowOut = (props: { currentDate: moment.Moment; stockingDateRanges: StockingDateRange }) => {
  const { currentDate, stockingDateRanges } = props;

  const { max, min } = stockingDateRanges.growOut.startDateGrowOut;
  return currentDate && (currentDate < moment().subtract(min, 'd') || currentDate > moment().add(max, 'd'));
};

export const disabledEndDate = (props: { currentDate: moment.Moment; stockingStartDate: string }) => {
  const { currentDate, stockingStartDate } = props;
  const startDate = stockingStartDate ? formatLongDateWithZone({ date: stockingStartDate }) : '';

  const dateTime = moment(startDate);
  return currentDate && (currentDate < dateTime);
};

export const convertPoundsToKilograms = (pounds: number | string) => {
  return Math.round((parseFloat(pounds.toString()) * (LBS_TO_KG_RATIO)) * 100) / 100;
};

export const convertKilogramsToPounds = (kilograms: number | string, round = true) => {
  if (round) {
    return Math.round((parseFloat(kilograms.toString()) * (1 / LBS_TO_KG_RATIO)) * 100) / 100;
  }

  return ((parseFloat(kilograms.toString()) * (1 / LBS_TO_KG_RATIO)) * 100) / 100;
};

export const getStockingVolumeLabel = (selectedContainerType: string) => {
  switch (selectedContainerType) {
    case containerTypes.TANK:
      return i18next.t('stockings.liters');

    case containerTypes.RACEWAY:
      return i18next.t('stockings.cubicMeters');

    case containerTypes.PRE_HATCHERY:
    case containerTypes.POOL:
      return i18next.t('stockings.hectares');

    default:
      return i18next.t('stockings.defaultMeasureAdult');
  }
};

export const getStockingVolumeUnit = (containerType: string) => {
  switch (containerType) {
    case containerTypes.TANK:
      return volumeUnits.L;

    case containerTypes.RACEWAY:
      return volumeUnits.M3;

    case containerTypes.PRE_HATCHERY:
    case containerTypes.POOL:
      return volumeUnits.HA;
  }
};

export const calcAvgGrowth = (props: { averageWeight: number; stage: number; phaseType: string; stockingWeight?: number }) => {
  const { averageWeight, phaseType, stage, stockingWeight = 0 } = props;

  switch (phaseType) {
    case stockingPhaseTypes.JUVENILE:
      return `${roundWeight({ value: (averageWeight / 1000) / (stage + 1), showUnit: false })} ${i18next.t('analysis.resultData.gramPerDay')}`;
    case stockingPhaseTypes.ADULT:
      return `${roundWeight({ value: ((averageWeight - stockingWeight) / 1000) / ((stage + 1) / 7), showUnit: false })} ${i18next.t('analysis.resultData.gramPerWeek')}`;
    default:
      return '';
  }
};

export const getHarvestedAndTransferredAnimalCount = (props: { stocking: IStocking }) => {
  const { stocking } = props;

  let harvestQuantity = stocking.harvestQuantity || 0;

  if (stocking.harvests && stocking.harvests.length > 0) {
    const quantity = stocking.harvests.reduce((acc: number, harvest) => acc + harvest.harvestQuantity, 0);
    harvestQuantity += quantity;
  }

  if (stocking.transfers && stocking.transfers.length > 0) {
    const animalsTransferred = stocking.transfers.reduce((acc: number, transfer) => acc + transfer.animalsTransferred, 0);
    harvestQuantity += animalsTransferred;
  }

  return harvestQuantity;
};

export const getVolumeLabelByStocking = (stocking: Stocking) => {
  if (stocking?.litersNumber) {
    return i18next.t('stockings.liters');
  }

  if (stocking?.hectares) {
    return i18next.t('stockings.hectares');
  }

  if (stocking?.cubicMeters) {
    return i18next.t('stockings.cubicMeters');
  }

  return i18next.t('stockings.defaultMeasureAdult');
};

export const getVolumeUnitByStocking = (stocking: Stocking) => {
  if (stocking?.litersNumber) {
    return volumeUnits.L;
  }

  if (stocking?.hectares) {
    return volumeUnits.HA;
  }

  if (stocking?.cubicMeters) {
    return volumeUnits.M3;
  }

  return volumeUnits.L;
};
